import React, { useState } from 'react'

import Module from './Module'

import Box from '../../../components/Box';
import Flex from '../../../components/Flex';
import Button from '../../../components/Button';
import theme from '../../../components/ThemeProvider/theme';

const Desktop = ({ region, useData, openList }) => {
  const [all, openAll] = useState()
  return (
    <Flex
      position="absolute"
      top={theme.headerHeight}
      left="0"
      right="0"
      pt="1em"
      bg="white"
      px="2em"
      height={all ? `calc(100vh - ${theme.headerHeight})` : '45vh'}
      overflowY={all ? 'scroll' : 'hidden'}
      zIndex={98}
    >
      {region.map((r, i, { length }) => (
        <Box width={1 / length} key={i}>
          <Box
            mx="0.5rem"
            fontSize="1.25em"
            fontWeight="bold"
            borderBottom="1px solid"
            pb="0.625rem"
            lineHeight="1"
          >
            {r}
          </Box>
          {useData[r].map(({ name, src, isLock }, k) => (
            <Module openList={openList} name={name} src={src} isLock={isLock} key={k} />
          ))}
        </Box>
      ))}
      <>
        <Box
          position="absolute"
          top="0"
          bottom="0"
          left="0"
          right="0"
          pointerEvents="none"
          background={!all && 'linear-gradient(rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255) 100%)'}
        />
        <Box position={all ? 'fixed' : 'absolute'} bottom="0.5em" left="50%" transform="translateX(-50%)">
          <Button.White hoverBg="white" fontWeight="normal" color="gray" hoverColor="black" onClick={() => openAll(!all)}>
            {all ? '收回列表' : '看完整列表'}
          </Button.White>
        </Box>
      </>
    </Flex>
  )
}

export default Desktop
