import React, { useEffect, useRef, useMemo, useState } from 'react'
import Shuffle from 'shufflejs'
import sortBy from 'lodash/sortBy'
import size from 'lodash/size'
import { useFormikContext } from 'formik'

import Container from '../../components/Container'
import Box from '../../components/Box'
import Flex from '../../components/Flex'
// import Text from '../../components/Text'
import { responsive } from '../../components/ThemeProvider/theme';

// import useResponsive from '../../contexts/mediaQuery/useResponsive'
import Card from './Card'

import useFilterValue from '../useFilterValue'
import Filter from '../Filter'
import useCsv from '../useCsv'
import csvData from '../data.csv'
import RotateContext from './rotateContext'
import { isArray } from 'lodash'

let shuffleInstance

const HomePage = () => {
  // const { isMobile } = useResponsive()
  const { data } = useCsv(csvData)
  const sortData = useMemo(() => sortBy(data, 'isLock'), [data])
  const filterData = useFilterValue()
  const { values } = useFormikContext()
  const containerRef = useRef()
  const sizerRef = useRef()
  const [visibleItems, setVisibleItems] = useState(1)
  // console.log(data)
  useEffect(() => {
    const element = containerRef.current;
    const sizer = sizerRef.current
    if (data.length && element && sizer) {
      shuffleInstance = new Shuffle(element, {
        itemSelector: '.mountain',
        sizer,
      });
    }
  }, [data])

  useEffect(() => {
    if (shuffleInstance) {
      shuffleInstance.filter(e => {
        const eleLocation = e.getAttribute('data-location')
        const eleJournal = e.getAttribute('data-journal').split(',')
        const elenotHunMt = e.getAttribute('data-nothunmt')
        console.log(eleJournal)

        return (!size(filterData.filter.location) || filterData.filter.location[eleLocation])
          && (!size(filterData.filter.journal) || eleJournal.some(j => filterData.filter.journal[j]))
          && (!size(filterData.filter.notHunMt) || filterData.filter.notHunMt[elenotHunMt])
      });
      // if (filterData.filter) {
      //   // console.log(filterData.filter)
      //   shuffleInstance.filter(filterData.filter);
      // } else {
      //   shuffleInstance.filter();
      // }

      if (filterData.search) {
        const searchText = filterData.search
        shuffleInstance.filter((e) => {
          const titleElement = e.getAttribute('data-title')
          return titleElement.indexOf(searchText) !== -1
        })
      }
      // console.log(filterData.sort)

      if (filterData.sort) {
        const [sortKey, rev] = filterData.sort.split(':')
        shuffleInstance.sort({
          by: ele => +ele.getAttribute(`data-${sortKey}`),
          reverse: Boolean(rev),
        });
      } else {
        shuffleInstance.sort();
      }
      setVisibleItems(shuffleInstance.visibleItems)
    }
  }, [filterData])

  const [rotate, allRotate] = useState()
  // console.log(sortData)
  return (
    <>
      {useMemo(() => <Filter rotate={rotate} allRotate={allRotate} />, [rotate, allRotate])}
      <RotateContext.Provider value={rotate}>
        <Container pt={responsive((values.search || values.location.length || values.journal.length)? '8em' : '3em', '3em')}>
          {!visibleItems && <Box textAlign="center" fontSize="1.5em" mt="2em">查無資料</Box>}
          {useMemo(() => (
            <Flex flexWrap="wrap" ref={containerRef}>
              {sortData.map(({ name, height, north, adj, src, isLock, location, journal, notHunMt }, i) => (
                <Box
                  p={responsive('0.75em', '1.125em')}
                  width={responsive(1 / 2, 1 / 3, 1 / 4)}
                  key={i}
                  className="mountain"
                  data-location={location}
                  data-journal={journal}
                  data-height={height}
                  data-north={north}
                  data-title={name}
                  data-nothunmt={notHunMt}
                >
                  <Card width="100%" adj={adj} name={name} src={src} isLock={isLock} />
                </Box>
              ))}
            </Flex>
          ), [data])}
          <Box width={responsive(1 / 2, 1 / 3, 1 / 4)} ref={sizerRef} />
        </Container>
      </RotateContext.Provider>
    </>
  )
}

export default HomePage
