import React from 'react'

import Box from '../../components/Box';
import Text from '../../components/Text';
import Image from '../../components/Image';

import part1 from './part1.jpg'
import part1webp from './part1.webp'

const lists = [
  '從事戶外活動的天數',
  '最近的天氣情況',
  '預計行走的路線規劃',
  '水源的狀態'
]

const systems = [
  '定位系統：可以包含紙本地圖與電子地圖，若是電子地圖則務必記得預先下載離線地圖，避免山區收訊不穩定。此外仍建議攜帶GPS定位系統，GPS定位系統於戶外透過衛星定位校正，將取得更明確的經緯度位置。另外若有空間也可攜帶無線電與inReach可以更完善定位系統的準備。',
  '照明系統：額外的備用電池與輕量化的備用頭燈，可以減少電力用完或是遺失頭燈的窘境。出發前一定要記得做測試，並且在打包過程要避免擠壓到開啟鈕造成電力的浪費。頭燈是相當便利的，使用頭燈可以空出雙手煮飯或進行夜間的活動，而燈源若能有紅色的光，就可以在夜間的使用上不會打擾到其他的人。不僅能在緊急時候能夠有求生的功能同時更能使行進過程更加便捷。',
  '防曬保護：防曬的部分若沒有注意到，短期內會造成疼痛，嚴重一點可能會起水泡。長期來看，也有機會引發皮膚癌。目前會建議多使用物理性防曬，如：帽子、太陽眼鏡、長袖等等具備防曬功能的衣物，已減少防曬乳的使用。若是長時間在戶外行走，也必須留意到帽子邊緣不夠造成耳朵、嘴唇、脖子等曬傷。故若是知道自己會長時間曝曬的話，建議對於這些小地方更要留意。'
]

const PartI = () => {
  return (
    <Box>
      <Text.Article>走！我們去爬山吧！<br />登山可以輕鬆寫意但可不能隨隨便便。</Text.Article>
      <Text.Article>
        頭燈到底帶不帶？<br />行動電源是不是必須？<br />小喇叭一定得帶嗎？
      </Text.Article>
      <Text.Article>走！我們去爬山吧！<br />登山可以輕鬆寫意但可不能隨隨便便。</Text.Article>
      <Text>在行動之前我們得思考規劃，規劃就必須得考量到：</Text>
      <Box as="ol">
        {lists.map((text, i) => (
          <li key={i}>{text}</li>
        ))}
      </Box>
      <Box my="2em">
        <Image src={[part1webp ,part1]} />
        <Text>實拍圖來源：許書瑋</Text>
      </Box>
      <Text.Article>所以我們會建議大家在登山前，一定要把這十大裝備系統隨時準備好，才能減少登山意外的風險，並且有更大的彈性面對多變的戶外！</Text.Article>
      <Text.Article>登山裝備系統將分為三個篇幅與大家討論，就讓我們細細道來吧！</Text.Article>
      <Box as="ol" listStyleType="lower-alpha" my="2em">
        {systems.map((text, i) => (
          <Box as="li" mt="1em" key={i}>{text}</Box>
        ))}
      </Box>
    </Box>
  )
}

export default PartI
