import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import range from 'lodash/range'

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Box from './Box'
import Chevron from './Chevron'
import Flex from './Flex'
import Button from './Button'
import { responsive } from './ThemeProvider/theme';

const StyleBox = styled(Box)`
  img,
  picture {
    display: block;
  }
  .slick-arrow {
    height: auto;
    font-size: 0.75em;
    z-index: 1;
    width: 3em;
    color: white;
    &::before {
      content: none;
    }
    &:hover {
      color: white;
    }
  }
  .slick-prev {
    left: -2em;
  }
  .slick-next {
    right: -2em;
  }
`

const Carousel = ({ children, nodot, width, flex, autoplay, arrows, ...props }) => {
  const [activeItemIndex, setActiveIndex] = useState(0)
  const slickRef = useRef()
  useEffect(() => {
    if (slickRef.current) {
      slickRef.current.slickGoTo(activeItemIndex)
    }
  }, [slickRef, activeItemIndex])
  return (
    <StyleBox position="relative" width={width} flex={flex} {...props}>
      <Slider
        autoplay={autoplay}
        prevArrow={<Chevron.Left transform="translateX(2.25em)" />}
        nextArrow={<Chevron.Right transform="translateX(-2.25em)" />}
        arrows={arrows}
        dots={false}
        ref={slickRef}
        autoplaySpeed={3500}
        speed={750}
        beforeChange={(from, to) => setActiveIndex(to)}
      >
        {children}
      </Slider>
      {!nodot && (
        <Flex
          position="absolute"
          bottom="1em"
          left="50%"
          transform="translateX(-50%)"
        >
          {range(children.length).map((i) => (
            <Box mx="0.25em" key={i}>
              <Button.Circle
                border="1px solid"
                borderColor="lightGray"
                bg="lightGray"
                hoverBorder="white"
                hoverBg="white"
                py={responsive('0.375rem', '0.5rem')}
                px={responsive('0.375rem', '0.5rem')}
                active={i === activeItemIndex}
                onClick={() => setActiveIndex(i)}
              />
            </Box>
          ))}
        </Flex>
      )}
    </StyleBox>
  );
}

Carousel.defaultProps = {
  arrows: true,
}

export default Carousel;
