import { useState, useEffect } from 'react'
import { tsv } from 'd3-fetch'
import mapValues from 'lodash/mapValues'
import range from 'lodash/range'

import { coverImg, icons, animalImgs, plants, packages, pictures } from './data'

const repreHandler = (d) => {
  return d.split('\n')[0].trim()
}

const seasonHandler = (d) => {
  return d.split(',').map(dd => +dd)
}

const applicationHandler = (d) => {
  return d.split(',').map(dd => +dd)
}

const activeHandler = (d) => {
  if (d === "all") {
    return range(13)
  }
  return d.split(',').map(dd => +dd)
}

const animalHandler = d => {
  const regPttn = '([^（]+)（([^）]+)）、?'
  let reg = new RegExp(regPttn)
  const found = []
  if (!reg.exec(d)) {
    found.push({
      name: d,
    })
    return found
  }
  reg = new RegExp(regPttn, 'g')
  let res
  while (res = reg.exec(d)) {
    found.push({
      name: res[1],
      icon: res[2].split('、').map(dd => icons[dd]),
    })
  }
  return found
}

const cityHandler = (d) => {
  return d.split('、')
}

const dataHandler = {
  mammel_repre: repreHandler,
  bird_repre: repreHandler,
  reptiles_repre: repreHandler,
  amphibian_repre: repreHandler,
  insect_repre: repreHandler,
  mammal: animalHandler,
  bird: animalHandler,
  reptiles: animalHandler,
  amphibian: animalHandler,
  insect: animalHandler,
  season: seasonHandler,
  application: applicationHandler,
  city: cityHandler,
}

export default (url) => {
  const [loading, setLoading] = useState()
  const [data, setData] = useState([])
  useEffect(() => {
    setLoading(true)
    tsv(url).then(d => {
      setData(d.map(dd => {
        const cover = coverImg[dd.en]
        const adj = [
          {
            title: dd.metaphor1,
            desc: dd.metaphor1Text
          },
          {
            title: dd.metaphor2,
            desc: dd.metaphor2Text
          },
        ]
        const history = [
          [
            {
              text: dd.history1,
            },
            {
              text: dd.history1_1,
            },
          ],
          [
            {
              text: `『${dd.history2}』`,
              fontSize: '1.25em',
              fontWeight: 'bold',
              textAlign: 'center',
              mt: '2rem',
              mb: '1.625rem'
            },
            {
              text: dd.history2_1,
            },
          ],
        ]
        const organism = [
          [
            {
              img: animalImgs[repreHandler(dd.mammel_repre)],
              animalName: repreHandler(dd.mammel_repre),
              animals: animalHandler(dd.mammal)
            },
            {
              img: animalImgs[repreHandler(dd.bird_repre)],
              animalName: repreHandler(dd.bird_repre),
              animals: animalHandler(dd.bird)
            },
            {
              img: animalImgs[repreHandler(dd.reptiles_repre)],
              animalName: repreHandler(dd.reptiles_repre),
              animals: animalHandler(dd.reptiles)
            },
          ],
          [
            {
              img: dd.others === '' ? animalImgs[repreHandler(dd.amphibian_repre)] : animalImgs[repreHandler(dd.amphibian_repre)][1],
              animalName: repreHandler(dd.amphibian_repre),
              animals: animalHandler(dd.amphibian)
            },
            {
              img: dd.others === '' ? animalImgs[repreHandler(dd.insect_repre)] : animalImgs[repreHandler(dd.insect_repre)][1],
              animalName: repreHandler(dd.insect_repre),
              animals: animalHandler(dd.insect)
            },
            dd.others !== '' && {
              active: activeHandler(dd.active),
              text: dd.others
            }
          ]
        ]
        const plant = plants[dd.en]
        const packageImg = packages[dd.en]
        const picture = range(10).map(i => {
          const picKey = `picture${i + 1}`
          const txtKey = `text${i + 1}`
          return dd[picKey] && {
            src: pictures[dd[picKey]],
            text: dd[txtKey],
          }
        })
        const transformedData = mapValues(dd, (value, key) => dataHandler[key] ? dataHandler[key](value, dd) : value)
        return {adj, history, organism, ...transformedData, cover, plant, packageImg, picture}
      }))
      setLoading(false)
    })
  }, [])

  return {
    data,
    loading,
  }
}
