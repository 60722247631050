import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react';
import ReactDOM from 'react-dom';
import smoothscroll from 'smoothscroll-polyfill';
import ReactGA from 'react-ga'

import ThemeProvider from './components/ThemeProvider'
import MediaProvider from './contexts/mediaQuery/MediaProvider'

import App from './App';
// import * as serviceWorker from './serviceWorker';

ReactGA.initialize('UA-77055530-31')

smoothscroll.polyfill();
ReactDOM.render(
  <ThemeProvider>
    <MediaProvider>
      <App />
    </MediaProvider>
  </ThemeProvider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
