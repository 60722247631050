import React from 'react'

import SVG from '../../components/SVG'

const Special = (props) => (
  <SVG viewBox="0 0 32 32" {...props}>
    <g id="icon">
      <path d="M18.14,21.54c0,.12.06.18.17.18h3.78a1.16,1.16,0,0,1,1.18,1.18,1.12,1.12,0,0,1-.34.83,1.15,1.15,0,0,1-.84.35H7.91a1.15,1.15,0,0,1-.84-.35,1.12,1.12,0,0,1-.34-.83,1.16,1.16,0,0,1,1.18-1.18h3.62c.12,0,.17-.06.17-.18V19.7c0-.12,0-.18-.17-.18h-1s0,0,0,.07a.49.49,0,0,1-.15.35.49.49,0,0,1-.36.15H9.17a1.19,1.19,0,0,1-.89-.38,1.25,1.25,0,0,1-.37-.91V14.21a1.47,1.47,0,0,1,.45-1.08,1.43,1.43,0,0,1,1.08-.44h2.09c.12,0,.17-.06.17-.18V11.09c0-.12,0-.18-.17-.18H8.12A1.2,1.2,0,0,1,6.94,9.72a1.12,1.12,0,0,1,.35-.82,1.16,1.16,0,0,1,.83-.35H21.9a1.16,1.16,0,0,1,.83.35,1.12,1.12,0,0,1,.35.82,1.2,1.2,0,0,1-1.18,1.19H18.31c-.11,0-.17.06-.17.18v1.42c0,.12.06.18.17.18h2.24a1.52,1.52,0,0,1,1.53,1.52v4.43a1.28,1.28,0,0,1-.39.94,1.3,1.3,0,0,1-.93.38h-.93a.41.41,0,0,1-.28-.11.4.4,0,0,1-.11-.27s0-.06-.05-.06H18.31c-.11,0-.17.06-.17.18Zm-7.68-4.41c0,.12,0,.18.17.18h2a1.54,1.54,0,0,1,1.53,1.53v2.7a.16.16,0,0,0,.18.18h1.07c.12,0,.17-.06.17-.18v-2.7a1.54,1.54,0,0,1,1.53-1.53h2.11a.16.16,0,0,0,.18-.18V15.06a.16.16,0,0,0-.18-.18H17.15a1.47,1.47,0,0,1-1.08-.45,1.46,1.46,0,0,1-.45-1.08V11.09c0-.12,0-.18-.17-.18H14.39c-.11,0-.17.06-.17.18v2.26a1.46,1.46,0,0,1-.45,1.08,1.47,1.47,0,0,1-1.08.45H10.63c-.12,0-.17.06-.17.18Z" fill="currentColor"/>
      <polygon points="15 1 1 11.17 6.35 27.63 23.65 27.63 29 11.17 15 1" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
    </g>
  </SVG>
)

export default Special
