import yushan from './image/yushan_web.png'
import yushanmobile from './image/yushan_mobile.png'
import yushanplant from './image/yushan_plant.png'
import yushanpackage from './image/yushan_package.png'
import yushan1 from './image/yushan1.jpg'
import yushan2 from './image/yushan2.jpg'
import yushan3 from './image/yushan3.jpg'
import yushan4 from './image/yushan4.jpg'
import yushan5 from './image/yushan5.jpg'
import yushan6 from './image/yushan6.jpg'
import yushan7 from './image/yushan7.jpg'
import yushan8 from './image/yushan8.jpg'
import jialishan from './image/jialishan_web.png'
import jialishanmobile from './image/jialishan_mobile.png'
import jialishanplant from './image/jialishan_plant.png'
import jialishanpackage from './image/jialishan_package.png'
import jialishan1 from './image/jialishan1.jpg'
import jialishan2 from './image/jialishan2.jpg'
import jialishan3 from './image/jialishan3.jpg'
import jialishan4 from './image/jialishan4.jpg'
import taipingshan from './image/taipingshan_web.png'
import taipingshanmobile from './image/taipingshan_mobile.png'
import taipingshanplant from './image/taipingshan_plant.png'
import taipingshanpackage from './image/taipingshan_package.png'
import taipingshan1 from './image/taipingshan1.jpg'
import taipingshan2 from './image/taipingshan2.jpeg'
import shengmudengshan from './image/shengmudengshan_web.png'
import shengmudengshanmobile from './image/shengmudengshan_mobile.png'
import shengmudengshanplant from './image/shengmudengshan_plant.png'
import shengmudengshanpackage from './image/shengmudengshan_package.png'
import shengmudengshan1 from './image/shengmudengshan1.jpg'
import shengmudengshan2 from './image/shengmudengshan2.jpg'
import shengmudengshan3 from './image/shengmudengshan3.jpg'
import shengmudengshan4 from './image/shengmudengshan4.jpg'
import shengmudengshan5 from './image/shengmudengshan5.jpg'
import shengmudengshan6 from './image/shengmudengshan6.jpg'
import hengshan from './image/hengshan_web.png'
import hengshanmobile from './image/hengshan_mobile.png'
import hengshanplant from './image/hengshan_plant.png'
import hengshanpackage from './image/hengshan_package.png'
import hengshan1 from './image/hengshan1.jpg'
import hengshan2 from './image/hengshan2.jpg'
import xueshan from './image/xueshan_web.png'
import xueshanmobile from './image/xueshan_mobile.png'
import xueshanplant from './image/xueshan_plant.png'
import xueshanpackage from './image/xueshan_package.png'
import xueshan1 from './image/xueshan1.jpg'
import xueshan2 from './image/xueshan2.jpg'
import xueshan3 from './image/xueshan3.jpg'
import xueshan4 from './image/xueshan4.jpg'
import xueshan5 from './image/xueshan5.jpg'
import xueshan6 from './image/xueshan6.jpg'
import xueshan7 from './image/xueshan7.jpg'
import xueshan8 from './image/xueshan8.jpg'
import xueshan9 from './image/xueshan9.jpg'
import xueshan10 from './image/xueshan10.jpg'
import huoshishan from './image/huoshishan_web.png'
import huoshishanmobile from './image/huoshishan_mobile.png'
import huoshishanplant from './image/huoshishan_plant.png'
import huoshishanpackage from './image/huoshishan_package.png'
import huoshishan1 from './image/huoshishan1.jpg'
import huoshishan2 from './image/huoshishan2.jpg'
import huoshishan3 from './image/huoshishan3.jpg'
import fenqihudadongshan from './image/fenqihudadongshan_web.png'
import fenqihudadongshanmobile from './image/fenqihudadongshan_mobile.png'
import fenqihudadongshanplant from './image/fenqihudadongshan_plant.png'
import fenqihudadongshanpackage from './image/fenqihudadongshan_package.png'
import fenqihudadongshan1 from './image/fenqihudadongshan1.jpg'
import dongyanshan from './image/dongyanshan_web.png'
import dongyanshanmobile from './image/dongyanshan_mobile.png'
import dongyanshanplant from './image/dongyanshan_plant.png'
import dongyanshanpackage from './image/dongyanshan_package.png'
import dongyanshan1 from './image/dongyanshan1.jpeg'
import dongyanshan2 from './image/dongyanshan2.jpg'
import dongyanshan3 from './image/dongyanshan3.jpg'
import dongyanshan4 from './image/dongyanshan4.jpg'
import doulanshan from './image/doulanshan_web.png'
import doulanshanmobile from './image/doulanshan_mobile.png'
import doulanshanplant from './image/doulanshan_plant.png'
import doulanshanpackage from './image/doulanshan_package.png'
import doulanshan1 from './image/doulanshan1.jpg'
import doulanshan2 from './image/doulanshan2.jpg'
import doulanshan3 from './image/doulanshan3.jpg'
import doulanshan4 from './image/doulanshan4.jpg'
import doulanshan5 from './image/doulanshan5.jpg'
import doulanshan6 from './image/doulanshan6.jpg'
import liushishishan from './image/liushishishan_web.png'
import liushishishanmobile from './image/liushishishan_mobile.png'
import liushishishanplant from './image/liushishishan_plant.png'
import liushishishanpackage from './image/liushishishan_package.png'
import liushishishan1 from './image/liushishishan1.jpg'
import liushishishan2 from './image/liushishishan2.jpg'
import liushishishan3 from './image/liushishishan3.jpg'
import liushishishan4 from './image/liushishishan4.jpg'
import liushishishan5 from './image/liushishishan5.jpg'
import guanyinshan from './image/guanyinshan_web.png'
import guanyinshanmobile from './image/guanyinshan_mobile.png'
import guanyinshanplant from './image/guanyinshan_plant.png'
import guanyinshanpackage from './image/guanyinshan_package.png'
import guanyinshan1 from './image/guanyinshan1.jpg'
import guanyinshan2 from './image/guanyinshan2.jpeg'
import guanyinshan3 from './image/guanyinshan3.jpg'
import liyushan from './image/liyushan_web.png'
import liyushanmobile from './image/liyushan_mobile.png'
import liyushanplant from './image/liyushan_plant.png'
import liyushanpackage from './image/liyushan_package.png'
import liyushan1 from './image/liyushan1.jpg'
import liyushan2 from './image/liyushan2.jpg'
import wuerchahushan from './image/wuerchahushan_web.png'
import wuerchahushanmobile from './image/wuerchahushan_mobile.png'
import wuerchahushanplant from './image/wuerchahushan_plant.png'
import wuerchahushanpackage from './image/wuerchahushan_package.png'
import wuerchahushan1 from './image/wuerchahushan1.jpg'
import wuerchahushan2 from './image/wuerchahushan2.jpg'
import wuerchahushan3 from './image/wuerchahushan3.jpg'
import wuerchahushan4 from './image/wuerchahushan4.jpg'
import wuerchahushan5 from './image/wuerchahushan5.jpg'
import shoushan from './image/shoushan_web.png'
import shoushanmobile from './image/shoushan_mobile.png'
import shoushanplant from './image/shoushan_plant.png'
import shoushanpackage from './image/shoushan_package.png'
import shoushan1 from './image/shoushan1.jpg'
import shoushan2 from './image/shoushan2.jpg'
import shoushan3 from './image/shoushan3.jpg'
import shoushan4 from './image/shoushan4.jpg'
import yuntaishan from './image/yuntaishan_web.png'
import yuntaishanmobile from './image/yuntaishan_mobile.png'
import yuntaishanplant from './image/yuntaishan_plant.png'
import yuntaishanpackage from './image/yuntaishan_package.png'
import yuntaishan1 from './image/yuntaishan1.jpg'
import yuntaishan2 from './image/yuntaishan2.jpg'
import yuntaishan3 from './image/yuntaishan3.jpg'
import yuntaishan4 from './image/yuntaishan4.jpg'
import yuntaishan5 from './image/yuntaishan5.jpg'
import yuntaishan6 from './image/yuntaishan6.jpg'
import dawulunshan from './image/dawulunshan_web.png'
import dawulunshanmobile from './image/dawulunshan_mobile.png'
import dawulunshanplant from './image/dawulunshan_plant.png'
import dawulunshanpackage from './image/dawulunshan_package.png'
import dawulunshan1 from './image/dawulunshan1.jpeg'
import dawulunshan2 from './image/dawulunshan2.jpg'
import dawulunshan3 from './image/dawulunshan3.jpg'
import dawulunshan4 from './image/dawulunshan4.jpg'
import xiangshan from './image/xiangshan_web.png'
import xiangshanmobile from './image/xiangshan_mobile.png'
import xiangshanplant from './image/xiangshan_plant.png'
import xiangshanpackage from './image/xiangshan_package.png'
import xiangshan1 from './image/xiangshan1.jpg'
import xiangshan2 from './image/xiangshan2.jpg'
import xiangshan3 from './image/xiangshan3.jpg'

import yushanwebp from './image/yushan_web.webp'
import yushanmobilewebp from './image/yushan_mobile.webp'
import yushanplantwebp from './image/yushan_plant.webp'
import yushanpackagewebp from './image/yushan_package.webp'
import yushan1webp from './image/yushan1.webp'
import yushan2webp from './image/yushan2.webp'
import yushan3webp from './image/yushan3.webp'
import yushan4webp from './image/yushan4.webp'
import yushan5webp from './image/yushan5.webp'
import yushan6webp from './image/yushan6.webp'
import yushan7webp from './image/yushan7.webp'
import yushan8webp from './image/yushan8.webp'
import jialishanwebp from './image/jialishan_web.webp'
import jialishanmobilewebp from './image/jialishan_mobile.webp'
import jialishanplantwebp from './image/jialishan_plant.webp'
import jialishanpackagewebp from './image/jialishan_package.webp'
import jialishan1webp from './image/jialishan1.webp'
import jialishan2webp from './image/jialishan2.webp'
import jialishan3webp from './image/jialishan3.webp'
import jialishan4webp from './image/jialishan4.webp'
import taipingshanwebp from './image/taipingshan_web.webp'
import taipingshanmobilewebp from './image/taipingshan_mobile.webp'
import taipingshanplantwebp from './image/taipingshan_plant.webp'
import taipingshanpackagewebp from './image/taipingshan_package.webp'
import taipingshan1webp from './image/taipingshan1.webp'
import taipingshan2webp from './image/taipingshan2.jpeg'
import shengmudengshanwebp from './image/shengmudengshan_web.webp'
import shengmudengshanmobilewebp from './image/shengmudengshan_mobile.webp'
import shengmudengshanplantwebp from './image/shengmudengshan_plant.webp'
import shengmudengshanpackagewebp from './image/shengmudengshan_package.webp'
import shengmudengshan1webp from './image/shengmudengshan1.webp'
import shengmudengshan2webp from './image/shengmudengshan2.webp'
import shengmudengshan3webp from './image/shengmudengshan3.webp'
import shengmudengshan4webp from './image/shengmudengshan4.webp'
import shengmudengshan5webp from './image/shengmudengshan5.webp'
import shengmudengshan6webp from './image/shengmudengshan6.webp'
import hengshanwebp from './image/hengshan_web.webp'
import hengshanmobilewebp from './image/hengshan_mobile.webp'
import hengshanplantwebp from './image/hengshan_plant.webp'
import hengshanpackagewebp from './image/hengshan_package.webp'
import hengshan1webp from './image/hengshan1.webp'
import hengshan2webp from './image/hengshan2.webp'
import xueshanwebp from './image/xueshan_web.webp'
import xueshanmobilewebp from './image/xueshan_mobile.webp'
import xueshanplantwebp from './image/xueshan_plant.webp'
import xueshanpackagewebp from './image/xueshan_package.webp'
import xueshan1webp from './image/xueshan1.webp'
import xueshan2webp from './image/xueshan2.webp'
import xueshan3webp from './image/xueshan3.webp'
import xueshan4webp from './image/xueshan4.webp'
import xueshan5webp from './image/xueshan5.webp'
import xueshan6webp from './image/xueshan6.webp'
import xueshan7webp from './image/xueshan7.webp'
import xueshan8webp from './image/xueshan8.webp'
import xueshan9webp from './image/xueshan9.webp'
import xueshan10webp from './image/xueshan10.webp'
import huoshishanwebp from './image/huoshishan_web.webp'
import huoshishanmobilewebp from './image/huoshishan_mobile.webp'
import huoshishanplantwebp from './image/huoshishan_plant.webp'
import huoshishanpackagewebp from './image/huoshishan_package.webp'
import huoshishan1webp from './image/huoshishan1.webp'
import huoshishan2webp from './image/huoshishan2.webp'
import huoshishan3webp from './image/huoshishan3.webp'
import fenqihudadongshanwebp from './image/fenqihudadongshan_web.webp'
import fenqihudadongshanmobilewebp from './image/fenqihudadongshan_mobile.webp'
import fenqihudadongshanplantwebp from './image/fenqihudadongshan_plant.webp'
import fenqihudadongshanpackagewebp from './image/fenqihudadongshan_package.webp'
import fenqihudadongshan1webp from './image/fenqihudadongshan1.webp'
import dongyanshanwebp from './image/dongyanshan_web.webp'
import dongyanshanmobilewebp from './image/dongyanshan_mobile.webp'
import dongyanshanplantwebp from './image/dongyanshan_plant.webp'
import dongyanshanpackagewebp from './image/dongyanshan_package.webp'
import dongyanshan1webp from './image/dongyanshan1.webp'
import dongyanshan2webp from './image/dongyanshan2.webp'
import dongyanshan3webp from './image/dongyanshan3.webp'
import dongyanshan4webp from './image/dongyanshan4.webp'
import doulanshanwebp from './image/doulanshan_web.webp'
import doulanshanmobilewebp from './image/doulanshan_mobile.webp'
import doulanshanplantwebp from './image/doulanshan_plant.webp'
import doulanshanpackagewebp from './image/doulanshan_package.webp'
import doulanshan1webp from './image/doulanshan1.webp'
import doulanshan2webp from './image/doulanshan2.webp'
import doulanshan3webp from './image/doulanshan3.webp'
import doulanshan4webp from './image/doulanshan4.webp'
import doulanshan5webp from './image/doulanshan5.webp'
import doulanshan6webp from './image/doulanshan6.webp'
import liushishishanwebp from './image/liushishishan_web.webp'
import liushishishanmobilewebp from './image/liushishishan_mobile.webp'
import liushishishanplantwebp from './image/liushishishan_plant.webp'
import liushishishanpackagewebp from './image/liushishishan_package.webp'
import liushishishan1webp from './image/liushishishan1.webp'
import liushishishan2webp from './image/liushishishan2.webp'
import liushishishan3webp from './image/liushishishan3.webp'
import liushishishan4webp from './image/liushishishan4.webp'
import liushishishan5webp from './image/liushishishan5.webp'
import guanyinshanwebp from './image/guanyinshan_web.webp'
import guanyinshanmobilewebp from './image/guanyinshan_mobile.webp'
import guanyinshanplantwebp from './image/guanyinshan_plant.webp'
import guanyinshanpackagewebp from './image/guanyinshan_package.webp'
import guanyinshan1webp from './image/guanyinshan1.webp'
import guanyinshan2webp from './image/guanyinshan2.webp'
import guanyinshan3webp from './image/guanyinshan3.webp'
import liyushanwebp from './image/liyushan_web.webp'
import liyushanmobilewebp from './image/liyushan_mobile.webp'
import liyushanplantwebp from './image/liyushan_plant.webp'
import liyushanpackagewebp from './image/liyushan_package.webp'
import liyushan1webp from './image/liyushan1.webp'
import liyushan2webp from './image/liyushan2.webp'
import wuerchahushanwebp from './image/wuerchahushan_web.webp'
import wuerchahushanmobilewebp from './image/wuerchahushan_mobile.webp'
import wuerchahushanplantwebp from './image/wuerchahushan_plant.webp'
import wuerchahushanpackagewebp from './image/wuerchahushan_package.webp'
import wuerchahushan1webp from './image/wuerchahushan1.webp'
import wuerchahushan2webp from './image/wuerchahushan2.webp'
import wuerchahushan3webp from './image/wuerchahushan3.webp'
import wuerchahushan4webp from './image/wuerchahushan4.webp'
import wuerchahushan5webp from './image/wuerchahushan5.webp'
import shoushanwebp from './image/shoushan_web.webp'
import shoushanmobilewebp from './image/shoushan_mobile.webp'
import shoushanplantwebp from './image/shoushan_plant.webp'
import shoushanpackagewebp from './image/shoushan_package.webp'
import shoushan1webp from './image/shoushan1.webp'
import shoushan2webp from './image/shoushan2.webp'
import shoushan3webp from './image/shoushan3.webp'
import shoushan4webp from './image/shoushan4.webp'
import yuntaishanwebp from './image/yuntaishan_web.webp'
import yuntaishanmobilewebp from './image/yuntaishan_mobile.webp'
import yuntaishanplantwebp from './image/yuntaishan_plant.webp'
import yuntaishanpackagewebp from './image/yuntaishan_package.webp'
import yuntaishan1webp from './image/yuntaishan1.webp'
import yuntaishan2webp from './image/yuntaishan2.webp'
import yuntaishan3webp from './image/yuntaishan3.webp'
import yuntaishan4webp from './image/yuntaishan4.webp'
import yuntaishan5webp from './image/yuntaishan5.webp'
import yuntaishan6webp from './image/yuntaishan6.webp'
import dawulunshanwebp from './image/dawulunshan_web.webp'
import dawulunshanmobilewebp from './image/dawulunshan_mobile.webp'
import dawulunshanplantwebp from './image/dawulunshan_plant.webp'
import dawulunshanpackagewebp from './image/dawulunshan_package.webp'
import dawulunshan1webp from './image/dawulunshan1.webp'
import dawulunshan2webp from './image/dawulunshan2.webp'
import dawulunshan3webp from './image/dawulunshan3.webp'
import dawulunshan4webp from './image/dawulunshan4.webp'
import xiangshanwebp from './image/xiangshan_web.webp'
import xiangshanmobilewebp from './image/xiangshan_mobile.webp'
import xiangshanplantwebp from './image/xiangshan_plant.webp'
import xiangshanpackagewebp from './image/xiangshan_package.webp'
import xiangshan1webp from './image/xiangshan1.webp'
import xiangshan2webp from './image/xiangshan2.webp'
import xiangshan3webp from './image/xiangshan3.webp'

import bluebird from './image/bluebird.png'
import bluemammal from './image/bluemammal.png'
import bluereptiles from './image/bluereptiles.png'
import blueamphibian5 from './image/blueamphibian_5.png'
import blueamphibian6 from './image/blueamphibian_6.png'
import blueinsect5 from './image/blueinsect_5.png'
import blueinsect6 from './image/blueinsect_6.png'
import pinkbird from './image/pink_animal_birds.png'
import pinkmammal from './image/pink_animal_mammals.png'
import pinkreptiles from './image/pink_animal_reptiles.png'
import pinkamphibian5 from './image/pink_animal_amphibians_5.png'
import pinkamphibian6 from './image/pink_animal_amphibians_6.png'
import pinkinsect5 from './image/pink_animal_insects_5.png'
import pinkinsect6 from './image/pink_animal_insects_6.png'
import orangebird from './image/orange_animal_birds.png'
import orangemammal from './image/orange_animal_mammals.png'
import orangereptiles from './image/orange_animal_reptiles.png'
import orangeamphibian5 from './image/orange_animal_amphibians_5.png'
import orangeamphibian6 from './image/orange_animal_amphibians_6.png'
import orangeinsect5 from './image/orange_animal_insects_5.png'
import orangeinsect6 from './image/orange_animal_insects_6.png'
import greenbird from './image/green_animal_birds.png'
import greenmammal from './image/green_animal_mammals.png'
import greenreptiles from './image/green_animal_reptiles.png'
import greenamphibian5 from './image/green_animal_amphibians_5.png'
import greenamphibian6 from './image/green_animal_amphibians_6.png'
import greeninsect5 from './image/green_animal_insects_5.png'
import greeninsect6 from './image/green_animal_insects_6.png'
import coffeebird from './image/gray_animal_birds.png'
import coffeemammal from './image/gray_animal_mammals.png'
import coffeereptiles from './image/gray_animal_reptiles.png'
import coffeeamphibian5 from './image/gray_animal_amphibians_5.png'
import coffeeamphibian6 from './image/gray_animal_amphibians_6.png'
import coffeeinsect5 from './image/gray_animal_insects_5.png'
import coffeeinsect6 from './image/gray_animal_insects_6.png'

import qixingshanplant from './image/qixingshan_plant.png'
import dajianshanplant from './image/dajianshan_plant.png'
import guangtoushanplant from './image/guangtoushan_plant.png'
import xiangyangshanplant from './image/xiangyangshan_plant.png'
import quezishanplant from './image/quezishan_plant.png'
import luoshanplant from './image/luoshan_plant.png'
import hongdanshanplant from './image/hongdanshan_plant.png'
import jilongshanplant from './image/jilongshan_plant.png'
import tianshangshanplant from './image/tianshangshan_plant.png'
import feifengshanplant from './image/feifengshan_plant.png'
import lalashanplant from './image/lalashan_plant.png'
import dabajianshanplant from './image/dabajianshan_plant.png'
import taiwushanplant from './image/taiwushan_plant.png'
import taoshanplant from './image/taoshan_plant.png'
import huoyanshanplant from './image/huoyanshan_plant.png'
import lingmingshanplant from './image/lingmingshan_plant.png'
import shaolaishanplant from './image/shaolaishan_plant.png'
import yuanzuishanplant from './image/yuanzuishan_plant.png'
import chuyinshanplant from './image/chuyinshan_plant.png'
import maolanshanplant from './image/maolanshan_plant.png'
import yuemeishanplant from './image/yuemeishan_plant.png'
import kuibishanplant from './image/kuibishan_plant.png'
import yunjiadajianshanplant from './image/yunjiadajianshan_plant.png'
import alishanplant from './image/alishan_plant.png'
import xiuguluanshanplant from './image/xiuguluanshan_plant.png'
import lushanplant from './image/lushan_plant.png'
import kantoushanplant from './image/kantoushan_plant.png'
import zhuzijianshanplant from './image/zhuzijianshan_plant.png'
import zhongniuhushanplant from './image/zhongniuhushan_plant.png'
import luohanshanplant from './image/luohanshan_plant.png'
import huoshaoshanplant from './image/huoshaoshan_plant.png'
import taimalishanplant from './image/taimalishan_plant.png'
import lidingshanplant from './image/lidingshan_plant.png'
import hongtoushanplant from './image/hongtoushan_plant.png'

import qixingshanplantwebp from './image/qixingshan_plant.webp'
import dajianshanplantwebp from './image/dajianshan_plant.webp'
import guangtoushanplantwebp from './image/guangtoushan_plant.webp'
import xiangyangshanplantwebp from './image/xiangyangshan_plant.webp'
import quezishanplantwebp from './image/quezishan_plant.webp'
import luoshanplantwebp from './image/luoshan_plant.webp'
import hongdanshanplantwebp from './image/hongdanshan_plant.webp'
import jilongshanplantwebp from './image/jilongshan_plant.webp'
import tianshangshanplantwebp from './image/tianshangshan_plant.webp'
import feifengshanplantwebp from './image/feifengshan_plant.webp'
import lalashanplantwebp from './image/lalashan_plant.webp'
import dabajianshanplantwebp from './image/dabajianshan_plant.webp'
import taiwushanplantwebp from './image/taiwushan_plant.webp'
import taoshanplantwebp from './image/taoshan_plant.webp'
import huoyanshanplantwebp from './image/huoyanshan_plant.webp'
import lingmingshanplantwebp from './image/lingmingshan_plant.webp'
import shaolaishanplantwebp from './image/shaolaishan_plant.webp'
import yuanzuishanplantwebp from './image/yuanzuishan_plant.webp'
import chuyinshanplantwebp from './image/chuyinshan_plant.webp'
import maolanshanplantwebp from './image/maolanshan_plant.webp'
import yuemeishanplantwebp from './image/yuemeishan_plant.webp'
import kuibishanplantwebp from './image/kuibishan_plant.webp'
import yunjiadajianshanplantwebp from './image/yunjiadajianshan_plant.webp'
import alishanplantwebp from './image/alishan_plant.webp'
import xiuguluanshanplantwebp from './image/xiuguluanshan_plant.webp'
import lushanplantwebp from './image/lushan_plant.webp'
import kantoushanplantwebp from './image/kantoushan_plant.webp'
import zhuzijianshanplantwebp from './image/zhuzijianshan_plant.webp'
import zhongniuhushanplantwebp from './image/zhongniuhushan_plant.webp'
import luohanshanplantwebp from './image/luohanshan_plant.webp'
import huoshaoshanplantwebp from './image/huoshaoshan_plant.webp'
import taimalishanplantwebp from './image/taimalishan_plant.webp'
import lidingshanplantwebp from './image/lidingshan_plant.webp'
import hongtoushanplantwebp from './image/hongtoushan_plant.webp'

import qixingshanpackage from './image/qixingshan_package.png'
import dajianshanpackage from './image/dajianshan_package.png'
import guangtoushanpackage from './image/guangtoushan_package.png'
import xiangyangshanpackage from './image/xiangyangshan_package.png'
import quezishanpackage from './image/quezishan_package.png'
import luoshanpackage from './image/luoshan_package.png'
import hongdanshanpackage from './image/hongdanshan_package.png'
import jilongshanpackage from './image/jilongshan_package.png'
import tianshangshanpackage from './image/tianshangshan_package.png'
import feifengshanpackage from './image/feifengshan_package.png'
import lalashanpackage from './image/lalashan_package.png'
import dabajianshanpackage from './image/dabajianshan_package.png'
import taiwushanpackage from './image/taiwushan_package.png'
import taoshanpackage from './image/taoshan_package.png'
import huoyanshanpackage from './image/huoyanshan_package.png'
import lingmingshanpackage from './image/lingmingshan_package.png'
import shaolaishanpackage from './image/shaolaishan_package.png'
import yuanzuishanpackage from './image/yuanzuishan_package.png'
import chuyinshanpackage from './image/chuyinshan_package.png'
import maolanshanpackage from './image/maolanshan_package.png'
import yuemeishanpackage from './image/yuemeishan_package.png'
import kuibishanpackage from './image/kuibishan_package.png'
import yunjiadajianshanpackage from './image/yunjiadajianshan_package.png'
import alishanpackage from './image/alishan_package.png'
import xiuguluanshanpackage from './image/xiuguluanshan_package.png'
import lushanpackage from './image/lushan_package.png'
import kantoushanpackage from './image/kantoushan_package.png'
import zhuzijianshanpackage from './image/zhuzijianshan_package.png'
import zhongniuhushanpackage from './image/zhongniuhushan_package.png'
import luohanshanpackage from './image/luohanshan_package.png'
import huoshaoshanpackage from './image/huoshaoshan_package.png'
import taimalishanpackage from './image/taimalishan_package.png'
import lidingshanpackage from './image/lidingshan_package.png'
import hongtoushanpackage from './image/hongtoushan_package.png'

import qixingshanpackagewebp from './image/qixingshan_package.webp'
import dajianshanpackagewebp from './image/dajianshan_package.webp'
import guangtoushanpackagewebp from './image/guangtoushan_package.webp'
import xiangyangshanpackagewebp from './image/xiangyangshan_package.webp'
import quezishanpackagewebp from './image/quezishan_package.webp'
import luoshanpackagewebp from './image/luoshan_package.webp'
import hongdanshanpackagewebp from './image/hongdanshan_package.webp'
import jilongshanpackagewebp from './image/jilongshan_package.webp'
import tianshangshanpackagewebp from './image/tianshangshan_package.webp'
import feifengshanpackagewebp from './image/feifengshan_package.webp'
import lalashanpackagewebp from './image/lalashan_package.webp'
import dabajianshanpackagewebp from './image/dabajianshan_package.webp'
import taiwushanpackagewebp from './image/taiwushan_package.webp'
import taoshanpackagewebp from './image/taoshan_package.webp'
import huoyanshanpackagewebp from './image/huoyanshan_package.webp'
import lingmingshanpackagewebp from './image/lingmingshan_package.webp'
import shaolaishanpackagewebp from './image/shaolaishan_package.webp'
import yuanzuishanpackagewebp from './image/yuanzuishan_package.webp'
import chuyinshanpackagewebp from './image/chuyinshan_package.webp'
import maolanshanpackagewebp from './image/maolanshan_package.webp'
import yuemeishanpackagewebp from './image/yuemeishan_package.webp'
import kuibishanpackagewebp from './image/kuibishan_package.webp'
import yunjiadajianshanpackagewebp from './image/yunjiadajianshan_package.webp'
import alishanpackagewebp from './image/alishan_package.webp'
import xiuguluanshanpackagewebp from './image/xiuguluanshan_package.webp'
import lushanpackagewebp from './image/lushan_package.webp'
import kantoushanpackagewebp from './image/kantoushan_package.webp'
import zhuzijianshanpackagewebp from './image/zhuzijianshan_package.webp'
import zhongniuhushanpackagewebp from './image/zhongniuhushan_package.webp'
import luohanshanpackagewebp from './image/luohanshan_package.webp'
import huoshaoshanpackagewebp from './image/huoshaoshan_package.webp'
import taimalishanpackagewebp from './image/taimalishan_package.webp'
import lidingshanpackagewebp from './image/lidingshan_package.webp'
import hongtoushanpackagewebp from './image/hongtoushan_package.webp'

import xiuguluanshan from './image/xiuguluanshan_web.png'
import xiuguluanshanmobile from './image/xiuguluanshan_mobile.png'
import xiuguluanshanwebp from './image/xiuguluanshan_web.webp'
import xiuguluanshanmobilewebp from './image/xiuguluanshan_mobile.webp'

import xiangyangshan from './image/xiangyangshan_web.png'
import xiangyangshanmobile from './image/xiangyangshan_mobile.png'
import xiangyangshanwebp from './image/xiangyangshan_web.webp'
import xiangyangshanmobilewebp from './image/xiangyangshan_mobile.webp'

import taoshan from './image/taoshan_web.png'
import taoshanmobile from './image/taoshan_mobile.png'
import taoshanwebp from './image/taoshan_web.webp'
import taoshanmobilewebp from './image/taoshan_mobile.webp'

import lingmingshan from './image/lingmingshan_web.png'
import lingmingshanmobile from './image/lingmingshan_mobile.png'
import lingmingshanwebp from './image/lingmingshan_web.webp'
import lingmingshanmobilewebp from './image/lingmingshan_mobile.webp'

import shaolaishan from './image/shaolaishan_web.png'
import shaolaishanmobile from './image/shaolaishan_mobile.png'
import shaolaishanwebp from './image/shaolaishan_web.webp'
import shaolaishanmobilewebp from './image/shaolaishan_mobile.webp'

import alishan from './image/alishan_web.png'
import alishanmobile from './image/alishan_mobile.png'
import alishanwebp from './image/alishan_web.webp'
import alishanmobilewebp from './image/alishan_mobile.webp'

import taimalishan from './image/taimalishan_web.png'
import taimalishanmobile from './image/taimalishan_mobile.png'
import taimalishanwebp from './image/taimalishan_web.webp'
import taimalishanmobilewebp from './image/taimalishan_mobile.webp'

import yunjiadajianshan from './image/yunjiadajianshan_web.png'
import yunjiadajianshanmobile from './image/yunjiadajianshan_mobile.png'
import yunjiadajianshanwebp from './image/yunjiadajianshan_web.webp'
import yunjiadajianshanmobilewebp from './image/yunjiadajianshan_mobile.webp'

import qixingshan from './image/qixingshan_web.png'
import qixingshanmobile from './image/qixingshan_mobile.png'
import qixingshanwebp from './image/qixingshan_web.webp'
import qixingshanmobilewebp from './image/qixingshan_mobile.webp'

import kantoushan from './image/kantoushan_web.png'
import kantoushanmobile from './image/kantoushan_mobile.png'
import kantoushanwebp from './image/kantoushan_web.webp'
import kantoushanmobilewebp from './image/kantoushan_mobile.webp'

import quezishan from './image/quezishan_web.png'
import quezishanmobile from './image/quezishan_mobile.png'
import quezishanwebp from './image/quezishan_web.webp'
import quezishanmobilewebp from './image/quezishan_mobile.webp'

import lidingshan from './image/lidingshan_web.png'
import lidingshanmobile from './image/lidingshan_mobile.png'
import lidingshanwebp from './image/lidingshan_web.webp'
import lidingshanmobilewebp from './image/lidingshan_mobile.webp'

import hongtoushan from './image/hongtoushan_web.png'
import hongtoushanmobile from './image/hongtoushan_mobile.png'
import hongtoushanwebp from './image/hongtoushan_web.webp'
import hongtoushanmobilewebp from './image/hongtoushan_mobile.webp'

import tianshangshan from './image/tianshangshan_web.png'
import tianshangshanmobile from './image/tianshangshan_mobile.png'
import tianshangshanwebp from './image/tianshangshan_web.webp'
import tianshangshanmobilewebp from './image/tianshangshan_mobile.webp'

import feifengshan from './image/feifengshan_web.png'
import feifengshanmobile from './image/feifengshan_mobile.png'
import feifengshanwebp from './image/feifengshan_web.webp'
import feifengshanmobilewebp from './image/feifengshan_mobile.webp'

import huoshaoshan from './image/huoshaoshan_web.png'
import huoshaoshanmobile from './image/huoshaoshan_mobile.png'
import huoshaoshanwebp from './image/huoshaoshan_web.webp'
import huoshaoshanmobilewebp from './image/huoshaoshan_mobile.webp'

import taiwushan from './image/taiwushan_web.png'
import taiwushanmobile from './image/taiwushan_mobile.png'
import taiwushanwebp from './image/taiwushan_web.webp'
import taiwushanmobilewebp from './image/taiwushan_mobile.webp'

import dabajianshan from './image/dabajianshan_web.png'
import dabajianshanmobile from './image/dabajianshan_mobile.png'
import dabajianshanwebp from './image/dabajianshan_web.webp'
import dabajianshanmobilewebp from './image/dabajianshan_mobile.webp'

import lushan from './image/lushan_web.png'
import lushanmobile from './image/lushan_mobile.png'
import lushanwebp from './image/lushan_web.webp'
import lushanmobilewebp from './image/lushan_mobile.webp'

import guangtoushan from './image/guangtoushan_web.png'
import guangtoushanmobile from './image/guangtoushan_mobile.png'
import guangtoushanwebp from './image/guangtoushan_web.webp'
import guangtoushanmobilewebp from './image/guangtoushan_mobile.webp'

import dajianshan from './image/dajianshan_web.png'
import dajianshanmobile from './image/dajianshan_mobile.png'
import dajianshanwebp from './image/dajianshan_web.webp'
import dajianshanmobilewebp from './image/dajianshan_mobile.webp'
import yuanzuishan from './image/yuanzuishan_web.png'
import yuanzuishanmobile from './image/yuanzuishan_mobile.png'
import yuanzuishanwebp from './image/yuanzuishan_web.webp'
import yuanzuishanmobilewebp from './image/yuanzuishan_mobile.webp'
import lalashan from './image/lalashan_web.png'
import lalashanmobile from './image/lalashan_mobile.png'
import lalashanwebp from './image/lalashan_web.webp'
import lalashanmobilewebp from './image/lalashan_mobile.webp'
import zhuzijianshan from './image/zhuzijianshan_web.png'
import zhuzijianshanmobile from './image/zhuzijianshan_mobile.png'
import zhuzijianshanwebp from './image/zhuzijianshan_web.webp'
import zhuzijianshanmobilewebp from './image/zhuzijianshan_mobile.webp'
import maolanshan from './image/maolanshan_web.png'
import maolanshanmobile from './image/maolanshan_mobile.png'
import maolanshanwebp from './image/maolanshan_web.webp'
import maolanshanmobilewebp from './image/maolanshan_mobile.webp'
import chuyinshan from './image/chuyinshan_web.png'
import chuyinshanmobile from './image/chuyinshan_mobile.png'
import chuyinshanwebp from './image/chuyinshan_web.webp'
import chuyinshanmobilewebp from './image/chuyinshan_mobile.webp'
import zhongniuhushan from './image/zhongniuhushan_web.png'
import zhongniuhushanmobile from './image/zhongniuhushan_mobile.png'
import zhongniuhushanwebp from './image/zhongniuhushan_web.webp'
import zhongniuhushanmobilewebp from './image/zhongniuhushan_mobile.webp'
import yuemeishan from './image/yuemeishan_web.png'
import yuemeishanmobile from './image/yuemeishan_mobile.png'
import yuemeishanwebp from './image/yuemeishan_web.webp'
import yuemeishanmobilewebp from './image/yuemeishan_mobile.webp'
import huoyanshan from './image/huoyanshan_web.png'
import huoyanshanmobile from './image/huoyanshan_mobile.png'
import huoyanshanwebp from './image/huoyanshan_web.webp'
import huoyanshanmobilewebp from './image/huoyanshan_mobile.webp'
import jilongshan from './image/jilongshan_web.png'
import jilongshanmobile from './image/jilongshan_mobile.png'
import jilongshanwebp from './image/jilongshan_web.webp'
import jilongshanmobilewebp from './image/jilongshan_mobile.webp'
import hongdanshan from './image/hongdanshan_web.png'
import hongdanshanmobile from './image/hongdanshan_mobile.png'
import hongdanshanwebp from './image/hongdanshan_web.webp'
import hongdanshanmobilewebp from './image/hongdanshan_mobile.webp'
import luohanshan from './image/luohanshan_web.png'
import luohanshanmobile from './image/luohanshan_mobile.png'
import luohanshanwebp from './image/luohanshan_web.webp'
import luohanshanmobilewebp from './image/luohanshan_mobile.webp'
import luoshan from './image/luoshan_web.png'
import luoshanmobile from './image/luoshan_mobile.png'
import luoshanwebp from './image/luoshan_web.webp'
import luoshanmobilewebp from './image/luoshan_mobile.webp'
import kuibishan from './image/kuibishan_web.png'
import kuibishanmobile from './image/kuibishan_mobile.png'
import kuibishanwebp from './image/kuibishan_web.webp'
import kuibishanmobilewebp from './image/kuibishan_mobile.webp'

import xiuguluanshan1 from './image/xiuguluanshan1.jpg'
import xiuguluanshan1webp from './image/xiuguluanshan1.webp'
import xiuguluanshan2 from './image/xiuguluanshan2.jpg'
import xiuguluanshan2webp from './image/xiuguluanshan2.webp'
import xiuguluanshan3 from './image/xiuguluanshan3.jpg'
import xiuguluanshan3webp from './image/xiuguluanshan3.webp'
import xiuguluanshan4 from './image/xiuguluanshan4.jpg'
import xiuguluanshan4webp from './image/xiuguluanshan4.webp'
import xiuguluanshan5 from './image/xiuguluanshan5.jpg'
import xiuguluanshan5webp from './image/xiuguluanshan5.webp'
import xiuguluanshan6 from './image/xiuguluanshan6.jpg'
import xiuguluanshan6webp from './image/xiuguluanshan6.webp'
import xiuguluanshan7 from './image/xiuguluanshan7.jpg'
import xiuguluanshan7webp from './image/xiuguluanshan7.webp'

import xiangyangshan1 from './image/xiangyangshan1.jpg'
import xiangyangshan1webp from './image/xiangyangshan1.webp'
import xiangyangshan2 from './image/xiangyangshan2.jpg'
import xiangyangshan2webp from './image/xiangyangshan2.webp'
import xiangyangshan3 from './image/xiangyangshan3.jpg'
import xiangyangshan3webp from './image/xiangyangshan3.webp'
import xiangyangshan4 from './image/xiangyangshan4.jpg'
import xiangyangshan4webp from './image/xiangyangshan4.webp'
import xiangyangshan5 from './image/xiangyangshan5.jpg'
import xiangyangshan5webp from './image/xiangyangshan5.webp'

import taoshan1 from './image/taoshan1.jpg'
import taoshan1webp from './image/taoshan1.webp'
import taoshan2 from './image/taoshan2.jpg'
import taoshan2webp from './image/taoshan2.webp'
import taoshan3 from './image/taoshan3.jpg'
import taoshan3webp from './image/taoshan3.webp'
import taoshan4 from './image/taoshan4.jpg'
import taoshan4webp from './image/taoshan4.webp'
import taoshan5 from './image/taoshan5.jpg'
import taoshan5webp from './image/taoshan5.webp'

import lingmingshan1 from './image/lingmingshan1.jpg'
import lingmingshan1webp from './image/lingmingshan1.webp'
import lingmingshan2 from './image/lingmingshan2.jpg'
import lingmingshan2webp from './image/lingmingshan2.webp'
import lingmingshan3 from './image/lingmingshan3.jpg'
import lingmingshan3webp from './image/lingmingshan3.webp'
import lingmingshan4 from './image/lingmingshan4.jpg'
import lingmingshan4webp from './image/lingmingshan4.webp'
import lingmingshan5 from './image/lingmingshan5.jpg'
import lingmingshan5webp from './image/lingmingshan5.webp'
import lingmingshan6 from './image/lingmingshan6.jpg'
import lingmingshan6webp from './image/lingmingshan6.webp'
import lingmingshan7 from './image/lingmingshan7.jpg'
import lingmingshan7webp from './image/lingmingshan7.webp'

import shaolaishan1 from './image/shaolaishan1.jpg'
import shaolaishan1webp from './image/shaolaishan1.webp'
import shaolaishan2 from './image/shaolaishan2.jpg'
import shaolaishan2webp from './image/shaolaishan2.webp'
import shaolaishan3 from './image/shaolaishan3.jpg'
import shaolaishan3webp from './image/shaolaishan3.webp'

import alishan1 from './image/alishan1.jpg'
import alishan1webp from './image/alishan1.webp'
import alishan2 from './image/alishan2.jpg'
import alishan2webp from './image/alishan2.webp'
import alishan3 from './image/alishan3.jpg'
import alishan3webp from './image/alishan3.webp'
import alishan4 from './image/alishan4.jpg'
import alishan4webp from './image/alishan4.webp'
import alishan5 from './image/alishan5.jpg'
import alishan5webp from './image/alishan5.webp'

import taimalishan1 from './image/taimalishan1.jpg'
import taimalishan1webp from './image/taimalishan1.webp'
import taimalishan2 from './image/taimalishan2.jpg'
import taimalishan2webp from './image/taimalishan2.webp'
import taimalishan3 from './image/taimalishan3.jpg'
import taimalishan3webp from './image/taimalishan3.webp'
import taimalishan4 from './image/taimalishan4.jpg'
import taimalishan4webp from './image/taimalishan4.webp'
import taimalishan5 from './image/taimalishan5.jpg'
import taimalishan5webp from './image/taimalishan5.webp'
import taimalishan6 from './image/taimalishan6.jpg'
import taimalishan6webp from './image/taimalishan6.webp'

import yunjiadajianshan1 from './image/yunjiadajianshan1.jpg'
import yunjiadajianshan1webp from './image/yunjiadajianshan1.webp'
import yunjiadajianshan2 from './image/yunjiadajianshan2.jpg'
import yunjiadajianshan2webp from './image/yunjiadajianshan2.webp'
import yunjiadajianshan3 from './image/yunjiadajianshan3.jpg'
import yunjiadajianshan3webp from './image/yunjiadajianshan3.webp'
import yunjiadajianshan4 from './image/yunjiadajianshan4.jpg'
import yunjiadajianshan4webp from './image/yunjiadajianshan4.webp'

import qixingshan1 from './image/qixingshan1.jpg'
import qixingshan1webp from './image/qixingshan1.webp'
import qixingshan2 from './image/qixingshan2.jpg'
import qixingshan2webp from './image/qixingshan2.webp'
import qixingshan3 from './image/qixingshan3.jpg'
import qixingshan3webp from './image/qixingshan3.webp'
import qixingshan4 from './image/qixingshan4.jpg'
import qixingshan4webp from './image/qixingshan4.webp'
import qixingshan5 from './image/qixingshan5.jpg'
import qixingshan5webp from './image/qixingshan5.webp'
import qixingshan6 from './image/qixingshan6.jpg'
import qixingshan6webp from './image/qixingshan6.webp'

import kantoushan1 from './image/kantoushan1.jpg'
import kantoushan1webp from './image/kantoushan1.webp'
import kantoushan2 from './image/kantoushan2.jpg'
import kantoushan2webp from './image/kantoushan2.webp'
import kantoushan3 from './image/kantoushan3.jpg'
import kantoushan3webp from './image/kantoushan3.webp'

import quezishan1 from './image/quezishan1.jpeg'
import quezishan1webp from './image/quezishan1.webp'
import quezishan2 from './image/quezishan2.jpg'
import quezishan2webp from './image/quezishan2.webp'
import quezishan3 from './image/quezishan3.jpg'
import quezishan3webp from './image/quezishan3.webp'

import lidingshan1 from './image/lidingshan1.jpg'
import lidingshan1webp from './image/lidingshan1.webp'
import lidingshan2 from './image/lidingshan2.jpg'
import lidingshan2webp from './image/lidingshan2.webp'
import lidingshan3 from './image/lidingshan3.jpg'
import lidingshan3webp from './image/lidingshan3.webp'
import lidingshan4 from './image/lidingshan4.jpg'
import lidingshan4webp from './image/lidingshan4.webp'

import hongtoushan1 from './image/hongtoushan1.jpg'
import hongtoushan1webp from './image/hongtoushan1.webp'
import hongtoushan2 from './image/hongtoushan2.jpg'
import hongtoushan2webp from './image/hongtoushan2.webp'
import hongtoushan3 from './image/hongtoushan3.jpg'
import hongtoushan3webp from './image/hongtoushan3.webp'
import hongtoushan4 from './image/hongtoushan4.jpg'
import hongtoushan4webp from './image/hongtoushan4.webp'

import tianshangshan1 from './image/tianshangshan1.jpg'
import tianshangshan1webp from './image/tianshangshan1.webp'
import tianshangshan2 from './image/tianshangshan2.jpg'
import tianshangshan2webp from './image/tianshangshan2.webp'
import tianshangshan3 from './image/tianshangshan3.jpg'
import tianshangshan3webp from './image/tianshangshan3.webp'

import feifengshan1 from './image/feifengshan1.jpg'
import feifengshan1webp from './image/feifengshan1.webp'
import feifengshan2 from './image/feifengshan2.jpg'
import feifengshan2webp from './image/feifengshan2.webp'
import feifengshan3 from './image/feifengshan3.jpg'
import feifengshan3webp from './image/feifengshan3.webp'
import feifengshan4 from './image/feifengshan4.jpg'
import feifengshan4webp from './image/feifengshan4.webp'

import taiwushan1 from './image/taiwushan1.jpg'
import taiwushan1webp from './image/taiwushan1.webp'
import taiwushan2 from './image/taiwushan2.jpg'
import taiwushan2webp from './image/taiwushan2.webp'
import taiwushan3 from './image/taiwushan3.jpg'
import taiwushan3webp from './image/taiwushan3.webp'
import taiwushan4 from './image/taiwushan4.jpg'
import taiwushan4webp from './image/taiwushan4.webp'
import taiwushan5 from './image/taiwushan5.jpg'
import taiwushan5webp from './image/taiwushan5.webp'

import dabajianshan1 from './image/dabajianshan1.jpg'
import dabajianshan1webp from './image/dabajianshan1.webp'
import dabajianshan2 from './image/dabajianshan2.jpg'
import dabajianshan2webp from './image/dabajianshan2.webp'
import dabajianshan3 from './image/dabajianshan3.jpg'
import dabajianshan3webp from './image/dabajianshan3.webp'
import dabajianshan4 from './image/dabajianshan4.jpg'
import dabajianshan4webp from './image/dabajianshan4.webp'

import lushan1 from './image/lushan1.jpg'
import lushan1webp from './image/lushan1.webp'
import lushan2 from './image/lushan2.jpg'
import lushan2webp from './image/lushan2.webp'
import lushan3 from './image/lushan3.jpg'
import lushan3webp from './image/lushan3.webp'
import lushan4 from './image/lushan4.jpg'
import lushan4webp from './image/lushan4.webp'

import guangtoushan1 from './image/guangtoushan1.jpeg'
import guangtoushan1webp from './image/guangtoushan1.webp'
import guangtoushan2 from './image/guangtoushan2.jpg'
import guangtoushan2webp from './image/guangtoushan2.webp'

import dajianshan1 from './image/dajianshan1.jpg'
import dajianshan1webp from './image/dajianshan1.webp'
import dajianshan2 from './image/dajianshan2.jpg'
import dajianshan2webp from './image/dajianshan2.webp'
import dajianshan3 from './image/dajianshan3.jpg'
import dajianshan3webp from './image/dajianshan3.webp'
import dajianshan4 from './image/dajianshan4.jpg'
import dajianshan4webp from './image/dajianshan4.webp'
import dajianshan5 from './image/dajianshan5.jpg'
import dajianshan5webp from './image/dajianshan5.webp'
import dajianshan6 from './image/dajianshan6.jpg'
import dajianshan6webp from './image/dajianshan6.webp'

import yuanzuishan1 from './image/yuanzuishan1.jpg'
import yuanzuishan1webp from './image/yuanzuishan1.webp'
import yuanzuishan2 from './image/yuanzuishan2.jpg'
import yuanzuishan2webp from './image/yuanzuishan2.webp'
import yuanzuishan3 from './image/yuanzuishan3.jpg'
import yuanzuishan3webp from './image/yuanzuishan3.webp'
import yuanzuishan4 from './image/yuanzuishan4.jpg'
import yuanzuishan4webp from './image/yuanzuishan4.webp'

import lalashan1 from './image/lalashan1.jpg'
import lalashan1webp from './image/lalashan1.webp'
import lalashan2 from './image/lalashan2.jpg'
import lalashan2webp from './image/lalashan2.webp'
import lalashan3 from './image/lalashan3.jpg'
import lalashan3webp from './image/lalashan3.webp'
import lalashan4 from './image/lalashan4.jpg'
import lalashan4webp from './image/lalashan4.webp'

import zhuzijianshan1 from './image/zhuzijianshan1.jpeg'
import zhuzijianshan1webp from './image/zhuzijianshan1.webp'

import maolanshan1 from './image/maolanshan1.jpeg'
import maolanshan1webp from './image/maolanshan1.webp'

import zhongniuhushan1 from './image/zhongniuhushan1.jpeg'
import zhongniuhushan1webp from './image/zhongniuhushan1.webp'
import zhongniuhushan2 from './image/zhongniuhushan2.jpeg'
import zhongniuhushan2webp from './image/zhongniuhushan2.webp'

import yuemeishan1 from './image/yuemeishan1.jpg'
import yuemeishan1webp from './image/yuemeishan1.webp'
import yuemeishan2 from './image/yuemeishan2.jpg'
import yuemeishan2webp from './image/yuemeishan2.webp'

import huoyanshan1 from './image/huoyanshan1.jpg'
import huoyanshan1webp from './image/huoyanshan1.webp'
import huoyanshan2 from './image/huoyanshan2.jpg'
import huoyanshan2webp from './image/huoyanshan2.webp'
import huoyanshan3 from './image/huoyanshan3.jpg'
import huoyanshan3webp from './image/huoyanshan3.webp'
import huoyanshan4 from './image/huoyanshan4.jpg'
import huoyanshan4webp from './image/huoyanshan4.webp'

import jilongshan1 from './image/jilongshan1.jpg'
import jilongshan1webp from './image/jilongshan1.webp'
import jilongshan2 from './image/jilongshan2.jpg'
import jilongshan2webp from './image/jilongshan2.webp'
import jilongshan3 from './image/jilongshan3.jpg'
import jilongshan3webp from './image/jilongshan3.webp'

import hongdanshan1 from './image/hongdanshan1.jpeg'
import hongdanshan1webp from './image/hongdanshan1.webp'
import hongdanshan2 from './image/hongdanshan2.jpeg'
import hongdanshan2webp from './image/hongdanshan2.webp'

import luoshan1 from './image/luoshan1.jpg'
import luoshan1webp from './image/luoshan1.webp'
import luoshan2 from './image/luoshan2.jpg'
import luoshan2webp from './image/luoshan2.webp'
import luoshan3 from './image/luoshan3.jpg'
import luoshan3webp from './image/luoshan3.webp'

import kuibishan1 from './image/kuibishan1.jpg'
import kuibishan1webp from './image/kuibishan1.webp'
import kuibishan2 from './image/kuibishan2.jpg'
import kuibishan2webp from './image/kuibishan2.webp'
import kuibishan3 from './image/kuibishan3.jpg'
import kuibishan3webp from './image/kuibishan3.webp'
import kuibishan4 from './image/kuibishan4.jpg'
import kuibishan4webp from './image/kuibishan4.webp'

import bluebirdwebp from './image/bluebird.webp'
import bluemammalwebp from './image/bluemammal.webp'
import bluereptileswebp from './image/bluereptiles.webp'
import blueamphibian5webp from './image/blueamphibian_5.webp'
import blueamphibian6webp from './image/blueamphibian_6.webp'
import blueinsect5webp from './image/blueinsect_5.webp'
import blueinsect6webp from './image/blueinsect_6.webp'
import pinkbirdwebp from './image/pink_animal_birds.webp'
import pinkmammalwebp from './image/pink_animal_mammals.webp'
import pinkreptileswebp from './image/pink_animal_reptiles.webp'
import pinkamphibian5webp from './image/pink_animal_amphibians_5.webp'
import pinkamphibian6webp from './image/pink_animal_amphibians_6.webp'
import pinkinsect5webp from './image/pink_animal_insects_5.webp'
import pinkinsect6webp from './image/pink_animal_insects_6.webp'
import orangebirdwebp from './image/orange_animal_birds.webp'
import orangemammalwebp from './image/orange_animal_mammals.webp'
import orangereptileswebp from './image/orange_animal_reptiles.webp'
import orangeamphibian5webp from './image/orange_animal_amphibians_5.webp'
import orangeamphibian6webp from './image/orange_animal_amphibians_6.webp'
import orangeinsect5webp from './image/orange_animal_insects_5.webp'
import orangeinsect6webp from './image/orange_animal_insects_6.webp'
import greenbirdwebp from './image/green_animal_birds.webp'
import greenmammalwebp from './image/green_animal_mammals.webp'
import greenreptileswebp from './image/green_animal_reptiles.webp'
import greenamphibian5webp from './image/green_animal_amphibians_5.webp'
import greenamphibian6webp from './image/green_animal_amphibians_6.webp'
import greeninsect5webp from './image/green_animal_insects_5.webp'
import greeninsect6webp from './image/green_animal_insects_6.webp'
import coffeebirdwebp from './image/gray_animal_birds.webp'
import coffeemammalwebp from './image/gray_animal_mammals.webp'
import coffeereptileswebp from './image/gray_animal_reptiles.webp'
import coffeeamphibian5webp from './image/gray_animal_amphibians_5.webp'
import coffeeamphibian6webp from './image/gray_animal_amphibians_6.webp'
import coffeeinsect5webp from './image/gray_animal_insects_5.webp'
import coffeeinsect6webp from './image/gray_animal_insects_6.webp'

import Normal from './Normal'
import Protect from './Protect'
import Special from './Special'
import Unique from './Unique'

export const coverImg = {
  yushan: [[yushanwebp, yushan], [yushanmobilewebp, yushanmobile]],
  xueshan: [[xueshanwebp, xueshan], [xueshanmobilewebp, xueshanmobile]],
  xiuguluanshan: [[xiuguluanshanwebp, xiuguluanshan], [xiuguluanshanmobilewebp,  xiuguluanshanmobile]],
  xiangyangshan: [[xiangyangshanwebp, xiangyangshan], [xiangyangshanmobilewebp,  xiangyangshanmobile]],
  dabajianshan: [[dabajianshanwebp, dabajianshan], [dabajianshanmobilewebp, dabajianshanmobile]],
  taoshan: [[taoshanwebp, taoshan], [taoshanmobilewebp, taoshanmobile]],
  huoshishan: [[huoshishanwebp, huoshishan], [huoshishanmobilewebp, huoshishanmobile]],
  lingmingshan: [[lingmingshanwebp, lingmingshan], [lingmingshanmobilewebp, lingmingshanmobile]],
  lushan: [[lushanwebp, lushan], [lushanmobilewebp, lushanmobile]],
  guangtoushan: [[guangtoushanwebp, guangtoushan], [guangtoushanmobilewebp, guangtoushanmobile]],
  dajianshan: [[dajianshanwebp, dajianshan], [dajianshanmobilewebp, dajianshanmobile]],
  shaolaishan: [[shaolaishanwebp, shaolaishan], [shaolaishanmobilewebp, shaolaishanmobile]],
  jialishan: [[jialishanwebp, jialishan], [jialishanmobilewebp, jialishanmobile]],
  alishan: [[alishanwebp, alishan], [alishanmobilewebp, alishanmobile]],
  yuanzuishan: [[yuanzuishanwebp, yuanzuishan], [yuanzuishanmobilewebp, yuanzuishanmobile]],
  lalashan: [[lalashanwebp, lalashan], [lalashanmobilewebp, lalashanmobile]],
  taipingshan: [[taipingshanwebp, taipingshan], [taipingshanmobilewebp, taipingshanmobile]],
  fenqihudadongshan: [[fenqihudadongshanwebp, fenqihudadongshan], [fenqihudadongshanmobilewebp, fenqihudadongshanmobile]],
  taimalishan: [[taimalishanwebp, taimalishan], [taimalishanmobilewebp, taimalishanmobile]],
  yunjiadajianshan: [[yunjiadajianshanwebp, yunjiadajianshan], [yunjiadajianshanmobilewebp, yunjiadajianshanmobile]],
  dongyanshan: [[dongyanshanwebp, dongyanshan], [dongyanshanmobilewebp, dongyanshanmobile]],
  doulanshan: [[doulanshanwebp, doulanshan], [doulanshanmobilewebp, doulanshanmobile]],
  qixingshan: [[qixingshanwebp, qixingshan], [qixingshanmobilewebp, qixingshanmobile]],
  zhuzijianshan: [[zhuzijianshanwebp, zhuzijianshan], [zhuzijianshanmobilewebp, zhuzijianshanmobile]],
  maolanshan: [[maolanshanwebp, maolanshan], [maolanshanmobilewebp, maolanshanmobile]],
  shengmudengshan: [[shengmudengshanwebp, shengmudengshan], [shengmudengshanmobilewebp, shengmudengshanmobile]],
  liushishishan: [[liushishishanwebp, liushishishan], [liushishishanmobilewebp, liushishishanmobile]],
  chuyinshan: [[chuyinshanwebp, chuyinshan], [chuyinshanmobilewebp, chuyinshanmobile]],
  kantoushan: [[kantoushanwebp, kantoushan], [kantoushanmobilewebp, kantoushanmobile]],
  zhongniuhushan: [[zhongniuhushanwebp, zhongniuhushan], [zhongniuhushanmobilewebp, zhongniuhushanmobile]],
  quezishan: [[quezishanwebp, quezishan], [quezishanmobilewebp, quezishanmobile]],
  lidingshan: [[lidingshanwebp, lidingshan], [lidingshanmobilewebp, lidingshanmobile]],
  guanyinshan: [[guanyinshanwebp, guanyinshan], [guanyinshanmobilewebp, guanyinshanmobile]],
  yuemeishan: [[yuemeishanwebp, yuemeishan], [yuemeishanmobilewebp, yuemeishanmobile]],
  huoyanshan: [[huoyanshanwebp, huoyanshan], [huoyanshanmobilewebp, huoyanshanmobile]],
  liyushan: [[liyushanwebp, liyushan], [liyushanmobilewebp, liyushanmobile]],
  jilongshan: [[jilongshanwebp, jilongshan], [jilongshanmobilewebp, jilongshanmobile]],
  wuerchahushan: [[wuerchahushanwebp, wuerchahushan], [wuerchahushanmobilewebp, wuerchahushanmobile]],
  hongtoushan: [[hongtoushanwebp, hongtoushan], [hongtoushanmobilewebp, hongtoushanmobile]],
  hengshan: [[hengshanwebp, hengshan], [hengshanmobilewebp, hengshanmobile]],
  tianshangshan: [[tianshangshanwebp, tianshangshan], [tianshangshanmobilewebp, tianshangshanmobile]],
  feifengshan: [[feifengshanwebp, feifengshan], [feifengshanmobilewebp, feifengshanmobile]],
  shoushan: [[shoushanwebp, shoushan], [shoushanmobilewebp, shoushanmobile]],
  huoshaoshan: [[huoshaoshanwebp, huoshaoshan], [huoshaoshanmobilewebp, huoshaoshanmobile]],
  taiwushan: [[taiwushanwebp, taiwushan], [taiwushanmobilewebp, taiwushanmobile]],
  yuntaishan: [[yuntaishanwebp, yuntaishan], [yuntaishanmobilewebp, yuntaishanmobile]],
  dawulunshan: [[dawulunshanwebp, dawulunshan], [dawulunshanmobilewebp, dawulunshanmobile]],
  hongdanshan: [[hongdanshanwebp, hongdanshan], [hongdanshanmobilewebp, hongdanshanmobile]],
  luohanshan: [[luohanshanwebp, luohanshan], [luohanshanmobilewebp, luohanshanmobile]],
  xiangshan: [[xiangshanwebp, xiangshan], [xiangshanmobilewebp, xiangshanmobile]],
  luoshan: [[luoshanwebp, luoshan], [luoshanmobilewebp, luoshanmobile]],
  kuibishan: [[kuibishanwebp, kuibishan], [kuibishanmobilewebp, kuibishanmobile]],
}

export const icons = {
  "常": Normal,
  "保": Protect,
  "亞": Special,
  "特": Unique,
}

export const plants = {
  yushan: [yushanplantwebp, yushanplant],
  xueshan: [xueshanplantwebp, xueshanplant],
  xiuguluanshan: [xiuguluanshanplantwebp, xiuguluanshanplant],
  xiangyangshan: [xiangyangshanplantwebp, xiangyangshanplant],
  dabajianshan: [dabajianshanplantwebp, dabajianshanplant],
  taoshan: [taoshanplantwebp, taoshanplant],
  huoshishan: [huoshishanplantwebp, huoshishanplant],
  lingmingshan: [lingmingshanplantwebp, lingmingshanplant],
  lushan: [lushanplantwebp, lushanplant],
  guangtoushan: [guangtoushanplantwebp, guangtoushanplant],
  dajianshan: [dajianshanplantwebp, dajianshanplant],
  shaolaishan: [shaolaishanplantwebp, shaolaishanplant],
  jialishan: [jialishanplantwebp, jialishanplant],
  alishan: [alishanplantwebp, alishanplant],
  yuanzuishan: [yuanzuishanplantwebp, yuanzuishanplant],
  lalashan: [lalashanplantwebp, lalashanplant],
  taipingshan: [taipingshanplantwebp, taipingshanplant],
  fenqihudadongshan: [fenqihudadongshanplantwebp, fenqihudadongshanplant],
  taimalishan: [taimalishanplantwebp, taimalishanplant],
  yunjiadajianshan: [yunjiadajianshanplantwebp, yunjiadajianshanplant],
  dongyanshan: [dongyanshanplantwebp, dongyanshanplant],
  doulanshan: [doulanshanplantwebp, doulanshanplant],
  qixingshan: [qixingshanplantwebp, qixingshanplant],
  zhuzijianshan: [zhuzijianshanplantwebp, zhuzijianshanplant],
  maolanshan: [maolanshanplantwebp, maolanshanplant],
  shengmudengshan: [shengmudengshanplantwebp, shengmudengshanplant],
  liushishishan: [liushishishanplantwebp, liushishishanplant],
  chuyinshan: [chuyinshanplantwebp, chuyinshanplant],
  kantoushan: [kantoushanplantwebp, kantoushanplant],
  zhongniuhushan: [zhongniuhushanplantwebp, zhongniuhushanplant],
  quezishan: [quezishanplantwebp, quezishanplant],
  lidingshan: [lidingshanplantwebp, lidingshanplant],
  guanyinshan: [guanyinshanplantwebp, guanyinshanplant],
  yuemeishan: [yuemeishanplantwebp, yuemeishanplant],
  huoyanshan: [huoyanshanplantwebp, huoyanshanplant],
  liyushan: [liyushanplantwebp, liyushanplant],
  jilongshan: [jilongshanplantwebp, jilongshanplant],
  wuerchahushan: [wuerchahushanplantwebp, wuerchahushanplant],
  hongtoushan: [hongtoushanplantwebp, hongtoushanplant],
  hengshan: [hengshanplantwebp, hengshanplant],
  tianshangshan: [tianshangshanplantwebp, tianshangshanplant],
  feifengshan: [feifengshanplantwebp, feifengshanplant],
  shoushan: [shoushanplantwebp, shoushanplant],
  huoshaoshan: [huoshaoshanplantwebp, huoshaoshanplant],
  taiwushan: [taiwushanplantwebp, taiwushanplant],
  yuntaishan: [yuntaishanplantwebp, yuntaishanplant],
  dawulunshan: [dawulunshanplantwebp, dawulunshanplant],
  hongdanshan: [hongdanshanplantwebp, hongdanshanplant],
  luohanshan: [luohanshanplantwebp, luohanshanplant],
  xiangshan: [xiangshanplantwebp, xiangshanplant],
  luoshan: [luoshanplantwebp, luoshanplant],
  kuibishan: [kuibishanplantwebp, kuibishanplant],
}

export const packages = {
  yushan: [yushanpackagewebp, yushanpackage],
  xueshan: [xueshanpackagewebp, xueshanpackage],
  xiuguluanshan: [xiuguluanshanpackagewebp, xiuguluanshanpackage],
  xiangyangshan: [xiangyangshanpackagewebp, xiangyangshanpackage],
  dabajianshan: [dabajianshanpackagewebp, dabajianshanpackage],
  taoshan: [taoshanpackagewebp, taoshanpackage],
  huoshishan: [huoshishanpackagewebp, huoshishanpackage],
  lingmingshan: [lingmingshanpackagewebp, lingmingshanpackage],
  lushan: [lushanpackagewebp, lushanpackage],
  guangtoushan: [guangtoushanpackagewebp, guangtoushanpackage],
  dajianshan: [dajianshanpackagewebp, dajianshanpackage],
  shaolaishan: [shaolaishanpackagewebp, shaolaishanpackage],
  jialishan: [jialishanpackagewebp, jialishanpackage],
  alishan: [alishanpackagewebp, alishanpackage],
  yuanzuishan: [yuanzuishanpackagewebp, yuanzuishanpackage],
  lalashan: [lalashanpackagewebp, lalashanpackage],
  taipingshan: [taipingshanpackagewebp, taipingshanpackage],
  fenqihudadongshan: [fenqihudadongshanpackagewebp, fenqihudadongshanpackage],
  taimalishan: [taimalishanpackagewebp, taimalishanpackage],
  yunjiadajianshan: [yunjiadajianshanpackagewebp, yunjiadajianshanpackage],
  dongyanshan: [dongyanshanpackagewebp, dongyanshanpackage],
  doulanshan: [doulanshanpackagewebp, doulanshanpackage],
  qixingshan: [qixingshanpackagewebp, qixingshanpackage],
  zhuzijianshan: [zhuzijianshanpackagewebp, zhuzijianshanpackage],
  maolanshan: [maolanshanpackagewebp, maolanshanpackage],
  shengmudengshan: [shengmudengshanpackagewebp, shengmudengshanpackage],
  liushishishan: [liushishishanpackagewebp, liushishishanpackage],
  chuyinshan: [chuyinshanpackagewebp, chuyinshanpackage],
  kantoushan: [kantoushanpackagewebp, kantoushanpackage],
  zhongniuhushan: [zhongniuhushanpackagewebp, zhongniuhushanpackage],
  quezishan: [quezishanpackagewebp, quezishanpackage],
  lidingshan: [lidingshanpackagewebp, lidingshanpackage],
  guanyinshan: [guanyinshanpackagewebp, guanyinshanpackage],
  yuemeishan: [yuemeishanpackagewebp, yuemeishanpackage],
  huoyanshan: [huoyanshanpackagewebp, huoyanshanpackage],
  liyushan: [liyushanpackagewebp, liyushanpackage],
  jilongshan: [jilongshanpackagewebp, jilongshanpackage],
  wuerchahushan: [wuerchahushanpackagewebp, wuerchahushanpackage],
  hongtoushan: [hongtoushanpackagewebp, hongtoushanpackage],
  hengshan: [hengshanpackagewebp, hengshanpackage],
  tianshangshan: [tianshangshanpackagewebp, tianshangshanpackage],
  feifengshan: [feifengshanpackagewebp, feifengshanpackage],
  shoushan: [shoushanpackagewebp, shoushanpackage],
  huoshaoshan: [huoshaoshanpackagewebp, huoshaoshanpackage],
  taiwushan: [taiwushanpackagewebp, taiwushanpackage],
  yuntaishan: [yuntaishanpackagewebp, yuntaishanpackage],
  dawulunshan: [dawulunshanpackagewebp, dawulunshanpackage],
  hongdanshan: [hongdanshanpackagewebp, hongdanshanpackage],
  luohanshan: [luohanshanpackagewebp, luohanshanpackage],
  xiangshan: [xiangshanpackagewebp, xiangshanpackage],
  luoshan: [luoshanpackagewebp, luoshanpackage],
  kuibishan: [kuibishanpackagewebp, kuibishanpackage],
}

export const animalImgs = {
  "高山白腹鼠": [bluemammalwebp, bluemammal],
  "岩鷚": [bluebirdwebp, bluebird],
  "雪山草蜥": [bluereptileswebp, bluereptiles],
  "楚南氏山椒魚": [[blueamphibian5webp, blueamphibian5], [blueamphibian6webp, blueamphibian6]],
  "永澤蛇眼蝶": [[blueinsect5webp, blueinsect5], [blueinsect6webp, blueinsect6]],
  "長鬃山羊": [pinkmammalwebp, pinkmammal],
  "帝雉": [pinkbirdwebp, pinkbird],
  "菊池氏龜殼花": [pinkreptileswebp, pinkreptiles],
  "阿里山山椒魚": [[pinkamphibian5webp, pinkamphibian5], [pinkamphibian6webp, pinkamphibian6]],
  "姬長尾水青蛾": [[pinkinsect5webp, pinkinsect5], [pinkinsect6webp, pinkinsect6]],
  "臺灣黑熊": [orangemammalwebp, orangemammal],
  "冠羽畫眉": [orangebirdwebp, orangebird],
  "呂氏攀蜥": [orangereptileswebp, orangereptiles],
  "莫氏樹蛙": [[orangeamphibian5webp, orangeamphibian5], [orangeamphibian6webp, orangeamphibian6]],
  "臺灣長臂金龜": [[orangeinsect5webp, orangeinsect5], [orangeinsect6webp, orangeinsect6]],
  "白鼻心": [greenmammalwebp, greenmammal],
  "繡眼畫眉": [greenbirdwebp, greenbird],
  "百步蛇": [greenreptileswebp, greenreptiles],
  "面天樹蛙": [[greenamphibian5webp, greenamphibian5], [greenamphibian6webp, greenamphibian6]],
  "大紫蛺蝶": [[greeninsect5webp, greeninsect5],[greeninsect6webp, greeninsect6]],
  "穿山甲": [coffeemammalwebp, coffeemammal],
  "臺灣藍鵲": [coffeebirdwebp, coffeebird],
  "黑眉錦蛇": [coffeereptileswebp, coffeereptiles],
  "黑眶蟾蜍": [[coffeeamphibian5webp, coffeeamphibian5], [coffeeamphibian6webp, coffeeamphibian6]],
  "臺灣黑金龜": [[coffeeinsect5webp, coffeeinsect5], [coffeeinsect6webp, coffeeinsect6]],
}

export const pictures = {
  yushan1: [yushan1webp, yushan1],
  xueshan1: [xueshan1webp, xueshan1],
  huoshishan1: [huoshishan1webp, huoshishan1],
  jialishan1: [jialishan1webp, jialishan1],
  taipingshan1: [taipingshan1webp, taipingshan1],
  fenqihudadongshan1: [fenqihudadongshan1webp, fenqihudadongshan1],
  dongyanshan1: [dongyanshan1webp, dongyanshan1],
  doulanshan1: [doulanshan1webp, doulanshan1],
  shengmudengshan1: [shengmudengshan1webp, shengmudengshan1],
  liushishishan1: [liushishishan1webp, liushishishan1],
  guanyinshan1: [guanyinshan1webp, guanyinshan1],
  guanyinshan2: [guanyinshan2webp, guanyinshan2],
  guanyinshan3: [guanyinshan3webp, guanyinshan3],
  liyushan1: [liyushan1webp, liyushan1],
  wuerchahushan1: [wuerchahushan1webp, wuerchahushan1],
  hengshan1: [hengshan1webp, hengshan1],
  shoushan1: [shoushan1webp, shoushan1],
  yuntaishan1: [yuntaishan1webp, yuntaishan1],
  dawulunshan1: [dawulunshan1webp, dawulunshan1],
  xiangshan1: [xiangshan1webp, xiangshan1],
  yushan2: [yushan2webp, yushan2],
  xueshan2: [xueshan2webp, xueshan2],
  huoshishan2: [huoshishan2webp, huoshishan2],
  jialishan2: [jialishan2webp, jialishan2],
  taipingshan2: [taipingshan2webp, taipingshan2],
  dongyanshan2: [dongyanshan2webp, dongyanshan2],
  doulanshan2: [doulanshan2webp, doulanshan2],
  shengmudengshan2: [shengmudengshan2webp, shengmudengshan2],
  liushishishan2: [liushishishan2webp, liushishishan2],
  liyushan2: [liyushan2webp, liyushan2],
  wuerchahushan2: [wuerchahushan2webp, wuerchahushan2],
  hengshan2: [hengshan2webp, hengshan2],
  shoushan2: [shoushan2webp, shoushan2],
  yuntaishan2: [yuntaishan2webp, yuntaishan2],
  dawulunshan2: [dawulunshan2webp, dawulunshan2],
  xiangshan2: [xiangshan2webp, xiangshan2],
  yushan3: [yushan3webp, yushan3],
  xueshan3: [xueshan3webp, xueshan3],
  huoshishan3: [huoshishan3webp, huoshishan3],
  jialishan3: [jialishan3webp, jialishan3],
  dongyanshan3: [dongyanshan3webp, dongyanshan3],
  doulanshan3: [doulanshan3webp, doulanshan3],
  shengmudengshan3: [shengmudengshan3webp, shengmudengshan3],
  liushishishan3: [liushishishan3webp, liushishishan3],
  wuerchahushan3: [wuerchahushan3webp, wuerchahushan3],
  shoushan3: [shoushan3webp, shoushan3],
  yuntaishan3: [yuntaishan3webp, yuntaishan3],
  dawulunshan3: [dawulunshan3webp, dawulunshan3],
  xiangshan3: [xiangshan3webp, xiangshan3],
  yushan4: [yushan4webp, yushan4],
  xueshan4: [xueshan4webp, xueshan4],
  jialishan4: [jialishan4webp, jialishan4],
  dongyanshan4: [dongyanshan4webp, dongyanshan4],
  doulanshan4: [doulanshan4webp, doulanshan4],
  shengmudengshan4: [shengmudengshan4webp, shengmudengshan4],
  liushishishan4: [liushishishan4webp, liushishishan4],
  wuerchahushan4: [wuerchahushan4webp, wuerchahushan4],
  shoushan4: [shoushan4webp, shoushan4],
  yuntaishan4: [yuntaishan4webp, yuntaishan4],
  dawulunshan4: [dawulunshan4webp, dawulunshan4],
  yushan5: [yushan5webp, yushan5],
  xueshan5: [xueshan5webp, xueshan5],
  doulanshan5: [doulanshan5webp, doulanshan5],
  shengmudengshan5: [shengmudengshan5webp, shengmudengshan5],
  liushishishan5: [liushishishan5webp, liushishishan5],
  wuerchahushan5: [wuerchahushan5webp, wuerchahushan5],
  yuntaishan5: [yuntaishan5webp, yuntaishan5],
  xueshan6: [xueshan6webp, xueshan6],
  doulanshan6: [doulanshan6webp, doulanshan6],
  shengmudengshan6: [shengmudengshan6webp, shengmudengshan6],
  yushan6: [yushan6webp, yushan6],
  yuntaishan6: [yuntaishan6webp, yuntaishan6],
  yushan7: [yushan7webp, yushan7],
  xueshan7: [xueshan7webp, xueshan7],
  yushan8: [yushan8webp, yushan8],
  xueshan8: [xueshan8webp, xueshan8],
  xueshan9: [xueshan9webp, xueshan9],
  xueshan10: [xueshan10webp, xueshan10],
  xiuguluanshan1: [xiuguluanshan1webp, xiuguluanshan1],
  xiangyangshan1: [xiangyangshan1webp, xiangyangshan1],
  taoshan1: [taoshan1webp, taoshan1],
  lingmingshan1: [lingmingshan1webp, lingmingshan1],
  shaolaishan1: [shaolaishan1webp, shaolaishan1],
  alishan1: [alishan1webp, alishan1],
  taimalishan1: [taimalishan1webp, taimalishan1],
  yunjiadajianshan1: [yunjiadajianshan1webp, yunjiadajianshan1],
  qixingshan1: [qixingshan1webp, qixingshan1],
  kantoushan1: [kantoushan1webp, kantoushan1],
  quezishan1: [quezishan1webp, quezishan1],
  lidingshan1: [lidingshan1webp, lidingshan1],
  hongtoushan1: [hongtoushan1webp, hongtoushan1],
  tianshangshan1: [tianshangshan1webp, tianshangshan1],
  feifengshan1: [feifengshan1webp, feifengshan1],
  taiwushan1: [taiwushan1webp, taiwushan1],
  xiuguluanshan2: [xiuguluanshan2webp, xiuguluanshan2],
  xiangyangshan2: [xiangyangshan2webp, xiangyangshan2],
  taoshan2: [taoshan2webp, taoshan2],
  lingmingshan2: [lingmingshan2webp, lingmingshan2],
  shaolaishan2: [shaolaishan2webp, shaolaishan2],
  alishan2: [alishan2webp, alishan2],
  taimalishan2: [taimalishan2webp, taimalishan2],
  yunjiadajianshan2: [yunjiadajianshan2webp, yunjiadajianshan2],
  qixingshan2: [qixingshan2webp, qixingshan2],
  kantoushan2: [kantoushan2webp, kantoushan2],
  quezishan2: [quezishan2webp, quezishan2],
  lidingshan2: [lidingshan2webp, lidingshan2],
  hongtoushan2: [hongtoushan2webp, hongtoushan2],
  tianshangshan2: [tianshangshan2webp, tianshangshan2],
  feifengshan2: [feifengshan2webp, feifengshan2],
  taiwushan2: [taiwushan2webp, taiwushan2],
  xiuguluanshan3: [xiuguluanshan3webp, xiuguluanshan3],
  xiangyangshan3: [xiangyangshan3webp, xiangyangshan3],
  taoshan3: [taoshan3webp, taoshan3],
  lingmingshan3: [lingmingshan3webp, lingmingshan3],
  shaolaishan3: [shaolaishan3webp, shaolaishan3],
  alishan3: [alishan3webp, alishan3],
  taimalishan3: [taimalishan3webp, taimalishan3],
  yunjiadajianshan3: [yunjiadajianshan3webp, yunjiadajianshan3],
  qixingshan3: [qixingshan3webp, qixingshan3],
  kantoushan3: [kantoushan3webp, kantoushan3],
  quezishan3: [quezishan3webp, quezishan3],
  lidingshan3: [lidingshan3webp, lidingshan3],
  hongtoushan3: [hongtoushan3webp, hongtoushan3],
  tianshangshan3: [tianshangshan3webp, tianshangshan3],
  feifengshan3: [feifengshan3webp, feifengshan3],
  taiwushan3: [taiwushan3webp, taiwushan3],
  xiuguluanshan4: [xiuguluanshan4webp, xiuguluanshan4],
  xiangyangshan4: [xiangyangshan4webp, xiangyangshan4],
  taoshan4: [taoshan4webp, taoshan4],
  lingmingshan4: [lingmingshan4webp, lingmingshan4],
  alishan4: [alishan4webp, alishan4],
  taimalishan4: [taimalishan4webp, taimalishan4],
  yunjiadajianshan4: [yunjiadajianshan4webp, yunjiadajianshan4],
  qixingshan4: [qixingshan4webp, qixingshan4],
  lidingshan4: [lidingshan4webp, lidingshan4],
  hongtoushan4: [hongtoushan4webp, hongtoushan4],
  feifengshan4: [feifengshan4webp, feifengshan4],
  taiwushan4: [taiwushan4webp, taiwushan4],
  xiuguluanshan5: [xiuguluanshan5webp, xiuguluanshan5],
  xiangyangshan5: [xiangyangshan5webp, xiangyangshan5],
  taoshan5: [taoshan5webp, taoshan5],
  lingmingshan5: [lingmingshan5webp, lingmingshan5],
  alishan5: [alishan5webp, alishan5],
  taimalishan5: [taimalishan5webp, taimalishan5],
  qixingshan5: [qixingshan5webp, qixingshan5],
  taiwushan5: [taiwushan5webp, taiwushan5],
  xiuguluanshan6: [xiuguluanshan6webp, xiuguluanshan6],
  lingmingshan6: [lingmingshan6webp, lingmingshan6],
  taimalishan6: [taimalishan6webp, taimalishan6],
  qixingshan6: [qixingshan6webp, qixingshan6],
  xiuguluanshan7: [xiuguluanshan7webp, xiuguluanshan7],
  lingmingshan7: [lingmingshan7webp, lingmingshan7],
  dabajianshan1: [dabajianshan1webp, dabajianshan1],
  lushan1: [lushan1webp, lushan1],
  guangtoushan1: [guangtoushan1webp, guangtoushan1],
  dajianshan1: [dajianshan1webp, dajianshan1],
  yuanzuishan1: [yuanzuishan1webp, yuanzuishan1],
  lalashan1: [lalashan1webp, lalashan1],
  zhuzijianshan1: [zhuzijianshan1webp, zhuzijianshan1],
  maolanshan1: [maolanshan1webp, maolanshan1],
  zhongniuhushan1: [zhongniuhushan1webp, zhongniuhushan1],
  yuemeishan1: [yuemeishan1webp, yuemeishan1],
  huoyanshan1: [huoyanshan1webp, huoyanshan1],
  jilongshan1: [jilongshan1webp, jilongshan1],
  hongdanshan1: [hongdanshan1webp, hongdanshan1],
  luoshan1: [luoshan1webp, luoshan1],
  kuibishan1: [kuibishan1webp, kuibishan1],
  dabajianshan2: [dabajianshan2webp, dabajianshan2],
  lushan2: [lushan2webp, lushan2],
  guangtoushan2: [guangtoushan2webp, guangtoushan2],
  dajianshan2: [dajianshan2webp, dajianshan2],
  yuanzuishan2: [yuanzuishan2webp, yuanzuishan2],
  lalashan2: [lalashan2webp, lalashan2],
  zhongniuhushan2: [zhongniuhushan2webp, zhongniuhushan2],
  yuemeishan2: [yuemeishan2webp, yuemeishan2],
  huoyanshan2: [huoyanshan2webp, huoyanshan2],
  jilongshan2: [jilongshan2webp, jilongshan2],
  hongdanshan2: [hongdanshan2webp, hongdanshan2],
  luoshan2: [luoshan2webp, luoshan2],
  kuibishan2: [kuibishan2webp, kuibishan2],
  dabajianshan3: [dabajianshan3webp, dabajianshan3],
  lushan3: [lushan3webp, lushan3],
  dajianshan3: [dajianshan3webp, dajianshan3],
  yuanzuishan3: [yuanzuishan3webp, yuanzuishan3],
  lalashan3: [lalashan3webp, lalashan3],
  huoyanshan3: [huoyanshan3webp, huoyanshan3],
  jilongshan3: [jilongshan3webp, jilongshan3],
  luoshan3: [luoshan3webp, luoshan3],
  kuibishan3: [kuibishan3webp, kuibishan3],
  dabajianshan4: [dabajianshan4webp, dabajianshan4],
  lushan4: [lushan4webp, lushan4],
  dajianshan4: [dajianshan4webp, dajianshan4],
  yuanzuishan4: [yuanzuishan4webp, yuanzuishan4],
  lalashan4: [lalashan4webp, lalashan4],
  huoyanshan4: [huoyanshan4webp, huoyanshan4],
  kuibishan4: [kuibishan4webp, kuibishan4],
  dajianshan5: [dajianshan5webp, dajianshan5],
  dajianshan6: [dajianshan6webp, dajianshan6],
}

export default {}
