import React from 'react';
import Modal from 'react-modal';
import merge from 'lodash/merge'
import { IoIosClose } from 'react-icons/io';

import Box from './Box'
import Button from './Button';
import theme, { responsive } from './ThemeProvider/theme'

Modal.setAppElement('#root')

const defaultStyles = {
  overlay: {
    zIndex: theme.zOrder[3],
    backgroundColor: 'rgba(0,0,0,0.7)'
  },
  content: {
    width: '100%',
    // maxWidth: '40em',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    // marginRight: '-50%',
    // transform: 'translate(-50%, -50%)',
    // borderRadius: '1em',
    // border: '5px solid black',
    padding: '0',
    backgroundColor: 'white',
    overflow: 'scroll',
  }
};

export default ({
  children,
  border,
  borderRadius,
  customStyles,
  py,
  isMobile,
  closeIcon,
  onRequestClose,
  wrapperProps = { pb: '1em' },
  ...props
}) => (
  <Modal
    style={merge({}, defaultStyles, { content: customStyles })}
    onRequestClose={onRequestClose}
    {...props}
  >
    {closeIcon &&  (
      <Box position="fixed" right={responsive('0.5em', '1em')} top={responsive('0.5em', '1em')} zIndex={99}>
        <Button.Transparent
          display="inline-block"
          lineHeight="0"
          px="0"
          py="0"
          onClick={onRequestClose}
        >
          <IoIosClose size={isMobile ? '1.5em' : '2.5em'} />
        </Button.Transparent>
      </Box>
    )}
    <Box {...wrapperProps}>
      {children}
    </Box>
  </Modal>
)
