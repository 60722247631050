import React, { useState, useMemo } from 'react'
import { BsFillCaretDownFill } from 'react-icons/bs';
import { useFormikContext } from 'formik'
import OutsideClickHandler from 'react-outside-click-handler';

import RotateIcon from './RotateIcon'

import useFilterValue from '../useFilterValue'

import Box from '../../components/Box';
import Flex from '../../components/Flex';
import Dropdown from '../../components/Dropdown';
import Input from '../../components/Input';
import Button from '../../components/Button';
import { responsive } from '../../components/ThemeProvider/theme';

const options = [
  {
    label: '山排序',
    value: 'all',
  },
  {
    label: '北到南',
    value: 'north:reverse',
  },
  {
    label: '南到北',
    value: 'north',
  },
  {
    label: '高到低',
    value: 'height:reverse',
  },
  {
    label: '低到高',
    value: 'height',
  }
]

export const filterContent = [
  {
    title: '所在地區',
    name: 'location',
    checkboxs: [
      {
        rightLabel: '北北基',
      },
      {
        rightLabel: '桃竹苗',
      },
      {
        rightLabel: '中彰投',
      },
      {
        rightLabel: '雲嘉南',
      },
      {
        rightLabel: '宜花東',
      },
      {
        rightLabel: '高屏　',
      },
      {
        rightLabel: '澎金馬',
      },
    ]
  },
  {
    title: '行程天數',
    name: 'journal',
    checkboxs: [
      {
        rightLabel: '當天來回',
        mr: '1.625em',
      },
      {
        rightLabel: '兩天一夜',
        mr: '1.625em',
      },
      {
        rightLabel: '三天以上',
      },
    ]
  },
  {
    title: '百岳/非百岳',
    name: 'notHunMt',
    checkboxs: [
      {
        rightLabel: '百岳',
        mr: '1.625em',
      },
      {
        rightLabel: '非百岳',
        mr: '1.625em',
      },
    ]
  }
]

const FilterControl = ({ rotate, allRotate }) => {
  const [open, setOpen] = useState()
  const { values, setFieldValue } = useFormikContext()
  const { isFilter } = useFilterValue()
  return (
    <Flex
      flex={1}
      alignItems="center"
      mx={responsive('0em', '4em')}
      pt="0.5em"
    >
      <Box position="relative" bg="transparent">
        <OutsideClickHandler
          disabled={!Boolean(open)}
          onOutsideClick={() => setOpen(false)}
          display="flex"
        >
          <Dropdown.Field
            display="inline-block"
            width="6em"
            name="sort"
            placeholder="山排序"
            options={options}
            isSearchable={false}
          />
          <Box ml="0.5em" position="relative">
            <Button
              bg={isFilter ? 'cyan' : 'white'}
              hoverBg="white"
              border="1px solid"
              borderColor="gray"
              hoverBorder="gray"
              height="100%"
              py="0"
              px="0"
              width="5em"
              color={isFilter ? 'white' : open ? 'cyan' : 'darkGray'}
              hoverColor="black"
              fontWeight="normal"
              rightIcon={BsFillCaretDownFill}
              onClick={() => setOpen(!open)}
            >
              篩選
            </Button>
            {open && (
              <Box
                position="absolute"
                width={responsive('250%', '375px')}
                top="100%"
                left={responsive('-50%', '0')}
                px="1em"
                border="1px solid"
                borderColor="gray"
                borderRadius="0.25em"
                bg="white"
              >
                {filterContent.map(({ title, name, checkboxs }, k) => (
                  <Box key={k} mt="0.75em">
                    <Flex justifyContent="space-between">
                      <Box>{title}</Box>
                      <Input
                        onChange={(e) => {
                          setFieldValue(name, e.target.checked ? checkboxs.map((l, i) => String(i)) : [])
                        }}
                        type="checkbox"
                        rightLabel="全選"
                        checked={values[name].length === checkboxs.length}
                      />
                    </Flex>
                    <Flex
                      mt="1em"
                      pb="0.5em"
                      flexWrap="wrap"
                      borderBottom="1px solid"
                      borderColor="lightGray"
                    >
                      {checkboxs.map(({ rightLabel, ...props }, index) => (
                        <Input.Field
                          width={responsive(k ? 1 : 1 / 2, 1 / 4)}
                          justifyContent={!Boolean(k) && responsive(index % 2 && 'flex-end', 'flex-start')}
                          name={name}
                          type="checkbox"
                          rightLabel={rightLabel}
                          key={index}
                          value={String(index)}
                          {...props}
                        />
                      ))}
                    </Flex>
                  </Box>
                ))}
                <Button.Transparent
                  disabled={!isFilter}
                  onClick={() => {
                      setFieldValue('location', [])
                      setFieldValue('journal', [])
                      setFieldValue('notHunMt', [])
                    }}
                >
                  重新篩選
                </Button.Transparent>
              </Box>
            )}
          </Box>
        </OutsideClickHandler>
      </Box>
      <Box flex={1} textAlign="right">
        <Box.Inline transform={rotate && 'rotateY(180deg)'}>
          <Button.RotateButton
            py="0"
            color="darkGray"
            hoverColor="cyan"
            onClick={() => allRotate(!rotate)}
            rightIcon={RotateIcon}
            active={rotate}
          />
        </Box.Inline>
      </Box>
    </Flex>
  )
}

export default FilterControl
