import React, { useEffect, useState, useContext } from 'react'
import styled from 'styled-components'
import { MdPlayArrow } from 'react-icons/md'
// import random from 'lodash/random'
import { Link } from 'react-router-dom';
import { IoIosLock } from 'react-icons/io';

import Box from '../../components/Box'
import Button from '../../components/Button'
import Modal from '../../components/Modal'
import BackgroundImage from '../../components/BackgroundImage'
// import Image from '../../components/Image'
import { responsive } from '../../components/ThemeProvider/theme';

import useResponsive from '../../contexts/mediaQuery/useResponsive'

import { front, back } from '../photo'
import Topics from '../Topics'

import rotateContext from './rotateContext'

const StyledBox = styled(Box)`
  display: inline-block;
  perspective: 1000px;
  .card {
    position: relative;
    transition-duration: 0.6s;
    transition-timing-function: ease-in-out;
    transform-style: preserve-3d;
  }
`
// const bgColor = ['bgGreen', 'bgPink', 'bgOrange', 'gray', 'bgBlue']

const Card = ({ name, children, src, isLock, ...props }) => {
  const [state, setstate] = useState(isLock)
  const [transition, setTransition] = useState()
  const [open, setOpenCard] = useState()
  const rotate = useContext(rotateContext)
  useEffect(() => {
    setstate(isLock || rotate)
  }, [isLock, rotate])
  useEffect(() => {
    if (state) {
      setTimeout(() => setTransition(state), 200)
    } else setTimeout(() => setTransition(state), 300)
  }, [state])
  // console.log(state, rotate)
  const { isMobile } = useResponsive()
  return (
    <StyledBox {...props}>
      <Box
        className="card"
        transform={state && 'rotateY(180deg)'}
      >
        <BackgroundImage
          onClick={() => setstate(!state)}
          pointerEvents={state && 'none'}
          src={front[src]}
          zIndex={3}
          ratio={1}
          transition="all 0.3s"
          transform={transition ? 'scale(0.99) translateZ(1px)' : 'translateZ(1px)'}
          borderRadius="1rem"
        />
        <Box
          position="absolute"
          top="0"
          left="0"
          width="100%"
          height="100%"
          transform="rotateY(180deg)"
          pointerEvents={isLock && 'none'}
          onClick={() => setstate(!state)}
        >
          <BackgroundImage
            src={back[src]}
            borderRadius="1em"
            bg="white"
            overflow="hidden"
            transform={!transition && 'scale(0.98)'}
          />
          {isLock ? (
            <Box
              position="absolute"
              left="0"
              right="0"
              top="0"
              bottom="0"
              bg="white"
              opacity="0.5"
              borderRadius="1em"
            >
              <Box position="absolute" left="50%" top="50%" transform="translate(-50%, -50%)">
                <IoIosLock size="2.5em" />
              </Box>
            </Box>
          ) : transition && (
            <Box
              position="absolute"
              left="50%"
              bottom="1em"
              width={responsive('90%', '80%')}
              transform="translateX(-50%)"
              textAlign="center"
            >
              <Button.Transparent
                as={Link}
                rightIcon={MdPlayArrow}
                px={responsive('0.25em', '1em')}
                fontSize="1em"
                py="0.25em"
                color="white"
                hoverColor="white"
                bg="rgb(211, 211, 211, 0.5)"
                hoverBg="rgb(211, 211, 211, 0.5)"
                borderRadius="2em"
                fontWeight="bold"
                to={!isMobile && `/topics/${src}` }
                target={!isMobile && '_blank'}
                onClick={(e) => {
                  e.stopPropagation()
                  setOpenCard(src)
                }}
                title={name}
              >進入這座山
              </Button.Transparent>
            </Box>
          )}
        </Box>
      </Box>
      {isMobile &&
        <Modal closeIcon isOpen={open} onRequestClose={() => setOpenCard(false)}>
          <Topics closeModal={() => setOpenCard(false)} mountainName={src} />
        </Modal>
      }
    </StyledBox>
  )
}

export default Card;
