import React from 'react'
// import { Link } from 'react-router-dom';

import Box from '../../components/Box';
import Link from '../../components/Link';
import Text from '../../components/Text';

const training = [
  {
    title: '跑步：',
    text: '對身體造成的壓力較大，是較有效的訓練方式。主要鍛鍊到的是小腿肌肉，也能訓練到下坡時肌肉離心收縮狀況下受力的動作。但除非增加上坡的路段，否則不太能鍛鍊到上坡所需要的大腿股四頭肌。'
  },
  {
    title: '騎車：',
    text: '由於騎車是坐著進行的運動，相對對身體負擔較小，如果沒有增加上坡路段訓練強度可能太弱。能鍛鍊到股四頭肌，但無法鍛鍊到下坡需要的肌肉離心收縮受力的情況。\n基本上，只要你有持續的在從事任何運動，對基本體能一定都是有幫助的，差別只在與登山的關聯性的高低。跑步應該是最多人會用來當做上山前的體能訓練，但事實上，跑步與登山的關聯性並沒完全相同。我常遇到隊員是有經常在跑馬拉松的習慣，但是負重加上高海拔的低氧環境，在上坡路段時仍然會感覺到滿滿的無力感，便是因為大腿股四頭肌缺乏鍛鍊。'
  },
  {
    title: '而你有爬完山鐵腿的經驗嗎？',
    text: '那主要是因為下坡所造成的，我們平常很少會做這種肌肉離心收縮又受力的動作，因此造成下山後腳部的延遲性痠痛。'
  },
]

const PartII = () => {
  return (
    <Box>
      <Text.Article>
        上篇提及到登山訓練可分為柔軟度訓練、心肺耐力、肌力訓練等三個面向，從<Link.Underline color="black" to="/training/1">柔軟度開始</Link.Underline>介紹，接續則是關於心肺耐力等訓練。
      </Text.Article>
      <Text.Article>【心肺耐力訓練】</Text.Article>
      <Text.Article>關於心肺耐力的訓練，一般人最常聯想到的大概就是跑步與騎單車。</Text.Article>
      <Box>
        {training.map(({ title, text }, i) => (
          <Box my="2em" key={i}>
            <Text fontWeight="bold">{title}</Text>
            <Text whiteSpace="pre-wrap">{text}</Text>
          </Box>
        ))}
      </Box>
      <Text.Article>所以除了這兩項運動外，登階是最推薦也最接近登山運動的訓練手段。可同時鍛鍊到上下坡所需要的肌肉，特別是下樓梯的動作貼近實際登山情況，可以訓練到下山的肌肉，但缺點就是下樓時對膝蓋負擔較大。如果能加上負重進行更好，心肺與肌力都能同時訓練，缺點就是無聊，還要背著背包面對眾人的異樣眼光，如果找不到適合的環境，在家中找一個大約在小腿肚高、穩固的凳子，背著背包上下也是一種方法。上下一步大約20幾cm，因此只要上下個4000次就等於爬了一座一千公尺高的山了呢！是不是覺得輕鬆又方便啊！</Text.Article>
      <Text.Article>以登山為目標的心肺訓練有個重點，就是不一定非要喘到說不出話累個半死才叫做訓練。登山相對慢跑或騎車而言，行進時間更長但相對緩慢，稱得上是超耐久運動，因此把訓練的時間拉長強度降低，才是比較貼近一般狀況的訓練方式。</Text.Article>
      <Text.Article>至於訓練強度的高低，我們就於最後一個篇章與大家進行分享，敬請期待！</Text.Article>
    </Box>
  )
}

export default PartII
