import React from 'react'
import uniq from 'lodash/uniq'
import sortBy from 'lodash/sortBy'

import Desktop from './Desktop'
import Mobile from './Mobile'

import csvData from '../../data.csv'
import useCsv from '../../useCsv'

import Box from '../../../components/Box';
import useResponsive from '../../../contexts/mediaQuery/useResponsive'

const MountainList = ({ openList }) => {
  const { isMobile } = useResponsive()
  const { data } = useCsv(csvData)
  const region = uniq(data.map(({ location }) => location))
  const useData = sortBy(data, ({ isLock }) => isLock).reduce((res, { location, src, isLock, name }) => {
    res[location].push({ name, src, isLock})
    return res
  }, { 北北基: [], 澎金馬: [], 宜花東:[], 桃竹苗: [], 中彰投: [], 雲嘉南: [], 高屏: []})
  return (
    <Box>
      {isMobile ? (
        <Mobile region={region} useData={useData} openList={openList} />
      ) : (
        <Desktop region={region} useData={useData} openList={openList} />
      )}
    </Box>
  )
}

export default MountainList
