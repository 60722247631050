import React from 'react'

import Box from '../../components/Box';
import Text from '../../components/Text';

const orientations = [
  '柔軟度訓練',
  '心肺耐力訓練',
  '肌力訓練'
]

const PartI = () => {
  return (
    <Box>
      <Text.Article>登山相比其他競技運動來說，並不要求最好的爆發力、速度或耐力，基本上是不論運動神經好壞，只要會走路便能從事的活動。看看象山、陽明山路上提著礦泉水披著毛巾就上去的男女老少，便知道他的門檻有多低。</Text.Article>
      <Text.Article>
        也因此容易忽略登山其實是一種運動量超高的活動，除了需要背負著10幾公斤的重量走上 8-10小時崎嶇陡峭的路。隨著海拔的上升，氧氣量也跟著下降，比起在平地的運動需要花上更多的精力。一趟兩天一夜的雪山行程，運動量甚至會超過一場馬拉松！
      </Text.Article>
      <Text.Article>通常不會完全不做任何事前訓練就參加一場馬拉松吧？<br />但蠻常遇到只意思意思跑一兩次步就上山了！</Text.Article>
      <Text.Article>因為多數人對登山的印象就有如去公園散步一樣的休閒活動而已，而忽略了訓練的重要性，間接造成山難發生的機率。</Text.Article>
      <Text.Article>台灣的登山比較偏向國外所謂的「健行」，因此訓練強度不用像真正的「登山」那樣高。但這並不代表不用訓練，最簡單的訓練方法就是每週爬一次山！如果能維持每周都爬一座單日的郊山，基本上不用再特別做什麼額外的訓練，大概也足以應付高山、過夜的中級山或是多日縱走行程的體能。在山上常遇到穿著雨鞋下坡飛快的阿伯，與說著膝蓋不好再也不登山的年輕人之間的角色錯亂，就知道成效。</Text.Article>
      <Text.Article>但面對現實吧！你根本沒有時間週週去爬山。</Text.Article>
      <Text.Article>也因此才需要這些「訓練」來幫助自己能更輕鬆的享受戶外，達到在自然中身心靈全部得到解放與自由的感受(輕量化則是另一個重點)。</Text.Article>
      <Text>基本上在山下的訓練可分成三個面向：</Text>
      <Box as="ol" listStyleType="lower-alpha">
        {orientations.map((text, i) => (
          <Box as="li" key={i}>{text}</Box>
        ))}
      </Box>
      <Text.Article>本篇先介紹【柔軟度訓練】這個面向：</Text.Article>
      <Text.Article>柔軟度訓練就不需要多說了，大家常做的拉筋伸展、瑜珈、滾筒就屬於此項目，可以考慮每周加入 1-2次的訓練，我個人是會在早晨起來時做全身性的伸展運動，強度高的訓練後做滾筒運動，每幾周加入一個瑜珈訓練。只要找到自己習慣的運動方式就好，可能有些人會感覺柔軟度訓練並不是很重要，但它能加速恢復肌肉彈性、減緩痠痛、避免運動傷害發生等好處。</Text.Article>
      <Text.Article>關於心肺耐力及肌力訓練則待下篇分享！</Text.Article>
    </Box>
  )
}

export default PartI
