import { useMemo } from 'react'
import { useFormikContext } from 'formik'

const location = [
  '北北基',
  '桃竹苗',
  '中彰投',
  '雲嘉南',
  '宜花東',
  '高屏',
  '澎金馬',
]

const journal = [
  '當天來回',
  '兩天一夜',
  '三天以上',
]

const notHunMt = [
  0,
  1
]

export default () => {
  const { values } = useFormikContext()

  return useMemo(() => {
    const filterLocation = values.location ? values.location.reduce((all, v) => {
      all[location[+v]] = true
      return all
    }, {}) : {}
    const filterJournal = values.journal ? values.journal.reduce((all, v) => {
      all[journal[+v]] = true
      return all
    }, {}) : {}
    const filternotHunMt = values.notHunMt ? values.notHunMt.reduce((all, v) => {
      all[notHunMt[+v]] = true
      return all
    }, {}) : {}
    return {
      filter: {
        location: filterLocation,
        journal: filterJournal,
        notHunMt: filternotHunMt
      },
      isFilter: values.notHunMt.length > 0 || values.location.length > 0 || values.journal.length > 0,
      ...values
    }
  }, [values])
}
