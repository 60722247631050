import React from 'react'
import isArray from 'lodash/isArray'
import range from 'lodash/range'

import WhiteBox from './WhiteBox'

import Box from '../../components/Box'
import Flex from '../../components/Flex'
import Text from '../../components/Text'
import Circle from '../../components/Circle'
import Title from '../../components/TitleWithBg'
import Image from '../../components/Image'
import { responsive } from '../../components/ThemeProvider/theme';
import useResponsive from '../../contexts/mediaQuery/useResponsive'

const height = {
  blue: '3000m以上',
  pink: '2000-3000m',
  orange: '1000-2000m',
  green: '500-1000m',
  coffee: '0-500m',
}

const ModuleAnimal = ({
    animals,
    img,
    title,
    length,
    index,
    animalName,
    color,
    active,
    text,
    ...props
  }) => {
  const { isMobile } = useResponsive()
  const src = length > 2 ? img : img[Number(isMobile)]
  return (
    <WhiteBox fontWeight="bold" color={color} ml={(!isMobile && index) &&'0.75em'} p="0" mt="2em" {...props}>
      {img ? (
        <Box position="relative">
          <Image src={src} />
          <Box
            position="absolute"
            top={(isMobile || length > 2) ? '1em' : '25%'}
            width={(isMobile || length > 2) ? '100%' : '65%'}
            textAlign={(isMobile || length > 2) ? 'center' : 'left'}
            pl={(isMobile || length > 2) ? 0 : '2em'}
          >
            <Title bg={color}>{title}</Title>
            <Box
              mt="0.5em"
              position={(isMobile || length > 2) ? 'absolute' : 'relative'}
              transform={(isMobile || length > 2) && 'translateX(-40%)'}
              left={(isMobile || length > 2) && '45%'}
            >
              {animals.map(({ icon, name }, k) => (
                <Flex alignItems="center" m="0.25em" key={k}>
                  <Box mr="0.5em" fontSize="1.125em">● {name}</Box>
                  {icon && icon.map((Icon, i) => (
                    <Icon width="1.5em" key={i} />
                  ))}
                </Flex>
              ))}
            </Box>
          </Box>
          <Box
            position="absolute"
            left={(isMobile || length > 2) && '1em'}
            right={!(isMobile || length > 2) && '1em'}
            textAlign={(isMobile || length > 2) ? 'auto' : 'right'}
            bottom="0.5em"
          >
            <Title bg={color} fontSize="0.875em">{animalName}</Title>
            <Text>{height[color]}山代表</Text>
          </Box>
        </Box>
      ) : (
        <Box textAlign="center" p="1em">
          <Title bg="white" border="2px solid" color={color}>季節性活動</Title>
          <Flex flexWrap="wrap" mt="3em" mb="2.625em">
            {range(12).map((dot) => (
              <Box width={1 / 6} mt="1em" key={dot}>
                <Circle
                  bg={active.includes(dot + 1) ? color : 'white'}
                  color={active.includes(dot + 1) ? 'white' : color}
                  border="2px solid"
                  borderColor={color}
                  mx="0.25em"
                >{dot + 1}</Circle>
              </Box>
            ))}
          </Flex>
          <Text textAlign="left" whiteSpace="pre-wrap">{text}</Text>
        </Box>
      )}
    </WhiteBox>
  )
}

export default ModuleAnimal
