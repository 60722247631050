import React from 'react'

import Box from './Box'

const TitleWithBg = ({ bg, children, ...props }) => (
  <Box.Inline
    color="white"
    px="0.75rem"
    py="0.25rem"
    borderRadius="1em"
    bg={bg}
    fontSize="1.25em"
    fontWeight="900"
    {...props}
  >
    {children}
  </Box.Inline>
)

export default TitleWithBg
