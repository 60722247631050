import React from 'react'
// import { FaHeart } from 'react-icons/fa';

import Container from '../../components/Container'
import Box from '../../components/Box'
import Text from '../../components/Text'
import Flex from '../../components/Flex'
import Link from '../../components/Link'
import Image from '../../components/Image'
import title from '../Header/title.png'
import titlewebp from '../Header/title.webp'
import relab from './relab.webp'
import relabwebp from './relab.png'
import gamania from './gamania.webp'
import gamaniawebp from './gamania.png'
import hikingbook from './hikingbook.webp'
import hikingbookwebp from './hikingbook.png'

const purpose = [
  '提供一些靈感，下次大家描述山的時候可以試試看，讓每一座山的模樣更鮮明！',
  '如果哪座山勾起你的好奇，下次在床上爬完枕頭山後，就出門去走山吧！',
  '蒐集更多對山的愛與想像，每次走進山裡，我們都得到太多，所以也想知道，你得到什麼呢？'
]

const milestone = [
  {
    title: '2020/11 全數52座山解鎖，網站正式上線',
  },
  {
    title: '2020/10 全數52座山，解鎖其中35座山資訊',
  },
  {
    title: '2020/04 - 2020/11 橘子關懷基金會',
    content: ['2020 Summer School 夏日學園腦內冒險', '52座山資訊模板協作', '登山主題特輯內容提供']
  },
  {
    title: '2020/08 - 2020/11 Hikingbook',
    content: [' 52座山資訊模板實拍照片提供']
  },
]

const footerLogo = [
  { src: [relabwebp, relab], link: 'https://relab.cc/', width: '12em'},
  { src: [gamania, gamaniawebp], link: 'https://www.gamaniacheerup.org/', width: '12em'},
  { src: [hikingbook, hikingbookwebp], link: 'https://zh-tw.hikingbook.net/', width: '6em'},

]

const AboutPage = () => (
  <Container maxWidth="800px">
    <Text.H1 textAlign="center">山模山樣</Text.H1>
    <Text.H6 my="3.75rem" textAlign="center">「除了高度，你能夠用幾種方式來描述一座山的模樣呢？」</Text.H6>
    <Text.Article whiteSpace="pre-wrap">
      {'臺灣是世界上高山密度最高的國家，\n我們有百岳、還有其他大大小小的山，共超過250座，你爬過幾座呢？'}
    </Text.Article>
    <Text.Article>
      {'當我們運用想像，嘗試改變和山的互動關係，描述的可能性也愈來愈多！\n感謝許多人參與這個計畫，讓愈來愈多山的個性被看見、被呈現。'}
    </Text.Article>
    <Text.Article>這個小小的計畫，只有幾個小小的目的：</Text.Article>
    <Box as="ul" my="2em">
      {purpose.map((text, i) => (
        <li key={i}>{text}</li>
      ))}
    </Box>
    <Flex alignItems="flex-end">
      <Box>最開心的是，在今年和大家一起爬山</Box>
      <Box color="red">
        ❤
      </Box>
    </Flex>
    <Box mt="7.5em">
      <Text.H1 mb="3.75rem">山模山樣里程碑，邀你一探究竟</Text.H1>
      <Text.Article>山模山樣是有著許多人參與的計畫，邀請你與我們一起持續關注這個計畫，讓越來越多山的個性可被看見、被呈現。當然，也期待你能與朋友出門去走山，在路上共同討論這座山的樣貌！</Text.Article>
      <Text.H6 mb="2rem" textAlign="left">2020</Text.H6>
      <Box as="ul" mt="2em">
        {milestone.map(({ title, content }, i) => (
          <Box key={i} my="2em">
            <li>{title}</li>
            {content && content.map((text, k) => (
              <Text key={k}>{text}</Text>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
    <Box mt="7.5em" px="30%">
      <Image src={[titlewebp, title]} />
    </Box>
    <Flex mt="4.375em" alignItems="flex-end" justifyContent="center">
      {footerLogo.map(({src, link, ...props}, i) => (
        <Box as={Link} href={link} px="1.09375em" key={i} {...props}>
          <Image src={src} />
        </Box>
      ))}
    </Flex>
  </Container>
)

export default AboutPage
