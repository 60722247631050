import { useState, useEffect } from 'react'
import { csv } from 'd3-fetch'

const handleJournal = (data) => (data.split(','))

export default (url) => {
  const [loading, setLoading] = useState()
  const [data, setData] = useState([])
  useEffect(() => {
    setLoading(true)
    csv(url).then(d => {
      setData(d)
      setLoading(false)
    })
  }, [])

  return {
    data,
    loading,
  }
}
