import React from 'react'

import Box from '../../components/Box';
import Text from '../../components/Text';
import Image from '../../components/Image';
import Container from '../../components/Container';

import image1 from './image1.png'
import image2 from './image2.png'
import image3 from './image3.png'

const principle = [
  '事前充分的規劃與準備',
  '在可承受地點行走宿營',
  '適當處理垃圾維護環境',
  '保持環境原有的風貌',
  '減低用火對環境的衝擊',
  '尊重野生動植物',
  '考量其他的使用者',
]

const section = [
  {
    src: image3,
    source: '實拍圖來源：曾武平',
    text: '很多人會帶水果上山食用，既有水分、又有營養，多數的人會將果皮、種子等棄置於戶外，任其自然分解。果皮上可能有農藥會其他物質的殘留，這些都是環境賀爾蒙因子，及可能對環境造成嚴重的影響，當然，在其他人的自然感官上，也都是不好的體驗，比如體驗者會說:是誰在亂丟垃圾啊!看起來好髒亂啊!\n\n如果這個地區因為我們所丟棄的水果種子，造成這個地方未來長出這些水果，那對環境影響也是相當巨大的，試想原本不應該存在的物種，在此繁衍興盛起來，即為一個強勢物種，一個物種的進入，可能造成一到兩個或以上的原生物種消失，也就是所謂的外來種對原生物種的影響，對此原生態環境被改變而造成的生態災難，很多人說這是”物競天擇”，這是錯誤的說法，要不是因為人為的介入，這事原本就不應該發生，所以是”物競人擇”，比較好的方式是，帶可以全食的水果上山，比如蘋果皮(事先先做好清洗)、蘋果核、蘋果子都是可以吃的。\n\n最後一個例子，北插天山、塔曼山、嘉明湖(特別是向陽山屋至向陽名樹間)都是屬於地質敏感的地區，充滿許多的根系裸露以及沖蝕溝現象，而嘉明湖更因此造成許多大面積土質崩落流失，這些都是由人類的踩踏所造成的結果，流量管制以及不走捷徑，搭配目前台東林管處推行的手作步道，是目前短暫可行的保護方式。'
  },
  {
    src: image1,
    source: '圖：塔曼山根系裸露現象 / 實拍圖來源：曾武平'
  },
  {
    src: image2,
    source: '實拍圖來源：曾武平',
    text: '「無痕山林運動」沒有絕對的標準，過去在推行「無痕山林運動」的期間，有些單位、學校登山社會形成兩方的對立，一個是極高的道德標準，一個是想要學習，卻因為另一方”標準”太高而望之卻步，無痕山林是經由理解、認同而漸漸學習的，並非強迫的行為，因此千萬不要以自身的高標準，來定義別人的不是，因為對方可能過去什麼都不做，到現在經由學習已經可以多做很多減少對環境影響的作為了。如果標準可以去定義的話，自己已經能夠做到50分時，就不要想著30分、40分的事，而是去思考如何可以做到51分、60分、70分，更友善的親近戶外環境。\n\n過去我在戶外拿著塑膠袋撿垃圾，希望眾多的垃圾可以漸漸消失，但我在撿垃圾的同時，另外一端可能有人在丟著垃圾，或做出對環境有嚴重影響的行為，垃圾根本撿不完，敬邀各位一同參與無痕山林運動，藉由自身的行為、影響力來影響周邊的人，一起做出對環境友善的行為，這樣環境才有可能變得更好，垃圾也才有趨於撿完的一天，環境才能獲得延續與永續的發展。'
  },
]

const LeaveNoTrace = () => (
  <Container maxWidth="800px">
    <Text.H1>【從自身開始，逐步創造環境永續的可能】<br />--- 無痕山林運動</Text.H1>
    <Text.H6>撰文 / 曾武平， 文章來源 / 橘子關懷基金會</Text.H6>
    <Text.Article>
      「無痕山林運動」源自於美國無痕旅遊概念(Leave No Trace，簡稱LNT)，台灣由2006年在林務局舉辦的全國步道環境優化研討會中，LNT受到產官學界的重視與迴響，並透過網路的票選，將LNT在台灣定名為「無痕山林運動」，同年發表「無痕山林宣言」，為無痕山林運動的由來。
    </Text.Article>
    <Text.Article >
      「無痕山林運動」內容廣泛，概括有七個原則要項，主要教導大眾對待環境的正確觀念與技巧，協助將遊憩活動對自然的衝擊影響降到最低。
    </Text.Article>
    <Box as="ol" my="2em">
      {principle.map((text, i) => (
        <li key={i}>{text}</li>
      ))}
    </Box>
    <Text.Article >
      我們往往會因為認知不足，造成很多對環境衝擊影響的行為，人只要進到戶外，就會對環境造成影響，而盡可能降低對環境的衝擊影響是我們要學習的課題。舉例來說，許多的戶外環境是不接受寵物進到戶外的，首先我們要思考的是，寵物進到戶外會對環境造成什麼樣的影響？影響有多大？我們可以怎麼做？
    </Text.Article>
    <Text.Article >
      我們所處的環境與不同地區的環境是有區別的，如果我們的寵物身上帶有病原菌(而寵物本身帶有抗體或不帶抗體)，寵物進入到戶外時，很有可能將病原菌帶給不具抗體的野生動物造成致病，甚至嚴重的生態浩劫，相反的，我們的寵物也有可能在戶外感染其他病原菌，將之帶入我們日常所處的環境中。另外我們最常見的情況是，寵物常常會去追趕野生動物，野生動物逃跑時造成受傷，甚至是直接被寵物啃咬，這原本都不應該發生的，卻因為人類的不在意或不了解，而造成對環境的影響。
    </Text.Article>
    {section.map(({ src, text, source }, i) => (
      <Box key={i}>
        <Image src={src} />
        <Text>{source}</Text>
        <Text.Article whiteSpace="pre-wrap">
          {text}
        </Text.Article>
      </Box>
    ))}
    <Text.H6 textAlign="left" mb="2rem">作者簡介：</Text.H6>
    <Text fontWeight="bold">
      曾武平 Tseng Wu Ping<br />
      <br />
      財團法人台灣外展教育基金會 風險管理部經理<br />
      2016 台灣第一批徒步者，完成美國太平洋屋脊步道(PCT)<br />
      2012 獨攀完成所有台灣百岳<br />
      Leave No Trace無痕山林運動高階教師<br />
      教育部體育署山域嚮導<br />
      林務局無痕山林運動執行推動小組秘書處助理研究員(99-100)<br />
      嘉義林管處環境教育研習，無痕山林課程講師<br />
    </Text>
  </Container>
)

export default LeaveNoTrace
