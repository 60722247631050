import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'
import { FaFacebook } from 'react-icons/fa'
import { AiFillInstagram } from 'react-icons/ai'

import Box from '../components/Box';
import Flex from '../components/Flex';
import Link from '../components/Link';
import theme from '../components/ThemeProvider/theme';
import Container from '../components/Container';
import { responsive } from '../components/ThemeProvider/theme';

import Header from './Header'

const Layout = ({ children }) => {
  const location = useLocation()
  const noHeader = location.pathname.match(/^\/topics/)
  useEffect(() => {
    window.scroll({ top: 0 })
    ReactGA.pageview(location.pathname + location.search);
  }, [location])
  return (
    <>
      {!noHeader && <Header height={theme.headerHeight} siteTitle="山模山樣" />}
      <Box minHeight="100vh" bg="bgGray" pt={!noHeader && theme.headerHeight}>
        {children}
        {!noHeader && (
          <Container>
            <Flex
              flexDirection={responsive('column-reverse', 'row')}
              justifyContent="center"
              alignItems="center"
              py={responsive('3.75em', '4.375em')}
              color="darkGray"
            >
              <Box fontSize="0.75em">© 山模山樣 Mountainlike. All Rights Reserved.</Box>
              <Flex justifyContent="center" alignItems="center" mb={responsive('0.5em', 0)}>
                <Box mx="0.5em" width="1.5em">
                  <Link href="https://www.instagram.com/mountainlike/" color="darkGray">
                    <AiFillInstagram size="1.875em" />
                  </Link>
                </Box>
                <Box mx="0.5em" width="1.5em" mt="-0.125em">
                  <Link href="https://www.facebook.com/mountainlike.relab" color="darkGray">
                    <FaFacebook size="1.5em" />
                  </Link>
                </Box>
              </Flex>
            </Flex>
          </Container>
        )}
      </Box>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
