import React from 'react'

import Container from '../components/Container'
import Text from '../components/Text'
import Box from '../components/Box'
import Link from '../components/Link'
import Button from '../components/Button'

const Workshop = () => (
  <Container maxWidth="800px">
    <Text.H1 whiteSpace="pre-wrap">{'橘子關懷基金會\n「敢IN去冒險」'}</Text.H1>
    <Text.H6>
      「2020 Summer School夏日學園」和台灣青年「一起向山致敬」<br />
      用行動號召台灣青年，共創後疫情時代的冒險可能
    </Text.H6>
    <Text.Article>
      橘子關懷基金會自2008年成立以來，透過各項冒險計畫鼓勵青年挑戰自己，喚起屬於這個世代的冒險精神。今年攜手「台灣外展教育發展基金會」、「米亞桑戶外中心」及「Re-lab」，分別以「體感冒險」與「腦內冒險」為主軸，循序漸進的重啟台灣青年對於冒險的想像，徹底喚醒潛藏在每位台灣青年血液中的冒險基因。
    </Text.Article>
    <Text.Article>
      台灣四面臨海、溪河湍急，更為全世界高山密度最高的島嶼之一。政府於2019年底宣布新山林政策，期待以開放簡化的方式鼓勵大眾走入山林；而隨肺炎疫情改變的新生活模式，人與人之間更應珍視每一次實際互動的機會。同時也需沉靜面對、深層審視大自然給予我們的課題。
    </Text.Article>
    <Text.Article>
      2020年是基金會號召臺灣青年冒險的第13個年頭，決定將臺灣作為錨定，深掘家鄉的文化史脈，從認識腳下土地開始，擁抱台灣根基，順應冒險號召，一同敢IN去冒險。「腦內冒險—轉譯台灣山林，52座群山之最」以臺灣山岳為題，探尋我們與原生環境的關係，用文化與創意磨練青年的思考極限，打破百岳迷思，轉譯屬於新世代的山模山樣。19位學員耗費18小時，完成36座台灣山林資訊圖表，更自願加入山海圳資訊圖表製作，挑戰3小時內從零開始極限腦力激盪。
    </Text.Article>
    <Text.Article>
      登山大多是孤寂的，卻是臺灣山林教給每一位冒險者最深層的課程，冒險也是。臺灣山林更需時刻關注，審視環境讓永續在青年未來的藍圖中發酵。山的模樣也可能是未來的模樣。
    </Text.Article>
    <Text.Article>
      橘子關懷基金會將持續號召台灣青年持續參與屬於台灣的下一場冒險，讓冒險成為一種生活風格、更是每次人類進化的能量。
    </Text.Article>
    <Text.H1 mt="7.5rem">關於橘子關懷基金會</Text.H1>
    <Text.Article>橘子集團是一個夢想的集合體，匯聚創辦人劉柏園、橘子人們的夢想，更乘載許多社會大眾的夢想。在成立十幾年後、不斷接受許多外界支持、幫助的橘子集團，開始反思、期許自己能有更集中資源、組織性的付出—善盡「企業社會責任」。於2008年1月4日，橘子集團正式啟動「橘子關懷基金會」。</Text.Article>
    <Box textAlign="center" my="3.75em">
      <Button.White color="black" hoverBg="bgGray" hoverColor="black" as={Link} href="https://www.facebook.com/gamaniacheerupfoundation">
        尋找更多冒險可能
      </Button.White>
    </Box>
    <Text.H1>2020 Summer School 夏日學院計劃</Text.H1>
    <Text.Article>
      主辦單位：橘子關懷基金會<br />
      計畫支持：橘子集團<br />
      計劃夥伴：台灣外展教育發展基金會、米亞桑戶外中心、Re-lab<br />
      2020Summer School學員：陳俞安、黃亮瑄、黃舒玟、方又芊、黃郁雯、謝沛馨、羅品蓁、陳念廷、柯柔因、蕭筑元、黃意晴、林貫佑、洪薇檸、盛子芸、楊彥彬、廖韋婷、許意𦬕、張雅晴、陳肯 (以上為腦內冒險學員)<br />
      詹理晴、陳謙美、陳庚鼎、唐詩晴、李如綺、黃冠鈞、鄭筑勻、林迺凱、吳思穎、賴重睿、陳怡佩、林佳言、周均潤、蘇庭葳、陳柔安、倪耶宣、盛以和、林贊、汪書萱、洪于倫、曾子恩 (以上為體感冒險學員) <br />
      執行單位：橘子關懷基金會<br />
      總策劃人：陳秉良<br />
      專案管理：甘填 <br />
      執行協力：李汶錡、胡家綾、謝乙嘉、盧以婕<br />
      公關統籌：陳韻茹<br />
      公關企劃：李其穎、施欣豪<br />
      影像紀錄：曾琮霖、李邦豪、楊謹毓、何語樂、劉彥宏<br />
      媒體合作夥伴：今日新聞 NOWnews<br />
      特別感謝：王文靜、呂忠翰、李永欽、朱鵬諺、楊文智、趙民傑、鄭雅元、鄒欣寧、許莎莉<br />
    </Text.Article>
  </Container>
)

export default Workshop
