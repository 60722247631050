import React from 'react'
// import { Link } from 'react-router-dom';

import Box from '../../components/Box';
import Link from '../../components/Link';
import Text from '../../components/Text';
import Image from '../../components/Image';

import part2 from './part2.jpg'
import part2webp from './part2.webp'

const systems = [
  '急救相關：急救這件事情可以分為器材(硬體)以及知識(軟體)。而器材方面通常會依據個人或是團隊需求進行調整，往往很難有固定的器材清單，但可以把握幾個大的原則，如外傷處理基本配備(繃帶、貼布、紗布、軟膏)以及內服用藥(止痛、消炎)等，準備好相關配備更能快速處理事件。急救知識的建立是相當重要的標配！訓練課程上可以參考WMA野外急救課程，透過2天、4天或是8天的訓練課程，將實際處遇及概念灌入腦袋中，這樣在真的發生緊急情況時，即便當下硬體設備不足，仍可以讓自己處於相對鎮定的狀態，以便爭取更過維生機會，對於戶外活動更能多一層保護。',
  '切割工具：主要是用來處理食材或是切割物品，切割物品主要用於協助求助與創新物品兩個面向。市面上有些多功能刀具組，不僅具有切割功能，甚至有螺絲起子、開罐器甚至是小鋸子等，多功能刀具組當然相對便捷，只是登山活動仍須注意負重問題，準備裝備仍須考量此行山徑的狀態與行程時間，畢竟越複雜的刀具組相對重量也會越重～',
  '火源系統：主要指涉打火機與起火裝置。在緊急時刻，我們需要一個不怕潮濕就可以打起火來的裝置。故通常會準備2-3個打火機在身上備用，並且要個別妥善包裝好，分散風險。起火裝置如鎂棒打火石，並搭配易燃燒的火種，就能在野外環境中升火，達到保暖及求援的功效。'
]

const PartII = () => {
  return (
    <Box>
      <Text.Article>
        上篇提及定位系統、照明系統、防曬保護<Link.Underline color="black" to="/equipment/1">(野外基礎裝備I)</Link.Underline>，接續則是關於急救、切割工具以及火源等三個系統：
      </Text.Article>
      <Box as="ol" listStyleType="lower-alpha" my="2em">
        {systems.map((text, i) => (
          <Box as="li" mt="1em" key={i}>{text}</Box>
        ))}
      </Box>
      <Box my="2em">
        <Image src={[part2webp, part2]} />
        <Text>實拍圖來源：許書瑋</Text>
      </Box>
    </Box>
  )
}

export default PartII
