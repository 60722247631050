import { useState, useEffect } from 'react'
import { tsv } from 'd3-fetch'
import mapValues from 'lodash/mapValues'
import range from 'lodash/range'

const dataHandler = {
}

export default (url) => {
  const [loading, setLoading] = useState()
  const [data, setData] = useState([])
  useEffect(() => {
    setLoading(true)
    tsv(url).then(d => {
      setData(d)
      setLoading(false)
    })
  }, [])

  return {
    data,
    loading,
  }
}
