import { createGlobalStyle } from 'styled-components';

import theme from './theme';

import addtionalNotoSans from '../../webfont'

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;700;900&display=swap');
  ${addtionalNotoSans}
  body {
    font-family: ${theme.font};
    font-size: ${theme.fontSize};
    min-width: 100%;
    min-height: 100%;
  }
`;
