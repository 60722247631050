import React from 'react'

import Text from '../../components/Text';
import Container from '../../components/Container';

import PartI from './PartI'
import PartII from './PartII'
import PartIII from './PartIII'

const titles = [
  'I', 'II', 'III'
]

const part = [
  PartI,
  PartII,
  PartIII
]

const Equipment = ({ match: { params: { id } } }) => {
  const title = titles[id - 1]
  const Content = part[id - 1]
  return (
    <Container maxWidth="800px">
      <Text.H1>【野外基礎裝備 PART {title}】</Text.H1>
      <Text.H6>
        撰文 / 許書瑋，文章來源 / 橘子關懷基金會
      </Text.H6>
      <Content />
      <Text.H6 textAlign="left" mb="2rem">作者簡介：</Text.H6>
      <Text fontWeight="bold">
        許書瑋 Shu Wei Hsu<br />
        <br />
        米亞桑戶外中心<br />
        戶外教育工作者<br />
        無痕山林高階教師(LNT Master course)認證<br />
        Wilderness First Responder, WMA 野外急救助理教練<br />
      </Text>
    </Container>
  )
}

export default Equipment
