import React from 'react'

import Container from '../../components/Container'
import Text from '../../components/Text'
import Box from '../../components/Box'
import Image from '../../components/Image'

import aid2 from './aid_2.jpg'
import aid2webp from './aid_2.webp'


const contentWidthTitle = [
  {
    title: '【相對艱困的野外環境】',
    text: '野外，指的可能是高山、雪地、海洋或沙漠這類自然環境，身處於其中需要考慮的環境因子相較都市更為複雜，如；高海拔氧氣稀薄、氣溫低等都會延後傷口癒合的時間。因此，如何在這類艱難的環境中，作出合理的判斷，並為傷患或自己提供適當且適時的處置非常重要。'
  },
  {
    title: '【送醫延遲】',
    text: '由於身處野外，距離完善的都市醫療系統較為遙遠。因此，如何在等待後送的時間裡，掌握傷患的情況、提供適切的處置、預期可能發生的問題並加以預防，避免惡化，則是急救的關鍵能力。'
  },
  {
    title: '【有限的資源與設備】',
    text: '因為身處野外，缺乏院內醫療的完整設備，彈性運用身邊與環境現有的資源，發展處置傷患所需的設備，如製作擔架、肌肉骨骼固定護具等，也是野外急救技術的重點。'
  },
  {
    title: '【確實判斷傷患情勢選擇適當後送】',
    src: [aid2webp, aid2],
    source: '實拍圖來源：WMA Taiwan',
    text: '除了適當處置傷患、提供救援抵達前的照護外，判斷傷患當下狀態屬於緊急與否、嚴重與否、風險高低、穩定與否，是野外急救的核心概念。須避免因為對於傷患情勢的不了解而作出不適當的後送方式，尤其是用高風險方式後送低風險患者，如用直升機（高風險運輸工具）後送腳踝輕微扭傷患者（屬不緊急/低風險/穩定的傷患）。'
  },
]

const Aid = () => (
  <Container maxWidth="800px">
    <Text.H1 whiteSpace="pre-wrap">【登山不可不知的野外急救知識】</Text.H1>
    <Text.H6>
      撰文 / 彭筱琦，文章來源 / 橘子關懷基金會
    </Text.H6>
    <Text.Article>
      <b>什麼是野外急救？</b>
    </Text.Article>
    <Text.Article>
      野外急救是專為資源匱乏、偏遠地區、撤退時間較長的戶外活動帶領者、嚮導、救難人員、軍事等專業需求所發展的知識與技術。此類課程在國際上是從事戶外活動專業人員必要的基本條件之一。此外，亦有長途遠征挑戰者受此訓練，降低獨自面對野外挑戰時可能面臨的醫療風險；甚至，部分知識與技術也可應用於大規模災難醫療中。
    </Text.Article>
    <Text.Article>
      野外急救與都市到院前急救殊途同根，兩者醫療原則大致相同，但在部分處置方法則有顯著的差異。野外急救課程著重於長時間無法抵達醫院及獲得救援的情況下，如何判斷並處理緊急的急救狀況、提供長時間的傷患照護、克服嚴苛的環境以及現地裝備製作相關處置器材等野外情況下的急救知識與技術。
    </Text.Article>
    <Text.Article>
      <b>為何需要具備野外急救能力？</b>
    </Text.Article>
    {contentWidthTitle.map(({ title, text, src, source }, k) => (
      <Box key={k}>
        <Text.Article>
          <Text fontWeight="bold">{title}</Text>
          {text}
        </Text.Article>
        {src &&
          <Box>
            <Image src={src} />
            <Text>{source}</Text>
          </Box>
        }
      </Box>
    ))}
    <Text.Article>最近，台灣從事戶外活動人口明顯增加，意外事件頻傳。若每個人都能理解自己的能力，選擇合適能力的地點，就能減少意外發生的風險。而具備基本野外急救能力能夠準確判斷意外的緊急與嚴重程度，進而做出適當的處置。選擇合宜的救援更可以降低意外發生時，自己、傷患與救援者在過程中的風險，是需要三方共同努力的！</Text.Article>
    <Text.H6 textAlign="left" mb="2rem">作者簡介：</Text.H6>
    <Text fontWeight="bold">
      彭筱琦 Hsiao Chi Peng<br />
      <br />
      新罕布夏州立大學 冒險治療與社會工作雙碩士<br />
      戶外教育工作者<br />
      國際野外醫療協會（WMAI） 教練<br />
      中級救護技術員（EMT-II）<br />
    </Text>
  </Container>
)

export default Aid
