import luoshan from './luoshan.png'
import qixingshan from './qixingshan.png'
import dawulunshan from './dawulunshan.png'
import guanyinshan from './guanyinshan.png'
import hongdanshan from './hongdanshan.png'
import jilongshan from './jilongshan.png'
import wuerchahushan from './wuerchahushan.png'
import xiangshan from './xiangshan.png'
import tianshangshan from './tianshangshan.png'
import quezishan from './quezishan.png'
import dongyanshan from './dongyanshan.png'
import feifengshan from './feifengshan.png'
import lalashan from './lalashan.png'
import jialishan from './jialishan.png'
import shengmudengshan from './shengmudengshan.png'
import dabajianshan from './dabajianshan.png'
import taiwushan from './taiwushan.png'
import taoshan from './taoshan.png'
import xueshan from './xueshan.png'
import huoshishan from './huoshishan.png'
import huoyanshan from './huoyanshan.png'
import dajianshan from './dajianshan.png'
import taipingshan from './taipingshan.png'
import lingmingshan from './lingmingshan.png'
import yuntaishan from './yuntaishan.png'
import shaolaishan from './shaolaishan.png'
import yuanzuishan from './yuanzuishan.png'
import chuyinshan from './chuyinshan.png'
import guangtoushan from './guangtoushan.png'
import liyushan from './liyushan.png'
import hengshan from './hengshan.png'
import maolanshan from './maolanshan.png'
import yuemeishan from './yuemeishan.png'
import kuibishan from './kuibishan.png'
import yunjiadajianshan from './yunjiadajianshan.png'
import alishan from './alishan.png'
import fenqihudadongshan from './fenqihudadongshan.png'
import xiuguluanshan from './xiuguluanshan.png'
import yushan from './yushan.png'
import lushan from './lushan.png'
import xiangyangshan from './xiangyangshan.png'
import kantoushan from './kantoushan.png'
import liushishishan from './liushishishan.png'
import zhuzijianshan from './zhuzijianshan.png'
import zhongniuhushan from './zhongniuhushan.png'
import luohanshan from './18luohanshan.png'
import doulanshan from './doulanshan.png'
import huoshaoshan from './huoshaoshan.png'
import shoushan from './shoushan.png'
import taimalishan from './taimalishan.png'
import lidingshan from './lidingshan.png'
import hongtoushan from './hongtoushan.png'

import luoshanwebp from './luoshan.webp'
import qixingshanwebp from './qixingshan.webp'
import dawulunshanwebp from './dawulunshan.webp'
import guanyinshanwebp from './guanyinshan.webp'
import hongdanshanwebp from './hongdanshan.webp'
import jilongshanwebp from './jilongshan.webp'
import wuerchahushanwebp from './wuerchahushan.webp'
import xiangshanwebp from './xiangshan.webp'
import tianshangshanwebp from './tianshangshan.webp'
import quezishanwebp from './quezishan.webp'
import dongyanshanwebp from './dongyanshan.webp'
import feifengshanwebp from './feifengshan.webp'
import lalashanwebp from './lalashan.webp'
import jialishanwebp from './jialishan.webp'
import shengmudengshanwebp from './shengmudengshan.webp'
import dabajianshanwebp from './dabajianshan.webp'
import taiwushanwebp from './taiwushan.webp'
import taoshanwebp from './taoshan.webp'
import xueshanwebp from './xueshan.webp'
import huoshishanwebp from './huoshishan.webp'
import huoyanshanwebp from './huoyanshan.webp'
import dajianshanwebp from './dajianshan.webp'
import taipingshanwebp from './taipingshan.webp'
import lingmingshanwebp from './lingmingshan.webp'
import yuntaishanwebp from './yuntaishan.webp'
import shaolaishanwebp from './shaolaishan.webp'
import yuanzuishanwebp from './yuanzuishan.webp'
import chuyinshanwebp from './chuyinshan.webp'
import guangtoushanwebp from './guangtoushan.webp'
import liyushanwebp from './liyushan.webp'
import hengshanwebp from './hengshan.webp'
import maolanshanwebp from './maolanshan.webp'
import yuemeishanwebp from './yuemeishan.webp'
import kuibishanwebp from './kuibishan.webp'
import yunjiadajianshanwebp from './yunjiadajianshan.webp'
import alishanwebp from './alishan.webp'
import fenqihudadongshanwebp from './fenqihudadongshan.webp'
import xiuguluanshanwebp from './xiuguluanshan.webp'
import yushanwebp from './yushan.webp'
import lushanwebp from './lushan.webp'
import xiangyangshanwebp from './xiangyangshan.webp'
import kantoushanwebp from './kantoushan.webp'
import liushishishanwebp from './liushishishan.webp'
import zhuzijianshanwebp from './zhuzijianshan.webp'
import zhongniuhushanwebp from './zhongniuhushan.webp'
import luohanshanwebp from './18luohanshan.webp'
import doulanshanwebp from './doulanshan.webp'
import huoshaoshanwebp from './huoshaoshan.webp'
import shoushanwebp from './shoushan.webp'
import taimalishanwebp from './taimalishan.webp'
import lidingshanwebp from './lidingshan.webp'
import hongtoushanwebp from './hongtoushan.webp'

import luoshanicon from './luoshanicon.png'
import qixingshanicon from './qixingshanicon.png'
import dawulunshanicon from './dawulunshanicon.png'
import guanyinshanicon from './guanyinshanicon.png'
import hongdanshanicon from './hongdanshanicon.png'
import jilongshanicon from './jilongshanicon.png'
import wuerchahushanicon from './wuerchahushanicon.png'
import xiangshanicon from './xiangshanicon.png'
import tianshangshanicon from './tianshangshanicon.png'
import quezishanicon from './quezishanicon.png'
import dongyanshanicon from './dongyanshanicon.png'
import feifengshanicon from './feifengshanicon.png'
import lalashanicon from './lalashanicon.png'
import jialishanicon from './jialishanicon.png'
import shengmudengshanicon from './shengmudengshanicon.png'
import dabajianshanicon from './dabajianshanicon.png'
import taiwushanicon from './taiwushanicon.png'
import taoshanicon from './taoshanicon.png'
import xueshanicon from './xueshanicon.png'
import huoshishanicon from './huoshishanicon.png'
import huoyanshanicon from './huoyanshanicon.png'
import dajianshanicon from './dajianshanicon.png'
import taipingshanicon from './taipingshanicon.png'
import lingmingshanicon from './lingmingshanicon.png'
import yuntaishanicon from './yuntaishanicon.png'
import shaolaishanicon from './shaolaishanicon.png'
import yuanzuishanicon from './yuanzuishanicon.png'
import chuyinshanicon from './chuyinshanicon.png'
import guangtoushanicon from './guangtoushanicon.png'
import liyushanicon from './liyushanicon.png'
import hengshanicon from './hengshanicon.png'
import maolanshanicon from './maolanshanicon.png'
import yuemeishanicon from './yuemeishanicon.png'
import kuibishanicon from './kuibishanicon.png'
import yunjiadajianshanicon from './yunjiadajianshanicon.png'
import alishanicon from './alishanicon.png'
import fenqihudadongshanicon from './fenqihudadongshanicon.png'
import xiuguluanshanicon from './xiuguluanshanicon.png'
import yushanicon from './yushanicon.png'
import lushanicon from './lushanicon.png'
import xiangyangshanicon from './xiangyangshanicon.png'
import kantoushanicon from './kantoushanicon.png'
import liushishishanicon from './liushishishanicon.png'
import zhuzijianshanicon from './zhuzijianshanicon.png'
import zhongniuhushanicon from './zhongniuhushanicon.png'
import luohanshanicon from './luohanshanicon.png'
import doulanshanicon from './doulanshanicon.png'
import huoshaoshanicon from './huoshaoshanicon.png'
import shoushanicon from './shoushanicon.png'
import taimalishanicon from './taimalishanicon.png'
import lidingshanicon from './lidingshanicon.png'
import hongtoushanicon from './hongtoushanicon.png'

import luoshaniconwebp from './luoshanicon.webp'
import qixingshaniconwebp from './qixingshanicon.webp'
import dawulunshaniconwebp from './dawulunshanicon.webp'
import guanyinshaniconwebp from './guanyinshanicon.webp'
import hongdanshaniconwebp from './hongdanshanicon.webp'
import jilongshaniconwebp from './jilongshanicon.webp'
import wuerchahushaniconwebp from './wuerchahushanicon.webp'
import xiangshaniconwebp from './xiangshanicon.webp'
import tianshangshaniconwebp from './tianshangshanicon.webp'
import quezishaniconwebp from './quezishanicon.webp'
import dongyanshaniconwebp from './dongyanshanicon.webp'
import feifengshaniconwebp from './feifengshanicon.webp'
import lalashaniconwebp from './lalashanicon.webp'
import jialishaniconwebp from './jialishanicon.webp'
import shengmudengshaniconwebp from './shengmudengshanicon.webp'
import dabajianshaniconwebp from './dabajianshanicon.webp'
import taiwushaniconwebp from './taiwushanicon.webp'
import taoshaniconwebp from './taoshanicon.webp'
import xueshaniconwebp from './xueshanicon.webp'
import huoshishaniconwebp from './huoshishanicon.webp'
import huoyanshaniconwebp from './huoyanshanicon.webp'
import dajianshaniconwebp from './dajianshanicon.webp'
import taipingshaniconwebp from './taipingshanicon.webp'
import lingmingshaniconwebp from './lingmingshanicon.webp'
import yuntaishaniconwebp from './yuntaishanicon.webp'
import shaolaishaniconwebp from './shaolaishanicon.webp'
import yuanzuishaniconwebp from './yuanzuishanicon.webp'
import chuyinshaniconwebp from './chuyinshanicon.webp'
import guangtoushaniconwebp from './guangtoushanicon.webp'
import liyushaniconwebp from './liyushanicon.webp'
import hengshaniconwebp from './hengshanicon.webp'
import maolanshaniconwebp from './maolanshanicon.webp'
import yuemeishaniconwebp from './yuemeishanicon.webp'
import kuibishaniconwebp from './kuibishanicon.webp'
import yunjiadajianshaniconwebp from './yunjiadajianshanicon.webp'
import alishaniconwebp from './alishanicon.webp'
import fenqihudadongshaniconwebp from './fenqihudadongshanicon.webp'
import xiuguluanshaniconwebp from './xiuguluanshanicon.webp'
import yushaniconwebp from './yushanicon.webp'
import lushaniconwebp from './lushanicon.webp'
import xiangyangshaniconwebp from './xiangyangshanicon.webp'
import kantoushaniconwebp from './kantoushanicon.webp'
import liushishishaniconwebp from './liushishishanicon.webp'
import zhuzijianshaniconwebp from './zhuzijianshanicon.webp'
import zhongniuhushaniconwebp from './zhongniuhushanicon.webp'
import luohanshaniconwebp from './luohanshanicon.webp'
import doulanshaniconwebp from './doulanshanicon.webp'
import huoshaoshaniconwebp from './huoshaoshanicon.webp'
import shoushaniconwebp from './shoushanicon.webp'
import taimalishaniconwebp from './taimalishanicon.webp'
import lidingshaniconwebp from './lidingshanicon.webp'
import hongtoushaniconwebp from './hongtoushanicon.webp'

import alishanadj from './alishanadj.png'
import chuyinshanadj from './chuyinshanadj.png'
import dawulunshanadj from './dawulunshanadj.png'
import dongyanshanadj from './dongyanshanadj.png'
import doulanshanadj from './doulanshanadj.png'
import dajianshanadj from './dajianshanadj.png'
import dabajianshanadj from './dabajianshanadj.png'
import guanyinshanadj from './guanyinshanadj.png'
import guangtoushanadj from './guangtoushanadj.png'
import fenqihudadongshanadj from './fenqihudadongshanadj.png'
import feifengshanadj from './feifengshanadj.png'
import hengshanadj from './hengshanadj.png'
import huoshishanadj from './huoshishanadj.png'
import hongdanshanadj from './hongdanshanadj.png'
import huoyanshanadj from './huoyanshanadj.png'
import hongtoushanadj from './hongtoushanadj.png'
import huoshaoshanadj from './huoshaoshanadj.png'
import jialishanadj from './jialishanadj.png'
import jilongshanadj from './jilongshanadj.png'
import kantoushanadj from './kantoushanadj.png'
import kuibishanadj from './kuibishanadj.png'
import liushishishanadj from './liushishishanadj.png'
import liyushanadj from './liyushanadj.png'
import lalashanadj from './lalashanadj.png'
import luoshanadj from './luoshanadj.png'
import lushanadj from './lushanadj.png'
import luohanshanadj from './18luohanshanadj.png'
import lingmingshanadj from './lingmingshanadj.png'
import lidingshanadj from './lidingshanadj.png'
import maolanshanadj from './maolanshanadj.png'
import quezishanadj from './quezishanadj.png'
import qixingshanadj from './qixingshanadj.png'
import shaolaishanadj from './shaolaishanadj.png'
import shengmudengshanadj from './shengmudengshanadj.png'
import shoushanadj from './shoushanadj.png'
import taipingshanadj from './taipingshanadj.png'
import tianshangshanadj from './tianshangshanadj.png'
import taiwushanadj from './taiwushanadj.png'
import taoshanadj from './taoshanadj.png'
import taimalishanadj from './taimalishanadj.png'
import wuerchahushanadj from './wuerchahushanadj.png'
import xiangshanadj from './xiangshanadj.png'
import xueshanadj from './xueshanadj.png'
import xiuguluanshanadj from './xiuguluanshanadj.png'
import xiangyangshanadj from './xiangyangshanadj.png'
import yushanadj from './yushanadj.png'
import yuntaishanadj from './yuntaishanadj.png'
import yuanzuishanadj from './yuanzuishanadj.png'
import yuemeishanadj from './yuemeishanadj.png'
import yunjiadajianshanadj from './yunjiadajianshanadj.png'
import zhuzijianshanadj from './zhuzijianshanadj.png'
import zhongniuhushanadj from './zhongniuhushanadj.png'

import alishanadjwebp from './alishanadj.webp'
import chuyinshanadjwebp from './chuyinshanadj.webp'
import dawulunshanadjwebp from './dawulunshanadj.webp'
import dongyanshanadjwebp from './dongyanshanadj.webp'
import doulanshanadjwebp from './doulanshanadj.webp'
import dajianshanadjwebp from './dajianshanadj.webp'
import dabajianshanadjwebp from './dabajianshanadj.webp'
import guanyinshanadjwebp from './guanyinshanadj.webp'
import guangtoushanadjwebp from './guangtoushanadj.webp'
import fenqihudadongshanadjwebp from './fenqihudadongshanadj.webp'
import feifengshanadjwebp from './feifengshanadj.webp'
import hengshanadjwebp from './hengshanadj.webp'
import huoshishanadjwebp from './huoshishanadj.webp'
import hongdanshanadjwebp from './hongdanshanadj.webp'
import huoyanshanadjwebp from './huoyanshanadj.webp'
import hongtoushanadjwebp from './hongtoushanadj.webp'
import huoshaoshanadjwebp from './huoshaoshanadj.webp'
import jialishanadjwebp from './jialishanadj.webp'
import jilongshanadjwebp from './jilongshanadj.webp'
import kantoushanadjwebp from './kantoushanadj.webp'
import kuibishanadjwebp from './kuibishanadj.webp'
import liushishishanadjwebp from './liushishishanadj.webp'
import liyushanadjwebp from './liyushanadj.webp'
import lalashanadjwebp from './lalashanadj.webp'
import luoshanadjwebp from './luoshanadj.webp'
import lushanadjwebp from './lushanadj.webp'
import luohanshanadjwebp from './18luohanshanadj.webp'
import lingmingshanadjwebp from './lingmingshanadj.webp'
import lidingshanadjwebp from './lidingshanadj.webp'
import maolanshanadjwebp from './maolanshanadj.webp'
import quezishanadjwebp from './quezishanadj.webp'
import qixingshanadjwebp from './qixingshanadj.webp'
import shaolaishanadjwebp from './shaolaishanadj.webp'
import shengmudengshanadjwebp from './shengmudengshanadj.webp'
import shoushanadjwebp from './shoushanadj.webp'
import taipingshanadjwebp from './taipingshanadj.webp'
import tianshangshanadjwebp from './tianshangshanadj.webp'
import taiwushanadjwebp from './taiwushanadj.webp'
import taoshanadjwebp from './taoshanadj.webp'
import taimalishanadjwebp from './taimalishanadj.webp'
import wuerchahushanadjwebp from './wuerchahushanadj.webp'
import xiangshanadjwebp from './xiangshanadj.webp'
import xueshanadjwebp from './xueshanadj.webp'
import xiuguluanshanadjwebp from './xiuguluanshanadj.webp'
import xiangyangshanadjwebp from './xiangyangshanadj.webp'
import yushanadjwebp from './yushanadj.webp'
import yuntaishanadjwebp from './yuntaishanadj.webp'
import yuanzuishanadjwebp from './yuanzuishanadj.webp'
import yuemeishanadjwebp from './yuemeishanadj.webp'
import yunjiadajianshanadjwebp from './yunjiadajianshanadj.webp'
import zhuzijianshanadjwebp from './zhuzijianshanadj.webp'
import zhongniuhushanadjwebp from './zhongniuhushanadj.webp'

export const front = {
  luoshan: [luoshanadjwebp, luoshanadj],
  qixingshan: [qixingshanadjwebp, qixingshanadj],
  dawulunshan: [dawulunshanadjwebp, dawulunshanadj],
  guanyinshan: [guanyinshanadjwebp, guanyinshanadj],
  hongdanshan: [hongdanshanadjwebp, hongdanshanadj],
  jilongshan: [jilongshanadjwebp, jilongshanadj],
  wuerchahushan: [wuerchahushanadjwebp, wuerchahushanadj],
  xiangshan: [xiangshanadjwebp, xiangshanadj],
  tianshangshan: [tianshangshanadjwebp, tianshangshanadj],
  quezishan: [quezishanadjwebp, quezishanadj],
  dongyanshan: [dongyanshanadjwebp, dongyanshanadj],
  feifengshan: [feifengshanadjwebp, feifengshanadj],
  lalashan: [lalashanadjwebp, lalashanadj],
  jialishan: [jialishanadjwebp, jialishanadj],
  shengmudengshan: [shengmudengshanadjwebp, shengmudengshanadj],
  dabajianshan: [dabajianshanadjwebp, dabajianshanadj],
  taiwushan: [taiwushanadjwebp, taiwushanadj],
  taoshan: [taoshanadjwebp, taoshanadj],
  xueshan: [xueshanadjwebp, xueshanadj],
  huoshishan: [huoshishanadjwebp, huoshishanadj],
  huoyanshan: [huoyanshanadjwebp, huoyanshanadj],
  dajianshan: [dajianshanadjwebp, dajianshanadj],
  taipingshan: [taipingshanadjwebp, taipingshanadj],
  lingmingshan: [lingmingshanadjwebp, lingmingshanadj],
  yuntaishan: [yuntaishanadjwebp, yuntaishanadj],
  shaolaishan: [shaolaishanadjwebp, shaolaishanadj],
  yuanzuishan: [yuanzuishanadjwebp, yuanzuishanadj],
  chuyinshan: [chuyinshanadjwebp, chuyinshanadj],
  guangtoushan: [guangtoushanadjwebp, guangtoushanadj],
  liyushan: [liyushanadjwebp, liyushanadj],
  hengshan: [hengshanadjwebp, hengshanadj],
  maolanshan: [maolanshanadjwebp, maolanshanadj],
  yuemeishan: [yuemeishanadjwebp, yuemeishanadj],
  kuibishan: [kuibishanadjwebp, kuibishanadj],
  yunjiadajianshan: [yunjiadajianshanadjwebp, yunjiadajianshanadj],
  alishan: [alishanadjwebp, alishanadj],
  fenqihudadongshan: [fenqihudadongshanadjwebp, fenqihudadongshanadj],
  xiuguluanshan: [xiuguluanshanadjwebp, xiuguluanshanadj],
  yushan: [yushanadjwebp, yushanadj],
  lushan: [lushanadjwebp, lushanadj],
  xiangyangshan: [xiangyangshanadjwebp, xiangyangshanadj],
  kantoushan: [kantoushanadjwebp, kantoushanadj],
  liushishishan: [liushishishanadjwebp, liushishishanadj],
  zhuzijianshan: [zhuzijianshanadjwebp, zhuzijianshanadj],
  zhongniuhushan: [zhongniuhushanadjwebp, zhongniuhushanadj],
  luohanshan: [luohanshanadjwebp, luohanshanadj],
  doulanshan: [doulanshanadjwebp, doulanshanadj],
  huoshaoshan: [huoshaoshanadjwebp, huoshaoshanadj],
  shoushan: [shoushanadjwebp, shoushanadj],
  taimalishan: [taimalishanadjwebp, taimalishanadj],
  lidingshan: [lidingshanadjwebp, lidingshanadj],
  hongtoushan: [hongtoushanadjwebp, hongtoushanadj],
}

export const back = {
  yushan: [yushanwebp, yushan],
  xueshan: [xueshanwebp, xueshan],
  xiuguluanshan: [xiuguluanshanwebp, xiuguluanshan],
  xiangyangshan: [xiangyangshanwebp, xiangyangshan],
  dabajianshan: [dabajianshanwebp, dabajianshan],
  taoshan: [taoshanwebp, taoshan],
  huoshishan: [huoshishanwebp, huoshishan],
  lingmingshan: [lingmingshanwebp, lingmingshan],
  lushan: [lushanwebp, lushan],
  guangtoushan: [guangtoushanwebp, guangtoushan],
  dajianshan: [dajianshanwebp, dajianshan],
  shaolaishan: [shaolaishanwebp, shaolaishan],
  jialishan: [jialishanwebp, jialishan],
  alishan: [alishanwebp, alishan],
  yuanzuishan: [yuanzuishanwebp, yuanzuishan],
  lalashan: [lalashanwebp, lalashan],
  taipingshan: [taipingshanwebp, taipingshan],
  fenqihudadongshan: [fenqihudadongshanwebp, fenqihudadongshan],
  taimalishan: [taimalishanwebp, taimalishan],
  yunjiadajianshan: [yunjiadajianshanwebp, yunjiadajianshan],
  dongyanshan: [dongyanshanwebp, dongyanshan],
  doulanshan: [doulanshanwebp, doulanshan],
  qixingshan: [qixingshanwebp, qixingshan],
  zhuzijianshan: [zhuzijianshanwebp, zhuzijianshan],
  maolanshan: [maolanshanwebp, maolanshan],
  shengmudengshan: [shengmudengshanwebp, shengmudengshan],
  liushishishan: [liushishishanwebp, liushishishan],
  chuyinshan: [chuyinshanwebp, chuyinshan],
  kantoushan: [kantoushanwebp, kantoushan],
  zhongniuhushan: [zhongniuhushanwebp, zhongniuhushan],
  quezishan: [quezishanwebp, quezishan],
  lidingshan: [lidingshanwebp, lidingshan],
  guanyinshan: [guanyinshanwebp, guanyinshan],
  yuemeishan: [yuemeishanwebp, yuemeishan],
  huoyanshan: [huoyanshanwebp, huoyanshan],
  liyushan: [liyushanwebp, liyushan],
  jilongshan: [jilongshanwebp, jilongshan],
  wuerchahushan: [wuerchahushanwebp, wuerchahushan],
  hongtoushan: [hongtoushanwebp, hongtoushan],
  hengshan: [hengshanwebp, hengshan],
  tianshangshan: [tianshangshanwebp, tianshangshan],
  feifengshan: [feifengshanwebp, feifengshan],
  shoushan: [shoushanwebp, shoushan],
  huoshaoshan: [huoshaoshanwebp, huoshaoshan],
  taiwushan: [taiwushanwebp, taiwushan],
  yuntaishan: [yuntaishanwebp, yuntaishan],
  dawulunshan: [dawulunshanwebp, dawulunshan],
  hongdanshan: [hongdanshanwebp, hongdanshan],
  luohanshan: [luohanshanwebp, luohanshan],
  xiangshan: [xiangshanwebp, xiangshan],
  luoshan: [luoshanwebp, luoshan],
  kuibishan: [kuibishanwebp, kuibishan],
}

export const icons = {
  yushan: [yushaniconwebp, yushanicon],
  xueshan: [xueshaniconwebp, xueshanicon],
  xiuguluanshan: [xiuguluanshaniconwebp, xiuguluanshanicon],
  xiangyangshan: [xiangyangshaniconwebp, xiangyangshanicon],
  dabajianshan: [dabajianshaniconwebp, dabajianshanicon],
  taoshan: [taoshaniconwebp, taoshanicon],
  huoshishan: [huoshishaniconwebp, huoshishanicon],
  lingmingshan: [lingmingshaniconwebp, lingmingshanicon],
  lushan: [lushaniconwebp, lushanicon],
  guangtoushan: [guangtoushaniconwebp, guangtoushanicon],
  dajianshan: [dajianshaniconwebp, dajianshanicon],
  shaolaishan: [shaolaishaniconwebp, shaolaishanicon],
  jialishan: [jialishaniconwebp, jialishanicon],
  alishan: [alishaniconwebp, alishanicon],
  yuanzuishan: [yuanzuishaniconwebp, yuanzuishanicon],
  lalashan: [lalashaniconwebp, lalashanicon],
  taipingshan: [taipingshaniconwebp, taipingshanicon],
  fenqihudadongshan: [fenqihudadongshaniconwebp, fenqihudadongshanicon],
  taimalishan: [taimalishaniconwebp, taimalishanicon],
  yunjiadajianshan: [yunjiadajianshaniconwebp, yunjiadajianshanicon],
  dongyanshan: [dongyanshaniconwebp, dongyanshanicon],
  doulanshan: [doulanshaniconwebp, doulanshanicon],
  qixingshan: [qixingshaniconwebp, qixingshanicon],
  zhuzijianshan: [zhuzijianshaniconwebp, zhuzijianshanicon],
  maolanshan: [maolanshaniconwebp, maolanshanicon],
  shengmudengshan: [shengmudengshaniconwebp, shengmudengshanicon],
  liushishishan: [liushishishaniconwebp, liushishishanicon],
  chuyinshan: [chuyinshaniconwebp, chuyinshanicon],
  kantoushan: [kantoushaniconwebp, kantoushanicon],
  zhongniuhushan: [zhongniuhushaniconwebp, zhongniuhushanicon],
  quezishan: [quezishaniconwebp, quezishanicon],
  lidingshan: [lidingshaniconwebp, lidingshanicon],
  guanyinshan: [guanyinshaniconwebp, guanyinshanicon],
  yuemeishan: [yuemeishaniconwebp, yuemeishanicon],
  huoyanshan: [huoyanshaniconwebp, huoyanshanicon],
  liyushan: [liyushaniconwebp, liyushanicon],
  jilongshan: [jilongshaniconwebp, jilongshanicon],
  wuerchahushan: [wuerchahushaniconwebp, wuerchahushanicon],
  hongtoushan: [hongtoushaniconwebp, hongtoushanicon],
  hengshan: [hengshaniconwebp, hengshanicon],
  tianshangshan: [tianshangshaniconwebp, tianshangshanicon],
  feifengshan: [feifengshaniconwebp, feifengshanicon],
  shoushan: [shoushaniconwebp, shoushanicon],
  huoshaoshan: [huoshaoshaniconwebp, huoshaoshanicon],
  taiwushan: [taiwushaniconwebp, taiwushanicon],
  yuntaishan: [yuntaishaniconwebp, yuntaishanicon],
  dawulunshan: [dawulunshaniconwebp, dawulunshanicon],
  hongdanshan: [hongdanshaniconwebp, hongdanshanicon],
  luohanshan: [luohanshaniconwebp, luohanshanicon],
  xiangshan: [xiangshaniconwebp, xiangshanicon],
  luoshan: [luoshaniconwebp, luoshanicon],
  kuibishan: [kuibishaniconwebp, kuibishanicon],
}
