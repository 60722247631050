import React, { useState, useEffect, useMemo, useRef } from 'react'
import { GrSearch } from 'react-icons/gr';
import { useFormikContext } from 'formik'
import throttle from 'lodash/throttle'

import useFilterValue from './useFilterValue'

import Input from '../components/Input';
import Button from '../components/Button';

const Search = ({ isMobile, ...props }) => {
  const [search, handleSearch] = useState()
  const { values, setFieldValue } = useFormikContext()
  const { isFilter } = useFilterValue()
  const [value, setValue] = useState(values.search)
  const prevValue = useRef()

  const sendValueToFormik = throttle(() => {
    setFieldValue('search', value)
  }, 750)

  useEffect(() => {
    if (prevValue.current !== value) {
      sendValueToFormik()
    }
    prevValue.current = value
  }, [value, sendValueToFormik])

  return useMemo(() => isFilter || search ? (
    <Input.Search
      name="search"
      defaultValue={value}
      autoFocus
      isFilter={isFilter}
      onBlur={(e) => {
        e.persist()
        setTimeout(() => handleSearch(e.target.value ? true : false))
      }}
      onChange={(e) => setValue(e.target.value)}
      placeholder="輸入關鍵字"
      {...props}
    />
  ) : (
    <Button.Transparent
      mx="0.5em"
      leftIcon={GrSearch}
      onClick={() => handleSearch(!search)}
      mt={isMobile && '0.25em'}
    >
      {!isMobile && '搜尋'}
    </Button.Transparent>
  ), [isFilter, search, isMobile])
}

export default Search
