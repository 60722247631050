import React from 'react';

import { flexbox } from 'styled-system';
import styled from 'styled-components';
import { forwardRef, createElement } from 'react'

import Box from './Box';
import { responsive } from './ThemeProvider/theme';

import asForward from './utils/asForward'

const Flex = styled(Box)`
  ${flexbox}
`;

Flex.defaultProps = {
  display: 'flex',
};

Flex.displayName = 'Flex';

const ForwardedFlex = forwardRef((props, ref) => createElement(asForward(Flex), { ...props, ref }));

ForwardedFlex.Responsive = (props) => <Flex flexDirection={responsive('column', 'row')} {...props} />

export default ForwardedFlex
