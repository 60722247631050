import React, { useState } from 'react'
import useFetch from 'use-http'

import Input from '../../components/Input'
import Box from '../../components/Box'
import Button from '../../components/Button'

const Subscribe = () => {
  const { post } = useFetch('https://us-central1-re-drive-75970.cloudfunctions.net/app/mountains/subscribe')
  const [value, setEmail] = useState()
  return (
    <>
      <Box mt="5em" width="80%" mx="auto">
        <Input value={value} onChange={(e) => setEmail(e.target.value)} autoFocus label="E-mail" name="email" />
      </Box>
      <Box textAlign="center" mt="1em">
        <Button.Transparent onClick={() => {
          post({ email: value })
          .then(() => alert('訂閱成功'))
        }}>送出</Button.Transparent>
      </Box>
    </>
  )
}

export default Subscribe
