import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components';
import ReactResizeDetector from 'react-resize-detector';
import get from 'lodash/get'

import Container from '../../components/Container'
import Box from '../../components/Box'
import Flex from '../../components/Flex'
import Link from '../../components/Link'
import Image from '../../components/Image'
import BackgroundImage from '../../components/BackgroundImage'
import Button from '../../components/Button'
import Circle from '../../components/Circle'
import Text from '../../components/Text'
import Carousel from '../../components/Carousel'
import TitleWithBg from '../../components/TitleWithBg'

import { responsive } from '../../components/ThemeProvider/theme';

import useResponsive from '../../contexts/mediaQuery/useResponsive'

import WhiteBox from './WhiteBox'
import ModuleAnimal from './ModuleAnimal'
import Normal from './Normal'
import Protect from './Protect'
import Special from './Special'
import Unique from './Unique'
import Taiwan from './Taiwan'
import Form from './Form'
import hikingbook from './hikingbook.png'
import mountains from './mountains.png'
// import teams from './teams.png'
// import footer from './footer.png'
import mountainswebp from './mountains.webp'
// import teamswebp from './teams.webp'
// import footerwebp from './footer.webp'
import hikingbookwebp from './hikingbook.webp'

import { front } from '../photo'

import useTsv from './useTsv'
import dataTsv from './data.tsv'

const legend = [
  {
    Icon: Normal,
    text: '常見種'
  },
  {
    Icon: Protect,
    text: '保育類'
  },
  {
    Icon: Special,
    text: '台灣特有亞種'
  },
  {
    Icon: Unique,
    text: '台灣特有種'
  },
]

const types = [
  '哺乳類', '鳥類', '爬蟲類', '兩棲類', '昆蟲類', '季節性活動'
]
const season = ['春', '夏', '秋', '冬']
const application = ['入園證', '入山證', '山莊/山屋（依過夜需求斟酌）']
const buttonText = ['主題文章', '探索更多的山']

// const footerContent = [
//   [teamswebp, teams],
//   [footerwebp, footer]
// ]

const percent = (number) => `${(number * 100)}%`

const height = {
  high: { top: percent(63 / 202), bottom: percent(112 / 202)},
  middle: { top: percent(114 / 202), bottom: percent(62 / 202)},
  low: { top: percent(163 / 202), bottom: percent(13 / 202)},
}

const journal = {
  one: { left: percent(148 / 522), right: percent(278 / 522)},
  two: { left: percent(278 / 522), right: percent(148 / 522)},
  overTwo: { left: percent(408 / 522), right: percent(19 / 522)},
  ot: { left: percent(148 / 522), right: percent(148 / 522)},
  tt: { left: percent(278 / 522), right: percent(19 / 522)},
  ott: { left: percent(148 / 522), right: percent(19 / 522)},
}

const StyledLink = styled.a`
  color: currentColor;
`
const Title = ({ title, desc, props }) => (
  <Box mt={responsive('4.5em', '9.375em')} mb={responsive('2.25em', '4.875em')} textAlign="center" {...props}>
    <Text lineHeight="1" fontSize={responsive('1.5em', '3em')} fontWeight="900">{title}</Text>
    <Text mt="1.5rem" fontSize={responsive('1em', '1.375em')}>{desc}</Text>
  </Box>
)

const Topics = ({ mountainName, match, closeModal }) => {
  const { isMobile } = useResponsive()
  const { data, loading } = useTsv(dataTsv)
  if (!Boolean(data.length)) return null
  if (loading) return (
    <Box color="black">Loading...</Box>
  )
  const id = get(match, ['params', 'id'], mountainName)
  const d = data.filter(ddd => ddd.en === id)[0]
  // console.log(dd, id)
  return (
    <Box bg={d.bg} color="white" py="3em" fontWeight="bold">
      <Box width={responsive(1, 9 / 10)} mx="auto">
        <BackgroundImage ratio={isMobile ? 569 / 640 : 830 / 1920} src={d.cover[Number(isMobile)]} />
      </Box>
      <Container>
        <Title title="這座山『最...』的模樣" desc="人有各種樣貌與個性，山也一樣，有各種不同『山模山樣』。" />
        <Flex.Responsive>
          {d.adj.map(({ title, desc }, index) => (
            <WhiteBox
              mt="2em"
              mx={responsive(0, '1em')}
              width={responsive(1, index ? 1 / 3 : 2 / 3)}
              color={d.color}
              key={index} >
              <Flex.Responsive>
                {!index && (
                  <Box width={responsive(1, 1 / 2)} borderRadius="0.5em" overflow="hidden" mr="1.5em">
                    <Image src={front[id || mountainName]} />
                  </Box>
                )}
                <Box flex={1} pt="1em">
                  <Circle width="1.5em" fontWeight="bold" bg={d.color} color="white" mx="auto">{index + 1}</Circle>
                  <Text textAlign="center" mt="0.25rem" fontSize="1.5em" fontWeight="bold">{`最『${title}』的山`}</Text>
                  <Text mt="1rem">{desc}</Text>
                </Box>
              </Flex.Responsive>
            </WhiteBox>
          ))}
        </Flex.Responsive>
        <Title title="歷史與文化" desc="關於這座山，曾上演過這些有趣的故事與傳說?" />
        <Flex.Responsive>
          {d.history.map((array, i) => (
            <WhiteBox
              color={d.color}
              width={responsive(1, 1 / 2)}
              textAlign="center"
              mx={responsive(0, '1em')}
              mt="2em"
              key={i}
            >
              <TitleWithBg bg={d.color} px="2em">
                {i ? '奇聞軼事' : '山名由來'}
              </TitleWithBg>
              {array.map(({ text, ...props }, k) => (
                <Text whiteSpace="pre-wrap" textAlign="justify" mt="2em" key={k} {...props}>{text}</Text>
              ))}
            </WhiteBox>
          ))}
        </Flex.Responsive>
        <Title title="山裡的居民" desc="山就像一棟公寓，不同樓層與客房裡住著各式各樣的居民，快來看看他們是誰吧！?" />
        <WhiteBox overflow={responsive('scroll', 'auto')} mt="2em">
          <Box width={responsive('800px', 'auto')}>
            <Image src={d.plant} />
          </Box>
        </WhiteBox>
        {isMobile && <Text textAlign="center">備註：往右滑可以瀏覽資訊圖全貌喔！</Text>}
        <WhiteBox my="2em">
          <Flex.Responsive
            justifyContent="space-between"
            alignItems="center"
          >
            <TitleWithBg
              px="1em"
              mr={responsive(0, '8em')}
              py="0.25em"
              fontSize="1em"
              bg={d.color}
            >圖例說明
            </TitleWithBg>
            <Flex flexWrap="wrap" flex={1} color={d.color}>
              {legend.map(({ Icon, text }, index) => (
                <Flex mt={responsive('0.5em', 0)} alignItems="center" width={responsive(1 / 2, 1 / legend.length)} key={index}>
                  <Icon width="2em" />
                  <Box ml="0.5em" fontWeight="bold">{text}</Box>
                </Flex>
              ))}
            </Flex>
          </Flex.Responsive>
        </WhiteBox>
        <Box>
          {d.organism.map((life, i) => (
            <Flex.Responsive key={i}>
              {life.filter(t => Boolean(t)).map(({ img, animals, animalName, text, active }, k, { length }) => (
                <ModuleAnimal
                  width={responsive(1, 1 / 2, 1 / length)}
                  title={Boolean(i) ? types[k + 3] : types[k]}
                  img={img}
                  text={text}
                  color={d.color}
                  length={length}
                  active={active}
                  animals={animals}
                  animalName={animalName}
                  index={k}
                  key={k}
                />
              ))}
            </Flex.Responsive>
          ))}
        </Box>
        <Title title="走進山裡" desc="迫不及待要實地探訪山了嗎？出發前，還有什麼該準備的？" />
        <Flex.Responsive mt="2em">
          <WhiteBox color={d.color} textAlign="center">
            <TitleWithBg bg={d.color}>所在縣市</TitleWithBg>
            <Taiwan city={d.city} bgColor={d.bg} width="75%" />
            <Flex justifyContent="space-around">
              {d.city.map((city, i) => (
                <Text fontWeight="bold" fontSize={responsive('1em', '1.25em')} key={i}>● {city}</Text>
              ))}
            </Flex>
          </WhiteBox>
          <WhiteBox mt={responsive('2em', 0)} ml={responsive(0, '2em')} pb={responsive('1.5em', '3em')} p={responsive('1em', '1.375em')} flex={1} textAlign="center" color={d.color}>
            <TitleWithBg bg={d.color}>登山步道等級</TitleWithBg>
            <Text my="0.5em">登山步道等級除了是衡量體力是否能負荷的標準，也是攜帶裝備的依據喔！</Text>
            <ReactResizeDetector handleWidth handleHeight>
              {({ width }) => (
                <Box position="relative">
                  <Form />
                  <TitleWithBg
                    position="absolute"
                    px="0"
                    top={height[d.height].top}
                    bottom={height[d.height].bottom}
                    left={journal[d.journal].left}
                    right={journal[d.journal].right}
                    bg={d.color}
                    lineHeight="0"
                    fontSize={['0.75em', null, null, null, `${(width / 40)}px`, `${(width / 36)}px`]}
                  >
                    <Box
                      position="absolute"
                      width="100%"
                      top="50%"
                      left="50%"
                      transform="translate(-50%, -50%)"
                    >常見行程</Box>
                  </TitleWithBg>
                </Box>
              )}
            </ReactResizeDetector>
            <Flex.Responsive alignItems="center" mt="2em">
              <TitleWithBg bg={d.color} mr={responsive(0, '2em')} fontSize="1em">入山季節</TitleWithBg>
              <Flex flex={1} mt={responsive('0.5em', 0)}>
                {season.map((s, k) => (
                  <Flex mr="0.5em" mt="0.5em" alignItems="center" fontWeight="bold" key={k}>
                    <Circle
                      bg={d.season[k] && d.bg}
                      color={d.season[k] ? 'white' : d.bg}
                      fontSize="1.5em"
                      border="3px solid"
                      borderColor={d.bg}
                      width="3rem"
                    >{s}</Circle>
                    {!isMobile && !d.season[k] && (
                      <Box flex={1} textAlign="left" ml="0.25em" color={d.bg}>
                        <TitleWithBg fontSize="1em" border="3px solid" p="0.25em" bg="white" color={d.bg}>!注意</TitleWithBg>
                        <Text>{d.note}</Text>
                      </Box>
                    )}
                  </Flex>
                ))}
              </Flex>
              {isMobile && d.note && (
                <Flex alignItems="center" color={d.bg} mt="0.5em">
                  <TitleWithBg fontSize="1em" border="3px solid" p="0.25em" bg="white" color={d.bg}>!注意</TitleWithBg>
                  <Box flex={1} textAlign="left" ml="0.25em">{d.note}</Box>
                </Flex>
              )}
            </Flex.Responsive>
            <Flex.Responsive alignItems="center" mt="1em">
              <TitleWithBg bg={d.color} fontSize="1em" mr={responsive(0, '2em')}>事前申請</TitleWithBg>
              <Flex flexWrap="wrap" position="relative" mt={responsive('0.5em', 0)} fontWeight="bold" flex={1}>
                {application.map((a, k) => (
                  <Flex alignItems="center" mr="0.5em" key={k}>
                    <Circle
                      bg={d.application[k] && d.bg}
                      color={d.bg}
                      border="2px solid"
                      width="0.75em"
                    />
                    <Text ml="0.25rem" fontSize={responsive('1em', '1.125em')}>{a}</Text>
                  </Flex>
                ))}
                <Box textAlign="left" position={responsive('relative', 'absolute')} fontSize={responsive('1em', '1.125em')} top="105%" left="0">
                  出發前記得再確認一次：<StyledLink target="_blank" href="https://mountain.cpami.gov.tw/">台灣登山申請整合資訊網</StyledLink>
                </Box>
              </Flex>
            </Flex.Responsive>
          </WhiteBox>
        </Flex.Responsive>
        <WhiteBox height={responsive('800px', 'auto')} overflow={responsive('scroll', 'auto')} mt="2.5em">
          <Box width={responsive('800px', 'auto')}>
            <Image src={d.packageImg} />
          </Box>
        </WhiteBox>
        <Text mt="1em">備註：裝備部分本網站是依照當天來回、兩天一夜、三天以上，三項分類所製作，因此若確認要出發去認識這座山後，針對裝備準備還是要針對各座山的高度、外部環境(如季節、天氣影響...)等等條件做更詳細的確認喔！</Text>
        {d.picture.filter(content => Boolean(content)).length > 0 && (
          <>
            <Title title="山景實拍" desc="來自山友們的寫真傳送門，送給你最新鮮的山林美景。" />
            <Carousel autoplay>
              {d.picture.filter(content => Boolean(content)).map(({ src, text }, i) => (
                <Box position="relative" key={i}>
                  <Image src={src} />
                  <Box
                    position="absolute"
                    top="0"
                    bottom="0"
                    right="0"
                    left="0"
                    background="linear-gradient(rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 20%)"
                  />
                  <Box position="absolute" width={responsive('90%', 'auto')} left="50%" top="1em" transform="translateX(-50%)">
                    {text}
                  </Box>
                </Box>
              ))}
            </Carousel>
            <Text textAlign="center">照片提供協作：Hikingbook</Text>
          </>
        )}
        <Box textAlign="center" mt="7.25em">
          <Text fontWeight="bold" fontSize="1.5em">給自己一個親近山的理由</Text>
          <Box fontSize="1.125em" mt="2.25em">
            <Text lineHeight="1.75">
              看到這裡，你對這座山開啟了什麼想像呢？<br />
              快走進山裡，看看你的想像是否貼近山的模樣！
            </Text>
            <Box my="2.625em">
              <Text lineHeight="1.75">
                還想探索其他的山嗎？<br />
                更多不同個性的山等著你喔！
              </Text>
            </Box>
            <Text lineHeight="1.75">
              ＊若不知道如何安排行程，可以參考Hikingbook整理的步道頁面，<br />
              裡面提供了山友的經驗、交通資訊和景點等介紹。
            </Text>
          </Box>
        </Box>
        <Flex.Responsive justifyContent="center" alignItems="center" mt={responsive('2.5em', '4.25em')}>
          {buttonText.map((text, k) => (
            <Box mx="1em" mt="2em" key={k}>
              <Button.White
                width="18rem"
                py={k ? '1em' : 0}
                px="1em"
                fontSize="1.25em"
                color={d.color}
                hoverBg={d.color}
                as={k ? RouterLink : Link}
                href={!k && d.trail}
                to={k && '/'}
                onClick={closeModal}
              >
                <Flex alignItems="center" justifyContent="center">
                  {!k &&
                    <Box width="3em">
                      <Image src={[hikingbookwebp, hikingbook]} />
                    </Box>
                  }
                  <Text lineHeight="1.2">
                    {!k && d.name}{!k && d.name.length > 5 && <br />}{text}
                  </Text>
                </Flex>
              </Button.White>
            </Box>
          ))}
        </Flex.Responsive>
      </Container>
      <Box my={responsive('4.375em', '7em')}>
        <Image src={[mountainswebp, mountains]} />
      </Box>
      <Box textAlign="center">
        <Button.White as={RouterLink} color={d.color} hoverBg={d.color} fontSize="1.5em" to="/about">了解山模山樣故事</Button.White>
      </Box>
      <Container textAlign="center" mt="4.375em">
        <Text>
          動、植物資料挑選與校正 / 協助生物資料蒐集：中研院生多中心研究助理 謝宗佑<br />
          登山裝備資料校正：米亞桑戶外中心<br />
          資訊模板協力協作：2020 Summer School 腦內冒險學員<br />
          橘子關懷基金會<br />
          Re-lab設計團隊<br />
        </Text>
        {/* <Flex mt="2em" alignItems="center" justifyContent="center">
          {footerContent.map((src, i) => (
            <Box width={responsive('12.5em', '16em')} px="1em" key={i}>
              <Image src={src} />
            </Box>
          ))}
        </Flex> */}
      </Container>
    </Box>
  )
}

export default Topics
