import React, { useMemo } from 'react';
import Select from 'react-select'
import styled from 'styled-components';
import { BsFillCaretDownFill } from 'react-icons/bs';
import themeGet from '@styled-system/theme-get'
import { Field, FastField } from 'formik'
import { useFormikContext } from 'formik'

import { responsive } from './ThemeProvider/theme';
import useResponsive from '../contexts/mediaQuery/useResponsive'
import Box from './Box'

const padding = '0.5em'

const StyledDD = styled(Select)`
  font-size: 1em;
  .react-select__value-container {
    padding: 0;
  }

  .css-1uccc91-singleValue {
    left: 40%;
    transform: translate(-50%, -50%);
    color: white;
  }

  .react-select__control {
    border: 1px solid;
    border-color: ${themeGet('colors.gray')};
    border-radius: 0.25em;
    outline: none;
    background-color: ${props => props.value && themeGet('colors.cyan')(props)};

    .react-select__placeholder {
      color: ${themeGet('colors.darkGray')};
      font-size: 1rem;
      top: 50%;
      left: 0.5em;
      transform: translateY(-50%);
      margin: 0 0.5em;
    }
    .react-select__dropdown-indicator {
      display: none;
    }
    &:hover {
      color: ${themeGet('colors.cyan')};
    }
    &.react-select__control--menu-is-open {
      background-color: white;
      color: black;
      .react-select__placeholder  {
        color: white;
      }
      .react-select__single-value {
        color: white;
      }
      .react-select__dropdown-indicator {
        color: white;
      }
    }
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__menu {
    margin: 0;
    border-radius: 0;
    border: 1px solid ${themeGet('colors.gray')};
    boxshadow: 1px 3px 2px ${themeGet('colors.gray')};
  }

  .react-select__menuList {
    padding: 0;
  }

  .react-select__option {
    color: black;
    text-align: center;
    padding: 0.5em;
    border-bottom: none;
    &.react-select__option--is-focused,
    &.react-select__option--is-selected {
      color: ${themeGet('colors.cyan')};
      background-color: white;
    }
  }
`

const StyledSelect = styled(Box)`
  font-size: 1em;
  line-height: 1.25;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  appearance: none;
  border: none;
  outline: none;
  background-color: ${props => props.active && themeGet('colors.cyan')};
  color: ${props => props.active && 'white'};
`;

const Arrow = ({ selectedOption, active }) => (
  <Box
    position="absolute"
    color={active || selectedOption ? 'white' : 'black'}
    lineHeight="0"
    right="0.5em"
    top="50%"
    transform="translateY(-50%)"
    pointerEvents="none"
    fontSize={responsive('0.75em', '1em')}
  >
    <BsFillCaretDownFill size="1.25rem" />
  </Box>
)

const DD = ({
  options,
  name,
  onChange,
  onBlur,
  value,
  number,
  placeholder,
  disabled,
  readOnly,
  preEle,
  thin,
  zIndex,
  defaultInputValue,
  error,
  isSearchable,
  ...props
}) => {
  const { values } = useFormikContext()
  const d = disabled || options.length === 0
  const goodOptions = useMemo(() => {
    if (typeof options[0] === 'string' || typeof options[0] === 'number') {
      return options.map(opt => ({ label: opt, value: opt }))
    }
    return options
  }, [options])
  const selectedOption = useMemo(() => {
    return goodOptions.find((opt) => opt.value == value) || null
  }, [value, goodOptions])
  const { isMobile } = useResponsive()
  return (
    <Box {...props}>
      {isMobile ? (
        <Box
          border="1px solid"
          borderColor="gray"
          bg={values.sort ? 'cyan' : 'white'}
          color={values.sort ? 'white' : 'black'}
          borderRadius="0.25em"
          px="0.75em"
          position="relative"
          {...props}
        >
          <StyledSelect
            as="select"
            width="100%"
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            py={padding}
            px={padding}
            active={values.sort}
            isDisabled={d}
          >
            {/* {placeholder && <option value="_">{placeholder}</option>} */}
            {goodOptions.map((option) => {
              const v = option.value
              const l = option.label
              return (
                <option key={v} value={v}>
                  {l}
                </option>
              )}
            )}
          </StyledSelect>
          <Arrow active={values.sort} />
        </Box>
      ) : (
        <Box position="relative" zIndex={zIndex}>
          <StyledDD
            placeholder={placeholder}
            onChange={(option) => onChange({
              target: {
                value: option.value,
                name,
              },
              persist: () => {},
            })}
            value={selectedOption}
            options={goodOptions}
            isDisabled={d}
            isSearchable={isSearchable}
            classNamePrefix="react-select"
            defaultInputValue={defaultInputValue}
          />
          <Arrow selectedOption={selectedOption} />
        </Box>
      )}
    </Box>
  );
}

DD.defaultProps = {
  onChange: () => {},
  placeholder: '---',
}

DD.Field = ({ name, ...props }) => (
  <Field name={name}>
    {({ field, meta }) => (
      <DD
        {...props}
        {...field}
        error={meta.error}
      />
    )}
  </Field>
)

DD.FastField = ({ name, ...props }) => (
  <FastField name={name}>
    {({ field, meta }) => (
      <DD
        {...props}
        {...field}
        error={meta.error}
      />
    )}
  </FastField>
)

export default DD
