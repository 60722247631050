import React from 'react'
// import { Link } from 'react-router-dom';

import Container from '../../components/Container'
import Link from '../../components/Link'
import Text from '../../components/Text'
import Box from '../../components/Box'
import Image from '../../components/Image'
import Button from '../../components/Button'

import guide1 from './guide_1.png'
import guide2 from './guide_2.png'
import guide3 from './guide_3.png'
import guide4 from './guide_4.png'
import guide5 from './guide_5.png'
import guide6 from './guide_6.png'
import guide7 from './guide_7.png'
import guide8 from './guide_8.png'

import guide1webp from './guide_1.webp'
import guide2webp from './guide_2.webp'
import guide3webp from './guide_3.webp'
import guide4webp from './guide_4.webp'
import guide5webp from './guide_5.webp'
import guide6webp from './guide_6.webp'
import guide7webp from './guide_7.webp'
import guide8webp from './guide_8.webp'

const content = [
  {
    text: '點進網站時，你是否有看見五顏六色的山呢？\n這些顏色各自有代表的意義哦！\n我們將台灣的山分成5個級距，以5種主要顏色初步區分不同個性的他們。',
    src: [guide1webp, guide1]
  },
  {
    text: '接著，進到山的頁面中，會先看到該座山實際的樣貌、名稱與海拔高度，讓你對他有個基本的認識。',
    src: [guide2webp, guide2]
  },
  {
    text: '認識一個人的時候，我們通常會在心中給一些形容詞：「溫柔的」、「細心的」、「難相處的」，藉以讓這個人在我們心中留下更深的印象；而我們想像山也是如此，每座山都擁有他最『⋯⋯』的模樣。',
    src: [guide3webp, guide3]
  },
  {
    text: '在有了基本的印象後，我們可能會對一個人的身高、生日或某些特徵產生興趣，進而去了解背後的故事；對於山，我們了解了他的基本資料後，進而挖掘背後關於歷史與文化的故事，如名稱的由來與當地曾發生的奇聞軼事等。',
    src: [guide4webp, guide4]
  },
  {
    text: '了解一個人，了解他的交友圈很重要；而了解一座山，了解整個生態系也很重要！\n環境我們大致上瞭解了，因此這個部分主要分成：植物與動物。\n植物：這個部分結合山的剖面高度，標注出不同海拔區間有哪些常見的植物。',
    src: [guide5webp, guide5]
  },
  {
    text: '動物：分為哺乳類、鳥類、爬蟲類、兩棲類與昆蟲類等五大類，又每一類都可以區分為兩個部分——',
    lists: [
      '上方：搭配圖例說明的是該座山中會出現的動物，分為常見種、特有種、特有亞種或保育類等。',
      '下方：級距的代表動物，因此在同一個級距（如1000-2000m）的模板中都會出現同一種生物，不一定會和上方會見到的動物重複哦！',
    ],
    src: [guide6webp, guide6]
  },
  {
    text: '了解了一座山的全部後，就可以計畫是否要實際走入山中，去看看這些故事和動物們是不是真的如你想像中的樣子；另外，我們也彙整地理位置、平均爬行時間、適合爬行的季節與建議準備的申請與裝備。',
    lists: [
      '事前申請：透過「實心」與「空心」的呈現，協助山友初步了解是否需要提前準備資料，詳細資訊也可以上內政部的「臺灣登山申請整合資訊網」再次確認哦！',
      '裝備：我們是依照「在山上的平均停留時間」而去整理的建議，實際上需要攜帶的裝備仍須看造訪的天氣、個人的身體狀況及需求而定哦！',
    ],
    note: '若是準備好要出發了，事前準備、行程與爬行時間、氣候及裝備等等，都需要再次確認資訊，此處建議僅供參考哦！',
    src: [guide7webp, guide7]
  },
  {
    text: '最後，欣賞一下山友拍的美照，也歡迎將你拍的美照投稿給我們，讓更多人看見台灣山林之美！',
    src: [guide8webp, guide8]
  },
]

const Guide = () => {
  return (
    <Container maxWidth="800px">
      <Text.H1>「用想像力，看見每座山的不同！」</Text.H1>
      <Box>
        {content.map(({ text, src, lists, note }, i) => (
          <Box key={i}>
            <Text.Article>{text}</Text.Article>
            <Box as="ul">
              {lists && lists.map((list, k) => (
                <Box as="li" mt="1em" key={k}>{list}</Box>
              ))}
            </Box>
            {note && <Text.Article>{note}</Text.Article>}
            <Box my="2em">
              <Image src={src} />
            </Box>
          </Box>
        ))}
      </Box>
      <Text>聯繫我們可以透過下列的方式：</Text>
      <Box>
        <Link color="black" href="https://www.facebook.com/mountainlike.relab">▌Facebook</Link>
      </Box>
      <Box>
        <Link color="black" href="https://www.instagram.com/mountainlike/">▌Instagram</Link>
      </Box>
      <Text.Article>
        經過這樣的介紹之後，是否有幫助到你更了解網站的架構呢？<br />
        快快挑選一座你最感興趣的山，好好認識他吧！
      </Text.Article>
      <Box textAlign="center">
        <Button.White
          width="18rem"
          py="1em"
          px="1em"
          fontSize="1.25em"
          color="black"
          hoverBg="green"
          as={Link}
          to="/"
        >
          探索更多的山
        </Button.White>
      </Box>
    </Container>
  )
}

export default Guide
