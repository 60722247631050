import React, { useMemo } from 'react'
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { background } from 'styled-system';
import isArray from 'lodash/isArray'
import get from 'lodash/get'

import Box from './Box';
import asForward from './utils/asForward'

const BackgroundImg = styled(Box)`
  ${background}
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  ${(props) => props.height ? '' : `padding-top: ${props.ratio * 100}%;`}
`;

const BackgroundImage = ({ src, ...props }) => {
  const canUseWebp = get(window, 'Modernizr.webp')

  const pic = useMemo(() => {
    if (!isArray(src)) return null
    return canUseWebp ? src[0] : src[1];
  }, [canUseWebp, src])

  return <BackgroundImg src={isArray(src) ? pic : src} {...props} />
}

BackgroundImage.propTypes = {
  backgroundSize: PropTypes.string,
  backgroundPosition: PropTypes.string,
};

BackgroundImage.defaultProps = {
  position: 'relative',
  ratio: 1,
  backgroundSize: 'cover',
  backgroundPosition: '50% 50%',
};

BackgroundImage.displayName = 'BackgroundImage';

export default asForward(BackgroundImage);
