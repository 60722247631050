import React from 'react';
import styled, { css } from 'styled-components';
import {
  typography,
  space,
  color,
  layout,
  border,
  borderRadius,
  position,
} from 'styled-system';
import themeGet from '@styled-system/theme-get';
import shouldForwardProp from '@styled-system/should-forward-prop'

import Box from './Box';

import { customColor } from './utils/getColor';
import isToAs from './utils/isToAs'

const active = css`
  ${customColor('hoverColor')};
  ${customColor('hoverBg', 'backgroundColor')};
  ${customColor('hoverBorder', 'borderColor')};
`;

const ButtonBase = styled.button.withConfig({ shouldForwardProp })`
  padding: 0;
  font-family: inherit;
  text-decoration: none;
  ${typography}
  ${layout}
  ${position}
  ${space}
  ${color}
  ${border}
  ${borderRadius}
  appearance: none;
  transition: all ${themeGet('duration', 250)}ms;
  cursor: pointer;
  &:hover,
  &:focus {
    ${props => !props.disabled && active}
    outline: none;
  }
  ${props => props.active && !props.disabled && active}
  ${props =>
    props.disabled &&
    `
    cursor: not-allowed;
    opacity: 0.5;
  `}
`;

const InButtonSpan = props => <Box as="span" {...props} />;

const Button = isToAs(({
  leftIcon,
  rightIcon,
  iconSpacing,
  iconsSize,
  children,
  verticalAlign,
  lineHeight,
  ...props
}) => (
  <ButtonBase {...props}>
    {leftIcon ? (
      <Box lineHeight={lineHeight} as={leftIcon} mr={iconSpacing} verticalAlign={verticalAlign} fontSize={iconsSize} />
    ) : null}
    <InButtonSpan lineHeight={lineHeight}>{children}</InButtonSpan>
    {rightIcon ? (
      <Box lineHeight={lineHeight} as={rightIcon} ml={iconSpacing} verticalAlign={verticalAlign} fontSize={iconsSize} />
    ) : null}
  </ButtonBase>
));

const RotateButton = styled(Button)`
  position: relative;
  &:hover {
    transform: ${props => props.active ? 'rotateY(0deg)' : 'rotateY(180deg)'};
    ::before {
      position: absolute;
      content: '翻牌';
      width: 3em;
      color: ${themeGet('colors.cyan')};
      left: 100%;
      top: 50%;
      transform: translateY(-50%) rotateY(180deg);
      pointet-events: none;
    }
  }
  &:focus {
    ${props => !props.disabled && active}
    outline: none;
  }
`

Button.defaultProps = {
  border: '2px solid',
  borderColor: 'primary',
  bg: 'primary',
  lineHeight: '1.25rem',
  color: 'white',
  hoverColor: 'white',
  hoverBg: 'primaryHover',
  hoverBorder: 'primaryHover',
  px: '1.5em',
  py: '0.75em',
  fontWeight: 'bold',
  fontSize: '1em',
  iconSpacing: '0.125em',
  borderRadius: '0.25em',
  display: 'inline-block',
  verticalAlign: 'sub',
  iconsSize: '1.25em',
  type: 'button',
};

Button.displayName = 'Button';

Button.Danger = props => (
  <Button
    bg="danger"
    borderColor="danger"
    hoverBg="dangerHover"
    hoverBorder="dangerHover"
    {...props}
  />
);

Button.Secondary = props => (
  <Button
    bg="secondary"
    border="2px solid"
    borderColor="secondary"
    hoverBg="secondaryHover"
    hoverBorder="secondaryHover"
    {...props}
  />
);

Button.White = props => (
  <Button
    border="none"
    borderColor="transparent"
    hoverborder="transparent"
    bg="white"
    hoverColor="white"
    borderRadius="2em"
    {...props}
  />
);

Button.Danger = props => (
  <Button.Danger
    border="2px solid"
    borderColor="danger"
    bg="transparent"
    color="danger"
    hoverColor="white"
    {...props}
  />
);

Button.Transparent = props => (
  <Button
    fontWeight="normal"
    border="1px solid"
    borderColor="transparent"
    hoverBorder="transparent"
    bg="transparent"
    hoverBg="transparent"
    color="darkGray"
    hoverColor="black"
    px="0"
    {...props}
  />
);

Button.Circle = props => (
  <Button
    borderRadius="50%"
    {...props}
  />
);

Button.RotateButton = props => (
  <RotateButton
    fontWeight="normal"
    border="1px solid"
    borderColor="transparent"
    hoverBorder="transparent"
    bg="transparent"
    hoverBg="transparent"
    color="text"
    hoverColor="cyan"
    px="0"
    title="翻牌"
    {...props}
  />
);

export default Button;
