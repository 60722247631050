import React from 'react'
import SVG from '../../components/SVG'

const RotateIcon = ({ size, ...props }) =>  (
  <SVG viewBox="0 0 27.84 34" width="1.5em" {...props}>
    <g>
      <path fill="currentColor" d="M11.21,31.57c-.22,0-.44,0-.66-.07a1.2,1.2,0,1,0-.34,2.38L11,34h.09a1.2,1.2,0,0,0,.09-2.4Z"/>
      <path fill="currentColor" d="M3.36,26.25A1.2,1.2,0,1,0,1.21,27.3c.12.25.25.49.39.73a1.18,1.18,0,0,0,1,.6,1.19,1.19,0,0,0,1-1.8C3.56,26.64,3.46,26.45,3.36,26.25Z"/><path class="cls-1" d="M6.86,30.14c-.19-.12-.37-.24-.55-.38A1.2,1.2,0,0,0,4.89,31.7c.23.17.45.32.69.47a1.24,1.24,0,0,0,.64.18,1.2,1.2,0,0,0,.64-2.21Z"/>
      <path fill="currentColor" d="M16.34,31.6h-.75a1.2,1.2,0,1,0,0,2.4h.75a1.2,1.2,0,1,0,0-2.4Z"/>
      <path fill="currentColor" d="M21.21,31.6h-.4a1.2,1.2,0,1,0,0,2.4h.4a1.2,1.2,0,1,0,0-2.4Z"/>
      <path fill="currentColor" d="M26.64,0H6a1.2,1.2,0,0,0-1.2,1.2V12.5A11.93,11.93,0,0,0,0,22.07H0c0,.14,0,.29,0,.44a1.2,1.2,0,0,0,1.2,1.16h0a1.2,1.2,0,0,0,1.16-1.24c0-.12,0-.23,0-.35a9.47,9.47,0,0,1,2.43-6.34v6.08A1.2,1.2,0,0,0,6,23H26.64a1.2,1.2,0,0,0,1.2-1.2V1.2A1.2,1.2,0,0,0,26.64,0Zm-1.2,20.61H7.23V13.78a9.48,9.48,0,0,1,4.7-1.25h.71V16a.71.71,0,0,0,1.07.62l8-4.64a.71.71,0,0,0,0-1.23l-8-4.65a.71.71,0,0,0-1.07.62v3.44h-.71a11.86,11.86,0,0,0-4.7,1V2.4H25.44Z"/>
    </g>
  </SVG>
)

export default RotateIcon
