import React from 'react'
// import { Link } from 'react-router-dom';

import Box from '../../components/Box';
import Link from '../../components/Link';
import Text from '../../components/Text';

const training = [
  {
    title: '怎樣的強度叫做低？',
    text: '一個簡單的測量標準就是，用可以講話聊天的速度進行，不管是在跑步或是實際登山，當你開始喘到開始想用嘴巴呼吸，且無法完整說完一句話時，這樣的訓練速度便是太快。\n\n在這種低強度運動時，身體使用脂肪的比率是最高的，我們的身體不能儲存太多的肝醣，卻可儲存接近無限量的脂肪！因此在這種低強度下便可以拉長運動的持續時間。\n\n如果一開始出發衝得太快爬得太猛，造成肝醣燃燒殆盡，中間食物補充的又不足夠，後續下山便會無力，下山花得時間比上山還久，這個故事劇情我在嘉明湖山屋值班時幾乎周周都在上演！\n\n實際上用這種強度去跑步，你也許會發現速度簡直慢得不像話，這樣真得有訓練到嗎？\n我本身已經用這種強度加上空腹的狀況訓練兩年多了，平常帶職業團登山工作時，用這種行進速度幾乎可以一整天都不用吃上任何行進糧！但關於空腹這種訓練方法也許並不是適合每一個人的，有機會可以再來分享。'
  },
  {
    title: '心肺耐力的訓練建議：',
    text: '至少一周一次登階訓練搭配1-2次的跑步\n跑步以LSD (Long Slow Distance)為主，用前述的低強度從30分鐘開始，每周漸進增加拉長時間到1- 2小時左右，要再更長也是可以，但我們就是沒有時間登山才改成用跑步當訓練的啊！'
  },
  {
    title: '登階的初期目標為：',
    text: '從負重10%體重開始，至少上下300公尺。經過幾周之後可以慢慢增加強度，以每1-2周增加5%的體重負重或是100公尺的垂直距離皆可，一般來說負重上限以體重的三分之一為目標既可，最多不要超過二分之一，才不會對身體造成過大的負擔。如果擔心下坡對膝蓋的負擔，可以使用登山杖，或尋找有電梯的高樓，或用背水登山的方式進行，在登頂後把水倒掉輕裝下山等。'
  },
]

const basicTraining = [
  '深蹲、坐空氣椅或登階 – 可以訓練股四頭肌',
  '棒式 – 可以訓練核心、全身性的鍛鍊',
  '伏地挺身 – 可以訓練核心、全身性的鍛鍊',
  '閉眼單腳站 – 可以訓練下半身與本體感覺增加平衡感'
]

const PartIII = () => {
  return (
    <Box>
      <Text.Article>
        以登山為目標的心肺訓練有個重點，就是把訓練的時間拉長、強度降低較為有效。
      </Text.Article>
      <Box>
        {training.map(({ title, text }, i) => (
          <Box my="2em" key={i}>
            <Text fontWeight="bold">{title}</Text>
            <Text whiteSpace="pre-wrap">{text}</Text>
          </Box>
        ))}
      </Box>
      <Text.Article>【肌力訓練】</Text.Article>
      <Text.Article>
        肌力訓練是另一個常會忽略的項目，登山最重要的就是腿部的肌力，經過鍛鍊後可以增加身體的平衡能力，也更能對地形做出快速反應減少滑倒、扭傷的機率，與減輕膝蓋的負擔。手部的肌力能幫你更輕鬆、安全得通過困難地形，背負背包也需要核心背肌胸肌等上半身的肌肉來支撐與平衡。<br />
        登山不需要練出大塊的肌肉，只需要支撐自己體重加上背包的重量，因此並不需要上健身房，只要用幾個隨時都可以在家裡做的基本動作既可。
      </Text.Article>
      <Box>
        {basicTraining.map((text, i) => (
          <Text key={i}>{text}</Text>
        ))}
      </Box>
      <Text.Article>
        有器材的話可增加吊單槓，或是增加攀岩活動等訓練。尤其是攀岩，除了訓練上半身肌群可增加對過困難地形抓點時的信心外，也能訓練身體細微的重心轉換與平衡感，對過地形時的踩點與平衡極有幫助。
      </Text.Article>
      <Text.Article>
        肌力的訓練建議：
      </Text.Article>
      <Text.Article>
        一周兩次，每次循環做3組，每個動作10-30下或秒。當你可以輕鬆的超過30下(秒)時，就考慮增加負重或是動作的難度。
      </Text.Article>
      <Text.Article>
        如果目標只是在台灣非雪季登山健行，上述的建議足以幫助你在登山過程中更輕鬆、更順暢了，也能降低山難的風險。但如果目標是越野跑、雪季登山、阿爾卑斯式攀登或喜馬拉雅式的海外遠征，便是完全不同的故事了。
      </Text.Article>
    </Box>
  )
}

export default PartIII
