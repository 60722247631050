import React from 'react'

import Text from '../../components/Text';
import Box from '../../components/Box';
import Image from '../../components/Image';
import Container from '../../components/Container';

import PartI from './PartI'
import PartII from './PartII'
import PartIII from './PartIII'
import train1 from './train_1.png'
import train2 from './train_2.png'
import train3 from './train_3.png'
import train1webp from './train_1.webp'
import train2webp from './train_2.webp'
import train3webp from './train_3.webp'

const titles = [
  'I', 'II', 'III'
]

const part = [
  PartI,
  PartII,
  PartIII
]

const photos = [
  {
    src: [train1webp, train1],
    text: '想悠閒的步入山林，可從體能訓練與輕量化兩大途徑下手'
  },
  {
    src: [train2webp, train2],
    text: '跑步是一般人最常聯想到的訓練手段，但如果能加入上坡或實際上山才是最有效的訓練'
  },
  {
    src: [train3webp, train3],
    text: '此訓練建議是針對台灣一般非雪季登山，如果目標是雪地登山就需要更高強度的計畫。'
  },
]

const Training = ({ match: { params: { id } } }) => {
  const title = titles[id - 1]
  const photo = photos[id - 1]
  const Content = part[id - 1]
  return (
    <Container maxWidth="800px">
      <Text.H1>【給一般登山健行者的訓練建議 PART {title}】</Text.H1>
      <Text.H6>
        撰文 / 張國威，文章來源 / 橘子關懷基金會
      </Text.H6>
      <Box>
        <Image src={photo.src} />
        <Text>{photo.text} / 實拍圖來源：張國威</Text>
      </Box>
      <Content />
      <Text.H6 textAlign="left" mb="2rem">張國威 Kuo-Wei Chang</Text.H6>
      <Text.Article>大學參加登山社，畢業後就踏入戶外活動慘業不歸路已16年。專業戶外打雜工，只要跟戶外有關的工作都做過，登山嚮導、野外探勘協作、野外調查人員、背工、高山廚師、溯溪攀岩教練等。</Text.Article>
      <Text.Article>攀登經歷：四川雙橋溝冰攀、日本八岳地區冰雪攀，法國霞慕尼、瑞士，義大利、加拿大洛磯山脈等地登山。攀爬過北美最高峰迪奈利Mt.Denali、雷尼爾山 Mt. Rainier(美國太平洋西北最高峰) 、瑞士馬特洪 Matterhorn、法國白朗峰 Mont Blanc(西歐最高峰)、加拿大 Mt. Athabasca和日本赤岳等世界百岳。</Text.Article>
    </Container>
  )
}

export default Training
