import React from 'react'

import SVG from '../../components/SVG'

const Form = (props) => (
  <SVG viewBox="0 0 522 202" {...props}>
    <g id="text">
      <polygon points="1 1 1 51 131 51 1 1" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
      <path d="M173.16,19v4.09H171V20.84H159.52v2.27h-2V19h2.37a13.14,13.14,0,0,0-1-1.55l1.91-.75a11.33,11.33,0,0,1,1.43,2.16l-.33.14h2.23V16.57h2.15V19h2.12l-.11,0a13.4,13.4,0,0,0,1.28-2.31l2.27.67c-.43.61-.86,1.19-1.26,1.67Zm-14.35,7.57h13.11v6.82h-2.14V33h-8.93v.43h-2Zm11.24-4.88v4.12h-9.53V21.71Zm-9.2,6.42v.94h3.35v-.94Zm3.35,3.32v-.94h-3.35v.94Zm3.72-8.21h-5.4V24.3h5.4Zm1.86,4.89h-3.51v.94h3.51Zm0,3.32v-.94h-3.51v.94Z" fill="currentColor" />
      <path d="M186.37,25.38a10.17,10.17,0,0,0,7.07,5.89,7.44,7.44,0,0,0-1.47,2,11.52,11.52,0,0,1-7.08-6.13c-1,2.4-3,4.67-7.07,6.18a8.45,8.45,0,0,0-1.41-1.86c4.16-1.47,5.92-3.79,6.66-6.08h-6v-2.2h6.48c0-.48.06-.93.06-1.38V20h-5.89v-2.2H192V20h-6.12v1.85c0,.43,0,.88,0,1.35h6.86v2.2Z" fill="currentColor" />
      <path d="M211.63,27.18a24,24,0,0,0-2.32-2.09,7.34,7.34,0,0,1-1.2,1.55,10.8,10.8,0,0,0-1.41-.92,15.7,15.7,0,0,0,6.88,5,9.81,9.81,0,0,0-1.59,2,17.37,17.37,0,0,1-5.83-4.56v5.24h-2.25V28.26a17.4,17.4,0,0,1-5.78,4.45,13.06,13.06,0,0,0-1.51-1.84,16,16,0,0,0,7.29-5.8V20.91h-6.55v-2.1h6.55V16.59h2.25v2.22H213v2.1h-6.79v4l.27.41A8.06,8.06,0,0,0,208.7,21l2.09.38a14.94,14.94,0,0,1-.6,1.93c1,.79,2.27,1.74,2.94,2.36Zm-9.27-5.76a16.36,16.36,0,0,1-.65,1.94c.65.56,1.34,1.15,1.75,1.59L202,26.44a14.67,14.67,0,0,0-1.21-1.26,9.71,9.71,0,0,1-2.07,2.47,13.56,13.56,0,0,0-1.65-1.41A8.68,8.68,0,0,0,200.27,21Z" fill="currentColor" />
      <path d="M232.63,17.17v16.2h-2.34v-.95H219.47v.95h-2.24V17.17Zm-2.34,13.25v-11H219.47V30.42Zm-1.75-8.93v6.84h-7.35V21.49Zm-2.16,1.87h-3.17v3.12h3.17Z" fill="currentColor" />
      <path d="M95.51,22.7v1.85H87.28V23c-2.27.57-4.74,1.12-6.58,1.55l-.32-1.83c.24,0,.53-.09.82-.16V17.24h1.55v5l.7-.16v-6H81.31v-5.3h5.55v5.3H85.13V18h2v1.66h-2v2.1l2-.43.14,1.38h.55V13h-.63V11.07h8V13h-5.6v1.63h5v6h-5V22.7ZM83,14.48h2.15v-2H83Zm6.71,1.86v2.51h3.17V16.34Z" fill="currentColor" />
      <path d="M113.33,24.44h-5.08v.7H106.6V16.79l-.16.22a9.7,9.7,0,0,0-.84-.8v1.6h-2.52l-.16.84h3.22v4.94c0,.71-.13,1.07-.61,1.31a5.54,5.54,0,0,1-2.08.21,4.48,4.48,0,0,0-.37-1.34,11.62,11.62,0,0,0,1.31,0c.16,0,.23,0,.23-.2V23l-.9.34c0-.2-.13-.44-.21-.68-1,.23-1.92.47-2.75.69l-.4-1.26.43-.08c.16-.56.31-1.22.47-1.92h-.93v5.05H98.81V20.09h-.37V18.65h.37V18.2h1.52v.45h1.2c0-.29.09-.56.14-.84H99.11V13.4h1.38v3.15h3.66v-.79l-.68.65a12.7,12.7,0,0,0-1-.92c-.37.35-.74.66-1.08.93a8,8,0,0,0-.92-.74,10.78,10.78,0,0,0,1.15-.94l-.93-.75.75-.67c.29.22.61.46.93.72.24-.24.46-.5.66-.74l1.08.42c-.27.33-.57.67-.89,1l.89.79V13.4h1.45v1.74a17.34,17.34,0,0,0,2-4.9l1.69.4c-.24.85-.52,1.73-.84,2.56h4.7v1.7h-1.77v1.49h1.64V18h-1.64v1.54h1.64v1.6h-1.64v1.58h1.93ZM106.25,13H98.49v-1.5h3.09c-.12-.32-.23-.66-.32-.93l1.6-.35c.17.38.35.86.49,1.28h2.9Zm-3.6,7.13c-.13.59-.24,1.15-.37,1.65l.83-.18c-.12-.3-.24-.61-.36-.9l1-.33c.29.62.62,1.39.85,2V20.09Zm7.06-5.19h-1.46v1.49h1.46Zm0,3.09h-1.46v1.54h1.46Zm-1.46,4.72h1.46V21.13h-1.46Zm1.94-9.55a15.48,15.48,0,0,0-.9-2.25l1.64-.51a17,17,0,0,1,1,2.13Z" fill="currentColor" />
      <path d="M33.67,26.91v1.6H19.53v-1.6H25.7c-.13-.39-.26-.79-.39-1.12l2-.48c.18.49.43,1.08.63,1.6ZM33.3,38.62c0,.83-.21,1.17-.82,1.41a8.54,8.54,0,0,1-2.54.19,6.24,6.24,0,0,0-.56-1.34H24.8v.67H23V35.33h7v3.55h1.06c.22,0,.32-.07.32-.27V34.73H21.82v5.62H20V33.2H33.3Zm-1.54-6.08H21.66V29.12h10.1Zm-2-2.14H23.57v.88h6.19Zm-1.44,6.25H24.8v.92h3.52Z" fill="currentColor" />
      <path d="M39.87,31c0,2.68-.21,6.69-1.54,9.3a7.14,7.14,0,0,0-1.74-.82c1.28-2.47,1.39-6,1.39-8.47V26.79h6a8.55,8.55,0,0,0-.38-1.05l1.93-.43a10.21,10.21,0,0,1,.61,1.48h5.28v1.78H39.87Zm10.58,4.13a8,8,0,0,1-2.82,3,27.2,27.2,0,0,0,4,.57,8.57,8.57,0,0,0-1.09,1.65,18.53,18.53,0,0,1-5.18-1.17,22.21,22.21,0,0,1-5.06,1.08,7.6,7.6,0,0,0-.79-1.59,24.18,24.18,0,0,0,3.78-.56,9.53,9.53,0,0,1-1.94-1.74l.64-.26H40.32V34.62h8.61l.34-.06ZM42.35,34v-2.5H40.19V30h2.16V28.94h1.79V30h2.92V28.94h1.88V30h2.31v1.48H48.94V34Zm1,2.13a7.64,7.64,0,0,0,2.11,1.3,7.76,7.76,0,0,0,2.11-1.3Zm.78-4.63v1.06h2.92V31.48Z" fill="currentColor" />
      <path d="M11.86,79.18l1.24-1.67a4.24,4.24,0,0,0,3,1.37c1.35,0,2.27-.67,2.27-1.86s-.78-2.12-3.68-2.12V73c2.45,0,3.24-.85,3.24-2a1.59,1.59,0,0,0-1.78-1.67,3.82,3.82,0,0,0-2.52,1.19l-1.35-1.62a5.9,5.9,0,0,1,4-1.64c2.57,0,4.32,1.26,4.32,3.55a3.16,3.16,0,0,1-2.23,3v.09A3.33,3.33,0,0,1,21,77.17C21,79.61,18.86,81,16.32,81A5.65,5.65,0,0,1,11.86,79.18Z" fill="currentColor" />
      <path d="M24.61,74.07c0-4.61,1.8-6.86,4.52-6.86s4.5,2.27,4.5,6.86-1.8,7-4.5,7S24.61,78.66,24.61,74.07Zm6.51,0c0-3.73-.86-4.81-2-4.81s-2,1.08-2,4.81.86,4.9,2,4.9S31.12,77.81,31.12,74.07Z" fill="currentColor" />
      <path d="M37.3,74.07c0-4.61,1.8-6.86,4.52-6.86s4.5,2.27,4.5,6.86-1.8,7-4.5,7S37.3,78.66,37.3,74.07Zm6.52,0c0-3.73-.87-4.81-2-4.81s-2,1.08-2,4.81.87,4.9,2,4.9S43.82,77.81,43.82,74.07Z" fill="currentColor" />
      <path d="M50,74.07c0-4.61,1.8-6.86,4.52-6.86S59,69.48,59,74.07s-1.8,7-4.5,7S50,78.66,50,74.07Zm6.52,0c0-3.73-.86-4.81-2-4.81s-2,1.08-2,4.81.86,4.9,2,4.9S56.51,77.81,56.51,74.07Z" fill="currentColor" />
      <path d="M63.16,70.7h2.16l.18,1.35h.07a4.27,4.27,0,0,1,3.1-1.6,2.74,2.74,0,0,1,2.77,1.76,4.5,4.5,0,0,1,3.21-1.76c2.14,0,3.13,1.51,3.13,4v6.28H75.14v-6c0-1.54-.45-2.1-1.41-2.1a2.94,2.94,0,0,0-1.94,1.13v6.93H69.14v-6c0-1.54-.45-2.1-1.4-2.1a3,3,0,0,0-1.95,1.13v6.93H63.16Z" fill="currentColor" />
      <path d="M89.15,77.27c-2.38,1.07-5,2.2-7.08,3L81.36,78c.68-.23,1.49-.52,2.39-.88l-.36-10.52,2.23-.09L86,76.28l2.71-1.11Zm7.46,5.06A32.6,32.6,0,0,0,93.24,78a12.83,12.83,0,0,1-5.48,4.38,11.79,11.79,0,0,0-1.71-1.89c5.71-2.16,7.57-6.52,8.23-13.92l2.36.22a27.85,27.85,0,0,1-2.2,9.14,39.12,39.12,0,0,1,4,4.63ZM89.85,73.1a18.17,18.17,0,0,0-2.68-4.47l1.94-1A18,18,0,0,1,92,72Z" fill="currentColor" />
      <path d="M118.16,79.31v2.16H101.79V79.31h6.45V65.75h2.3V70.9h6.36v2.18h-6.36v6.23Z" fill="currentColor" />
      <path d="M7,128.55h2.7v-8H7.46v-1.55A8.11,8.11,0,0,0,10.38,118h1.85v10.56H14.6v2H7Z" fill="currentColor" />
      <path d="M17.69,124.23c0-4.35,1.7-6.47,4.26-6.47s4.25,2.14,4.25,6.47-1.7,6.58-4.25,6.58S17.69,128.57,17.69,124.23Zm6.15,0c0-3.52-.82-4.54-1.89-4.54s-1.9,1-1.9,4.54.82,4.63,1.9,4.63S23.84,127.77,23.84,124.23Z" fill="currentColor" />
      <path d="M29.68,124.23c0-4.35,1.7-6.47,4.27-6.47s4.25,2.14,4.25,6.47-1.7,6.58-4.25,6.58S29.68,128.57,29.68,124.23Zm6.16,0c0-3.52-.82-4.54-1.89-4.54s-1.91,1-1.91,4.54.82,4.63,1.91,4.63S35.84,127.77,35.84,124.23Z" fill="currentColor" />
      <path d="M41.68,124.23c0-4.35,1.7-6.47,4.26-6.47s4.25,2.14,4.25,6.47-1.7,6.58-4.25,6.58S41.68,128.57,41.68,124.23Zm6.15,0c0-3.52-.82-4.54-1.89-4.54s-1.9,1-1.9,4.54.81,4.63,1.9,4.63S47.83,127.77,47.83,124.23Z" fill="currentColor" />
      <path d="M54,124.81h4.64v1.79H54Z" fill="currentColor" />
      <path d="M61.2,129.06l1.17-1.58a4,4,0,0,0,2.8,1.29c1.28,0,2.15-.63,2.15-1.75s-.74-2-3.47-2v-1.8c2.31,0,3.06-.8,3.06-1.92a1.51,1.51,0,0,0-1.69-1.58,3.61,3.61,0,0,0-2.38,1.12l-1.27-1.53a5.58,5.58,0,0,1,3.77-1.54c2.43,0,4.08,1.19,4.08,3.34a3,3,0,0,1-2.1,2.88v.08a3.15,3.15,0,0,1,2.51,3.1c0,2.31-2,3.65-4.42,3.65A5.34,5.34,0,0,1,61.2,129.06Z" fill="currentColor" />
      <path d="M73.24,124.23c0-4.35,1.7-6.47,4.27-6.47s4.25,2.14,4.25,6.47-1.7,6.58-4.25,6.58S73.24,128.57,73.24,124.23Zm6.16,0c0-3.52-.82-4.54-1.89-4.54s-1.9,1-1.9,4.54.81,4.63,1.9,4.63S79.4,127.77,79.4,124.23Z" fill="currentColor" />
      <path d="M85.24,124.23c0-4.35,1.7-6.47,4.26-6.47s4.25,2.14,4.25,6.47-1.7,6.58-4.25,6.58S85.24,128.57,85.24,124.23Zm6.15,0c0-3.52-.82-4.54-1.89-4.54s-1.9,1-1.9,4.54.82,4.63,1.9,4.63S91.39,127.77,91.39,124.23Z" fill="currentColor" />
      <path d="M97.23,124.23c0-4.35,1.7-6.47,4.27-6.47s4.25,2.14,4.25,6.47-1.7,6.58-4.25,6.58S97.23,128.57,97.23,124.23Zm6.15,0c0-3.52-.81-4.54-1.88-4.54s-1.91,1-1.91,4.54.82,4.63,1.91,4.63S103.38,127.77,103.38,124.23Z" fill="currentColor" />
      <path d="M109.67,121.05h2l.17,1.28H112a4,4,0,0,1,2.92-1.51,2.6,2.6,0,0,1,2.62,1.66,4.22,4.22,0,0,1,3-1.66c2,0,3,1.42,3,3.82v5.93H121V125c0-1.47-.43-2-1.33-2a2.73,2.73,0,0,0-1.83,1.07v6.54h-2.5V125c0-1.47-.43-2-1.33-2a2.77,2.77,0,0,0-1.84,1.07v6.54h-2.48Z" fill="currentColor" />
      <path d="M13.6,179.62h2.86V171.2H14.05v-1.64a8.77,8.77,0,0,0,3.09-1.12h2v11.18h2.5v2.14h-8Z" fill="currentColor" />
      <path d="M24.87,175.05c0-4.61,1.81-6.86,4.52-6.86s4.51,2.27,4.51,6.86-1.8,7-4.51,7S24.87,179.64,24.87,175.05Zm6.52,0c0-3.73-.86-4.81-2-4.81s-2,1.08-2,4.81.86,4.9,2,4.9S31.39,178.79,31.39,175.05Z" fill="currentColor" />
      <path d="M37.57,175.05c0-4.61,1.8-6.86,4.52-6.86s4.5,2.27,4.5,6.86-1.8,7-4.5,7S37.57,179.64,37.57,175.05Zm6.52,0c0-3.73-.87-4.81-2-4.81s-2,1.08-2,4.81.87,4.9,2,4.9S44.09,178.79,44.09,175.05Z" fill="currentColor" />
      <path d="M50.26,175.05c0-4.61,1.8-6.86,4.52-6.86s4.5,2.27,4.5,6.86-1.8,7-4.5,7S50.26,179.64,50.26,175.05Zm6.52,0c0-3.73-.86-4.81-2-4.81s-2,1.08-2,4.81.87,4.9,2,4.9S56.78,178.79,56.78,175.05Z" fill="currentColor" />
      <path d="M63.43,171.68h2.16l.18,1.35h.07a4.27,4.27,0,0,1,3.1-1.6,2.74,2.74,0,0,1,2.77,1.76,4.48,4.48,0,0,1,3.21-1.76c2.14,0,3.13,1.51,3.13,4v6.28H75.4v-6c0-1.54-.45-2.1-1.4-2.1a3,3,0,0,0-2,1.13v6.93H69.41v-6c0-1.54-.45-2.1-1.41-2.1a3,3,0,0,0-1.94,1.13v6.93H63.43Z" fill="currentColor" />
      <path d="M89.42,178.25c-2.38,1.07-5,2.2-7.08,3.05L81.62,179c.69-.23,1.5-.52,2.4-.88l-.36-10.52,2.23-.09.32,9.74,2.72-1.11Zm7.45,5.06A31.61,31.61,0,0,0,93.51,179,12.83,12.83,0,0,1,88,183.35a12.28,12.28,0,0,0-1.71-1.89c5.71-2.16,7.56-6.52,8.23-13.92l2.36.22a27.85,27.85,0,0,1-2.2,9.14,39.12,39.12,0,0,1,4,4.63Zm-6.75-9.23a18.17,18.17,0,0,0-2.68-4.47l1.94-1A18.35,18.35,0,0,1,92.23,173Z" fill="currentColor" />
      <path d="M110,170v2.83l.36-.42a40.47,40.47,0,0,1,6.14,3.64l-1.59,2a35.5,35.5,0,0,0-4.91-3.28v8.56h-2.31V170h-6.5v-2.16h16V170Z" fill="currentColor" />
      <path d="M306.18,25a21.52,21.52,0,0,1-1.41,4.68c.29.18.63.44,1.1.72,1.25.74,2.94.85,5,.85a60.88,60.88,0,0,0,7.3-.47,7.3,7.3,0,0,0-.63,2.2c-1.48.09-4.67.22-6.77.22-2.4,0-3.91-.2-5.21-1-.75-.41-1.4-1-1.76-1s-1.06,1.23-1.51,2.25l-1.43-2a8.2,8.2,0,0,1,1.89-1.73,17.18,17.18,0,0,0,1-3.11h-2.27c.41-1.07.88-2.51,1.3-3.91h-1.62V20.77h4.21c-.38,1.26-.81,2.7-1.24,4H305l.33-.07Zm-3-4.73c-.19-.81-.68-2.11-1-3.1l1.89-.58c.35,1,.81,2.18,1.07,3Zm5.3,10.19h-1.86V22.61h1.34V17.1h7.76v5.51H317v6c0,.85-.14,1.3-.7,1.58a5.14,5.14,0,0,1-2.23.29,6.12,6.12,0,0,0-.47-1.55h-2.7v.56h-1.53V25h4.7v3.87h.73c.2,0,.26-.06.26-.24V24.23h-6.61Zm1.33-7.83h1V19.78h3v-1.1h-4Zm2.74,3.72H310.9v1.26h1.62Zm1.24-5.22h-1.42v1.5h1.42Z" fill="currentColor" />
      <path d="M321.83,29.68h2.87V21.26h-2.42V19.62a8.56,8.56,0,0,0,3.1-1.12h2V29.68h2.51v2.14h-8Z" fill="currentColor" />
      <path d="M348.44,22.5a16,16,0,0,1-3.62,7,13.76,13.76,0,0,0,4.39,2.05,9,9,0,0,0-1.42,1.91,14.1,14.1,0,0,1-4.52-2.51,15.57,15.57,0,0,1-4.45,2.49,7.47,7.47,0,0,0-1.35-1.77,15.17,15.17,0,0,0,4.31-2.17,17.12,17.12,0,0,1-1.95-2.81,12.46,12.46,0,0,1-1.13,1.06,10.43,10.43,0,0,0-1.48-1.49,14.33,14.33,0,0,0,4.22-5.87H337l1.55.46a20.27,20.27,0,0,1-1.56,3v9.58H334.9V26.64c-.45.5-.9.95-1.33,1.37A14.49,14.49,0,0,0,332,26.33a15.39,15.39,0,0,0,4.41-6H332.7v-2h7.08c-.2-.44-.42-.9-.63-1.26l2-.69c.34.58.77,1.33,1.06,1.95h6.5v2h-6.54l1.46.39c-.16.4-.36.79-.54,1.19h3.62l.36-.07ZM342,23.81c-.29.47-.59.92-.92,1.37a11.58,11.58,0,0,0,2.14,3,11.58,11.58,0,0,0,2.63-4.34Zm1.39,3.05a9.73,9.73,0,0,0-1.68-1.81l1.28-1a11.35,11.35,0,0,1,1.76,1.66Z" fill="currentColor" />
      <path d="M413.61,25a22.68,22.68,0,0,1-1.41,4.68c.29.18.63.44,1.1.72,1.24.74,2.94.85,5,.85a60.84,60.84,0,0,0,7.29-.47A7.93,7.93,0,0,0,425,33c-1.47.09-4.66.22-6.77.22-2.39,0-3.9-.2-5.2-1-.76-.41-1.4-1-1.76-1s-1.07,1.23-1.52,2.25l-1.42-2a8.2,8.2,0,0,1,1.89-1.73,17.18,17.18,0,0,0,1-3.11H409c.41-1.07.88-2.51,1.29-3.91h-1.62V20.77h4.22c-.38,1.26-.81,2.7-1.25,4h.74l.33-.07Zm-3-4.73c-.2-.81-.68-2.11-1-3.1l1.89-.58c.34,1,.81,2.18,1.06,3Zm5.29,10.19H414V22.61h1.33V17.1h7.76v5.51h1.32v6c0,.85-.15,1.3-.7,1.58a5.21,5.21,0,0,1-2.24.29,5.4,5.4,0,0,0-.47-1.55h-2.7v.56h-1.53V25h4.7v3.87h.74c.2,0,.25-.06.25-.24V24.23h-6.61Zm1.34-7.83h1V19.78h3v-1.1h-4Zm2.73,3.72h-1.62v1.26h1.62Zm1.25-5.22h-1.43v1.5h1.43Z" fill="currentColor" />
      <path d="M430.51,30.31c3.82-3,6.9-5.67,6.9-7.79a2.14,2.14,0,0,0-2.31-2.18c-1.24,0-2.14.63-3.22,1.78l-1.55-1.51a6.51,6.51,0,0,1,4.9-2.38c2.75,0,4.75,1.5,4.75,4.2s-2.34,4.89-5.2,7.31c.72-.06,1.33-.13,2-.13h3.8v2.21H430.51Z" fill="currentColor" />
      <path d="M461,22.5a16,16,0,0,1-3.62,7,13.71,13.71,0,0,0,4.4,2.05,9.43,9.43,0,0,0-1.43,1.91,14.1,14.1,0,0,1-4.52-2.51,15.52,15.52,0,0,1-4.44,2.49A7.5,7.5,0,0,0,450,31.64a15.17,15.17,0,0,0,4.31-2.17,17.12,17.12,0,0,1-1.95-2.81,11.26,11.26,0,0,1-1.13,1.06,10.43,10.43,0,0,0-1.48-1.49A14.24,14.24,0,0,0,454,20.36h-4.41l1.54.46a20.27,20.27,0,0,1-1.56,3v9.58h-2.11V26.64c-.45.5-.9.95-1.33,1.37a14.49,14.49,0,0,0-1.57-1.68,15.29,15.29,0,0,0,4.41-6h-3.71v-2h7.08a12.32,12.32,0,0,0-.63-1.26l2-.69c.34.58.77,1.33,1.06,1.95h6.5v2h-6.53l1.45.39c-.16.4-.36.79-.54,1.19h3.62l.36-.07Zm-6.41,1.31c-.29.47-.59.92-.92,1.37a11.38,11.38,0,0,0,2.15,3,11.84,11.84,0,0,0,2.63-4.34ZM456,26.86a9.73,9.73,0,0,0-1.68-1.81l1.28-1a11.92,11.92,0,0,1,1.77,1.66Z" fill="currentColor" />
      <path d="M472.45,28.31c-2.37,1.07-5,2.2-7.07,3.05l-.72-2.29c.68-.23,1.49-.52,2.39-.88l-.36-10.52,2.23-.09.33,9.74L472,26.21Zm7.46,5.06A32.6,32.6,0,0,0,476.54,29a12.86,12.86,0,0,1-5.47,4.38,12.82,12.82,0,0,0-1.71-1.89c5.7-2.16,7.56-6.52,8.22-13.92l2.36.22A27.87,27.87,0,0,1,477.75,27a38.94,38.94,0,0,1,4,4.63Zm-6.76-9.23a18.17,18.17,0,0,0-2.68-4.47l1.95-1A18.29,18.29,0,0,1,475.26,23Z" fill="currentColor" />
      <path d="M501.46,30.35v2.16H485.1V30.35h6.44V16.79h2.31v5.15h6.35v2.18h-6.35v6.23Z" fill="currentColor" />
      <rect x="1" y="1" width="520" height="200" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none" />
      <rect x="1" y="1" width="130" height="200" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none" />
      <rect x="131" y="1" width="130" height="200" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none" />
      <rect x="391" y="1" width="130" height="200" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none" />
      <rect x="1" y="1" width="520" height="50" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none" />
      <rect x="1" y="51" width="520" height="50" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none" />
      <rect x="1" y="101" width="520" height="50" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none" />
      <rect x="1" y="151" width="520" height="50" stroke-width="2" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" fill="none" />
    </g>
  </SVG>
)

export default Form
