import React from 'react'
import styled from 'styled-components';
import { IoIosLock } from 'react-icons/io';
import { icons } from '../../photo'
import { Link } from 'react-router-dom';

import Box from '../../../components/Box';
import Flex from '../../../components/Flex';
import Image from '../../../components/Image';
import useResponsive from '../../../contexts/mediaQuery/useResponsive'

const StyledLink = styled(Link)`
  text-decoration: none;
  color: black;
`
const Module = ({ name, src, isLock, openList, ...props }) => {
  const { isMobile } = useResponsive()
  return (
    <Flex
      as={StyledLink}
      to={`/topics/${src}`}
      target={isMobile ? '_self' : '_blank'}
      pointerEvents={isLock && 'none'}
      mt="1.125em"
      mb="1em"
      alignItems="center"
      onClick={isMobile && (() => openList(false))}
      {...props}
    >
      <Box position="relative" borderRadius="50%" width="2.5em" bg="white" overflow="hidden">
        <Image src={icons[src]} />
        {isLock && (
          <Box
            position="absolute"
            left="0"
            right="0"
            top="0"
            bottom="0"
            bg="white"
            opacity="0.5"
            borderRadius="1em"
          >
            <Box position="absolute" left="50%" top="50%" transform="translate(-50%, -50%)">
              <IoIosLock size="1.25em" />
            </Box>
          </Box>
        )}
      </Box>
      <Box ml="0.5em">{name}</Box>
    </Flex>
  )
}

export default Module
