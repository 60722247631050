import React from 'react'

import SVG from '../../components/SVG'

const Protect = (props) => (
  <SVG viewBox="0 0 32 32" {...props}>
    <g id="icon">
      <path d="M23.58,16.22a1.17,1.17,0,0,1,0,1.67,1.1,1.1,0,0,1-.83.34H20.87s-.06,0-.08.06a.06.06,0,0,0,0,.08A13.1,13.1,0,0,0,23.33,21a1.13,1.13,0,0,1,.47.85V22a1.19,1.19,0,0,1-.31.81,1.43,1.43,0,0,1-.93.49l-.16,0a1.18,1.18,0,0,1-.83-.34,15.73,15.73,0,0,1-2-2.42s0,0-.07,0a.07.07,0,0,0,0,.05v2.6a1.25,1.25,0,0,1-.39.92,1.23,1.23,0,0,1-.92.39,1.33,1.33,0,0,1-1.31-1.31V20.66s0,0,0-.05-.05,0-.06,0c-.32.42-.65.83-1,1.21a10.45,10.45,0,0,1-1.07,1,1.27,1.27,0,0,1-.86.34l-.23,0a1.84,1.84,0,0,1-1.06-.58,9,9,0,0,0-.72-.76.08.08,0,0,0-.08,0,.12.12,0,0,0,0,.08v1.25a1.23,1.23,0,0,1-1.24,1.23A1.21,1.21,0,0,1,9.59,24a1.19,1.19,0,0,1-.36-.87V15.61a.05.05,0,0,0,0-.06s0,0-.06,0a.83.83,0,0,1-.65.31H8.3a1,1,0,0,1-.72-.55l0-.07a2.28,2.28,0,0,1-.22-1,2.35,2.35,0,0,1,0-.26,2.22,2.22,0,0,1,.5-1.2,18.4,18.4,0,0,0,2.58-4.22,1.37,1.37,0,0,1,.7-.72,1.33,1.33,0,0,1,.57-.14,1.23,1.23,0,0,1,.42.07,1.23,1.23,0,0,1,.82,1.11,1.17,1.17,0,0,1-.1.47c-.32.72-.66,1.42-1,2.09a.69.69,0,0,0-.09.34v9.74a.14.14,0,0,0,0,.09.11.11,0,0,0,.11,0,11.53,11.53,0,0,0,3.67-3.26.05.05,0,0,0,0-.08s0-.06-.08-.06H13.59a1.12,1.12,0,0,1-.83-.34,1.17,1.17,0,0,1,0-1.67,1.12,1.12,0,0,1,.83-.34h3.1c.11,0,.17-.06.17-.18v-.93c0-.12-.06-.18-.17-.18H15.05A1.5,1.5,0,0,1,14,14.15a1.48,1.48,0,0,1-.45-1.09V9.64A1.44,1.44,0,0,1,14,8.56a1.47,1.47,0,0,1,1.08-.45h6.54a1.47,1.47,0,0,1,1.08.45,1.44,1.44,0,0,1,.45,1.08v3.42a1.48,1.48,0,0,1-.45,1.09,1.5,1.5,0,0,1-1.08.44H19.66a.16.16,0,0,0-.18.18v.93a.16.16,0,0,0,.18.18h3.09A1.1,1.1,0,0,1,23.58,16.22Zm-7.4-5.81a.16.16,0,0,0-.18.18v1.54a.16.16,0,0,0,.18.18h4.14a.16.16,0,0,0,.18-.18V10.59a.16.16,0,0,0-.18-.18Z" fill="currentColor"/><circle cx="16" cy="16" r="12.5" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="2"/>
      <circle cx="16" cy="16" r="15" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="2"/>
    </g>
  </SVG>
)

export default Protect
