import React from 'react'

import duty1 from './duty1.jpg'
import duty2 from './duty2.jpg'

import Container from '../../components/Container'
import Text from '../../components/Text'
import Image from '../../components/Image'
import Box from '../../components/Box'

const contents = [
  {
    title: '一定要走進山林嗎？',
    text: '人類在大自然中的任何行為都會對環境造成各種程度的衝擊。「禁止進入大自然」是唯一不對環境造成破壞的方式嗎？我們想大部分的人都同意教育替代禁止，不因噎廢食。明清以降，日治時期、戒嚴時代，都因為政治管理因素而封山。時空轉換，當「山林開放」「教育普及」「明確責任」…等成為山林解禁的內涵時，我們親身接觸戶外，與大自然產生連結，讓我們更熱愛身處的這片土地。台灣的美是所有人共同擁有的價值。'
  },
  {
    title: '人數=破壞！？',
    text: '「連假超過300人進到同一座山裡會產生什麼問題？」在不同場次的講座中與剛進入登山運動的山友討論時，大家的回饋，也通常是新聞媒體會下的標題，排遺、沖蝕溝、衛生紙、搭帳亂象等…，再發問「人數=破壞？」這時候大家反而陷入長考。為了滿足人類基本需求吃喝拉撒睡，不論在野外環境或文明社會都會產生廢棄物。文明社會中大家經過學習，會在適當的地方上廁所，會適當處理衛生紙。\n\n反之，進入野外環境呢，怎麼都成為社會亂象了。當進入山林的人經過學習、做好準備，用儘可能低衝擊的方式行動時，這些行為就發展成登山素養，這才是山友與大自然共處的面貌。'
  },
  {
    title: '人x素養=登山文化',
    src: duty2,
    note: '實拍圖來源：李岱鑫'
  },
]

const Duty = () => (
  <Container maxWidth="800px">
    <Text.H1>人 x 素養 = 登山文化，所謂山友責任</Text.H1>
    <Text.H6>撰文 / 李岱鑫，文章來源 / 橘子關懷基金會</Text.H6>
    <Text.Article>
      當個人是流行風潮的一份子，需要負責任嗎？<br />
      後疫情時代，登山運動人口大量成長，創下了統計數字的高峰。雪霸國家公園資料顯示，雪東線今年前九個月入園人數，已經超越去年一整年的統計數字，相較去年同期成長44%，玉管處也有相同發現。適逢行政院頒布『山林解禁』、交通部提『2020脊樑山脈旅遊年』。
    </Text.Article>
    <Image src={duty1} />
    <Text>實拍圖來源：李岱鑫</Text>
    <Text.Article>
      長年投入登山教育的我們開玩笑的說，除了口罩政策之外，這大概是數一數二成效好的國家政策，去年就超前部署了。大量登山人口的突然增加，值得討論的面相很多，包括政策、法源、環境等。而本篇想與大家討論：當登山運動已蔚為風潮，身為山友的一份子我們有什麼責任？
    </Text.Article>
    {contents.map(({ title, text, src, note }) => (
      <Box my="2em">
        <Text fontWeight="bold">{title}</Text>
        {src ? <Image src={src} /> : <Text whiteSpace="pre-wrap">{text}</Text>}
        <Text>{note}</Text>
      </Box>
    ))}
    <Text.Article>
      早期台灣登山教育強調硬技術，攀登過幾座百岳、如何求生、繩索技術、雪地技術、攀登技術等。林務局2007年在台灣開始第一梯無痕山林高階教師訓練之後，開啟了近十年登山教育軟實力的累積。更關注在環境議題，人類與環境永續的方向。2020年行政院政策及疫情推波助瀾下，登山運動呈現多元面貌，進入大自然慢慢成為顯學，鼓勵更多的人參與登山運動。步道理想的面貌是一條健康永續的路徑，有賴公部門持續維護修繕的系統。同時也該關注個人應做好的準備：運用降低環境衝擊的方式親近大自然，為自己的安全負責。
    </Text.Article>
    <Text.Article>
      山是中性的，每個人帶著不同的理由親近山林，同時我們尊重每個人在山林裡的選擇。這是一個很好的契機，當愈來愈多的人進入山林，透過學習與戶外素養養成後，也就開始形塑屬於臺灣這個世代的登山文化。
    </Text.Article>
    <Box fontWeight="bold">
      <Text.H6 mb="2rem" textAlign="left">作者簡介：</Text.H6>
      <Text>
        李岱鑫 Dai Sin Li<br />
        <br />
        國立清華大學國際專業管理碩士<br />
        2013 台灣外展教育發展基金會 培訓發展主任<br />
        2007 ACCT PVM3 高空繩索舊原<br />
        2008 WMA 野外急救員<br />
        2015 完成EBC 聖母峰基地環營線<br />
        2016 完成自行車一日北高<br />
      </Text>
    </Box>
  </Container>
)

export default Duty
