import React from 'react'
import styled, { css } from 'styled-components';
import {
  fontSize,
  fontWeight,
  lineHeight,
  space,
  color,
  letterSpacing,
  display,
} from 'styled-system';
import shouldForwardProp from '@styled-system/should-forward-prop'
import { Link } from 'react-router-dom'

import { customColor } from './utils/getColor';
import isToAs from './utils/isToAs'
import injectProps from './utils/injectProps'

const linkStyle = css`
  ${fontSize}
  ${space}
  ${color}
  ${fontWeight}
  ${lineHeight}
  ${letterSpacing}
  ${display}
  ${letterSpacing}
  ${injectProps('textDecoration')}
  ${({ disabled }) => disabled && `
    pointer-events: none;
  `}
  &:hover {
    ${customColor('hoverColor')};
  }
`;

const StyleLink = isToAs(styled.a.withConfig({ shouldForwardProp })`
  ${linkStyle}
`);

const ReLink = ({ to, ...props }) => {
  if (to) return <StyleLink as={Link} to={to} {...props} />
  return <StyleLink to={to} {...props} />
}

ReLink.displayName = 'Link';

ReLink.defaultProps = {
  fontWeight: 'bold',
  target: '_blank',
  textDecoration: 'none',
};

ReLink.Underline = props => (
  <ReLink textDecoration="underline" {...props} />
)

export default ReLink;
