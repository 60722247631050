import React, { useState } from 'react'
import { BiImageAlt, BiCart } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';

import Box from '../../components/Box';
import Flex from '../../components/Flex';
import Image from '../../components/Image';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import useResponsive from '../../contexts/mediaQuery/useResponsive'
import theme, { responsive } from '../../components/ThemeProvider/theme';

import title from './title.png'
import titlewebp from './title.webp'

import Menu from './Menu'
import MountainList from './MountainList';
import Search from '../Search';
import Subscribe from './Subscribe';

const Header = ({ siteTitle, ...props }) => {
  const [open, openList] = useState()
  const { isMobile } = useResponsive()
  const [emailIsOpen, setOpenModal] = useState()
  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      right={0}
      zOrder={2}
      {...props}
    >
      <OutsideClickHandler disabled={!Boolean(open)} onOutsideClick={() => openList(false)}>
        <Flex
          bg="white"
          position="relative"
          alignItems="center"
          boxShadow="0px 1px 0.5px lightgray"
          height={theme.headerHeight}
        >
          <Menu openModal={() => setOpenModal(true)} openList={openList} />
          <Button.Transparent onClick={() => openList(!open)} mx="0.5em" leftIcon={BiImageAlt}>
            {!isMobile && '選山列表'}
          </Button.Transparent>
          {!isMobile &&
            <>
              <Button.Transparent mx="0.5em" as={Link} to="/shop" leftIcon={BiCart}>
                商品
              </Button.Transparent>
              <Search />
            </>
          }
          <Box
            width={responsive('8em', '10em')}
            as={Link}
            to="/"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          >
            <Image src={[titlewebp, title]} alt="山模山樣" title="山模山樣" />
          </Box>
        </Flex>
        {open && (
          <MountainList openList={openList} />
        )}
      </OutsideClickHandler>
      <Modal
        closeIcon
        isOpen={emailIsOpen}
        onRequestClose={() => setOpenModal(false)}
        customStyles={{
          maxWidth: '40em',
          heigth: '100%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '1em',
          top: '50%',
          left: '50%',
          border: '5px solid black',
          overflow: 'hidden'
        }}
      >
        <Subscribe />
      </Modal>
    </Box>
  )
}

export default Header
