import React, { useState } from 'react'
import { useImmer } from "use-immer";
import { RiArrowDownSLine } from 'react-icons/ri';
import Collapsible from 'react-collapsible';
import fill from 'lodash/fill'

import Module from './Module'

import Box from '../../../components/Box';
import Flex from '../../../components/Flex';
import Button from '../../../components/Button';

const Desktop = ({ region, useData, openList }) => {
  const [isOpen, openCol] = useImmer([])
  const [show, openAll] = useState(6)
  return (
    <Flex
      position="absolute"
      height="90vh"
      flexDirection="column"
      top="3.5em"
      left="0"
      right="0"
      px="1em"
      pb="1.875em"
      bg="white"
      overflow="scroll"
      zIndex={98}
    >
      {region.map((r, i) => (
        <Box pt="1.875em" key={i}>
          <Collapsible
            trigger={
              <Flex borderBottom="1px solid" pb="0.25em" alignItems="center" justifyContent="space-between">
                <Box>{r}</Box>
                {useData[r].length > 5 && isOpen[i] && (
                  <Flex alignItems="center">
                    <Box transform={useData[r].length === show && 'rotate(180deg)'}>
                      <RiArrowDownSLine size="1.25em" />
                    </Box>
                    <Button.Transparent
                      py="0"
                      onClick={(e) => {
                        e.stopPropagation()
                        if (useData[r].length === show) {
                          return openAll(6)
                        }
                        openAll(useData[r].length)
                      }}
                    >
                      展開全部
                    </Button.Transparent>
                  </Flex>
                )}
              </Flex>
            }
            open={isOpen[i]}
            handleTriggerClick={() => {
              openCol(draft => {
                if (!draft[i]) {
                  fill(draft, false)
                }
                draft[i] = !draft[i]
              })
            }}
            easing="linear"
            transitionTime="400"
          >
            <Box position="relative">
              {useData[r].slice(0, show).map(({ name, src, isLock }, k) => (
                <Module openList={openList} name={name} src={src} isLock={isLock} key={k} />
              ))}
              {show < useData[r].length &&
                <Box
                  position="absolute"
                  top="0"
                  bottom="0"
                  left="0"
                  right="0"
                  pointerEvents="none"
                  background="linear-gradient(rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255) 100%)"
                />
              }
            </Box>
          </Collapsible>
        </Box>
      ))}
    </Flex>
  )
}

export default Desktop
