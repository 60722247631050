import React from 'react'

import SVG from '../../components/SVG'

const Normal = (props) => (
  <SVG viewBox="0 0 31 31" {...props}>
    <defs>
      <clipPath id="clip-path"><rect x="-924.07" y="-137.49" width="290" height="620" rx="9.99" fill="none"/>
      </clipPath>
    </defs>
    <g id="icon">
      <circle cx="15.5" cy="15.5" r="14.5" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="2"/>
      <path d="M21.12,9.14s0,0,0,.07,0,0,0,0h.74A1.5,1.5,0,0,1,23,9.7a1.46,1.46,0,0,1,.45,1.09v1.77a1.31,1.31,0,0,1-1.31,1.32,1.26,1.26,0,0,1-.92-.38,1.28,1.28,0,0,1-.39-.94v-.89a.16.16,0,0,0-.18-.18H10.27a.16.16,0,0,0-.18.18v1A1.25,1.25,0,0,1,8,13.51a1.18,1.18,0,0,1-.37-.88V10.79A1.46,1.46,0,0,1,8,9.7a1.5,1.5,0,0,1,1.08-.44h.53a.1.1,0,0,0,.08,0s0-.07,0-.09a.19.19,0,0,0,0-.07,1,1,0,0,1-.16-.55,1.48,1.48,0,0,1,.05-.35,1,1,0,0,1,.6-.65l.18-.07a1.58,1.58,0,0,1,.56-.1,1.31,1.31,0,0,1,.39,0,1.24,1.24,0,0,1,.74.53l.14.21a.69.69,0,0,1,.1.37.85.85,0,0,1,0,.26.83.83,0,0,1-.46.46v0l0,0h2c.12,0,.17-.06.17-.18V8.31a1.37,1.37,0,0,1,.4-1,1.28,1.28,0,0,1,1-.4,1.29,1.29,0,0,1,1,.4,1.34,1.34,0,0,1,.41,1v.77a.16.16,0,0,0,.18.18h2.14s0,0,0,0a.63.63,0,0,1-.41-.4.76.76,0,0,1,0-.21.55.55,0,0,1,.12-.35,1.13,1.13,0,0,0,.12-.21,1.6,1.6,0,0,1,.71-.6,1.39,1.39,0,0,1,.52-.11,1.1,1.1,0,0,1,.37.06l.37.1a.89.89,0,0,1,.64.62,1.22,1.22,0,0,1,0,.26.86.86,0,0,1-.26.6A1.07,1.07,0,0,0,21.12,9.14Zm-.23,8.19a1.54,1.54,0,0,1,1.53,1.53V21a3.41,3.41,0,0,1-.23,1.38,1.55,1.55,0,0,1-.81.78,5.11,5.11,0,0,1-1.78.36h-.08a1.48,1.48,0,0,1-.9-.3,2.11,2.11,0,0,1-.62-.9A.94.94,0,0,1,18,22a.88.88,0,0,1,.14-.46.78.78,0,0,1,.67-.36h.6a.5.5,0,0,0,.29-.06.21.21,0,0,0,.08-.19V19.82c0-.11-.06-.17-.18-.17H17.2c-.12,0-.18.06-.18.17v2.89a1.36,1.36,0,0,1-2.32,1,1.31,1.31,0,0,1-.4-1V19.82c0-.11-.06-.17-.18-.17H11.64a.15.15,0,0,0-.17.17v2.11a1.28,1.28,0,0,1-.4.95,1.3,1.3,0,0,1-1,.39,1.33,1.33,0,0,1-1.33-1.34V18.86a1.54,1.54,0,0,1,1.53-1.53h3.8a.16.16,0,0,0,.18-.18v-.49a.16.16,0,0,0-.18-.18h-2A1.52,1.52,0,0,1,10.59,15V13.64a1.54,1.54,0,0,1,1.53-1.53H18.8a1.52,1.52,0,0,1,1.53,1.53V15a1.5,1.5,0,0,1-1.53,1.52H17.2a.16.16,0,0,0-.18.18v.49a.16.16,0,0,0,.18.18Zm-7.61-3.41c-.12,0-.18.06-.18.17v.43c0,.11.06.17.18.17h4.2a.15.15,0,0,0,.17-.17v-.43a.15.15,0,0,0-.17-.17Z" fill="currentColor"/>
      <g clip-path="url(#clip-path)"><rect x="-929.44" y="-137.81" width="966" height="620" rx="10" fill="#fff"/></g>
    </g>
  </SVG>
)

export default Normal
