import { css } from 'styled-components';

import NotoSansTcWoff from './minified/NotoSansCJKtc-Regular.woff';
import NotoSansTcTtf from './minified/NotoSansCJKtc-Regular.ttf';

export default css`
  @font-face {
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Noto Sans'),
      local('NotoSans'),
      url(${NotoSansTcWoff}) format('woff'),
      url(${NotoSansTcTtf}) format('ttf');
    unicode-range: U+26B15;
  }
`;
