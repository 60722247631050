import React from 'react';
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';

import Box from './Box'
import Circle from './Circle'

// const Corner = ({ transform, ...props }) => (
//   <Box width="100%" {...props}>
//     <Box
//       width="100%"
//       pt="100%"
//       borderLeft="1px solid currentColor"
//       borderBottom="1px solid currentColor"
//       transform={transform}
//     />
//   </Box>
// )

const Chevron = ({ children, ...props }) => (
  <Circle bg="black" width="4em" color="white" opacity="0.5" {...props}>
    <IoIosArrowBack size="1.5em" />
  </Circle>
)

const ChevronRight = ({ children, ...props }) => (
  <Circle bg="black" width="4em" color="white" opacity="0.5" {...props}>
    <IoIosArrowForward size="1.5em" />
  </Circle>
)

// const ChevronTop = ({ children, ...props }) => (
//   <Circle bg="black" width={responsive('2.5em', '3.5em')} color="white" position="absolute" top="0" left="0" transform="translate(0%, -120%)" {...props}>
//     <Box transform="rotate(-90deg)">
//       <IoIosPlay size="1.5em" />
//     </Box>
//   </Circle>
// )

// const ChevronBottom = ({ children, ...props }) => (
//   <Circle bg="black" width={responsive('2.5em', '3.5em')} color="white" position="absolute" bottom="0" left="0" transform="translate(0%, 120%)" {...props}>
//     <Box transform="rotate(90deg)">
//       <IoIosPlay size="1.5em" />
//     </Box>
//   </Circle>
// )

Chevron.Left = Chevron
Chevron.Right = ChevronRight
// Chevron.Top = ChevronTop
// Chevron.Bottom = ChevronBottom

export default Chevron;
