import React from 'react'

import Box from '../../components/Box'
import { responsive } from '../../components/ThemeProvider/theme';

const WhiteBox = ({ children, color, ...props }) => {
  return (
    <Box p={responsive('1em', '1.875em')} bg="white" borderRadius="0.5em" color={color} {...props}>
      {children}
    </Box>
  )
}

export default WhiteBox
