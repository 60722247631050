import React, { createElement, forwardRef, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { textAlign } from 'styled-system';
import themeGet from '@styled-system/theme-get';
import { GrSearch } from 'react-icons/gr';
import { Field } from 'formik'

import Flex from './Flex';
import Box from './Box';
import Text from './Text';

const TextInput = styled.input`
  font-family: inherit;
  width: 100%;
  padding: ${props => props.inputpadding || '0.25em' };
  border: 1px solid ${themeGet('colors.gray')};
  border-radius: 0.25em;
  font-weight: bold;
  font-size: 1.25em;
  ${textAlign}
  &::placeholder {
    color: ${themeGet('colors.gray')};
  }
`;

const Input = forwardRef(({
  label,
  rightLabel,
  labelWidth,
  name,
  placeholder,
  disabled,
  value,
  onChange,
  onBlur,
  error,
  type,
  min,
  max,
  textAlign,
  step,
  inputpadding,
  children,
  autoFocus,
  checked,
  ...props
}, ref) => {
  return (
    <Flex as="label" alignItems="center" {...props}>
      {label && (
        <Box mr="1em" width={labelWidth}>
          <Text fontWeight="bold">{label}</Text>
        </Box>
      )}
      {children}
      <Box width={type === 'checkbox' && '1em'} flex={type !== 'checkbox' && 1}>
        {createElement(TextInput, {
          name,
          placeholder,
          disabled,
          value,
          onChange,
          onBlur,
          type,
          min,
          max,
          inputpadding,
          textAlign,
          autoFocus,
          step,
          checked,
          ref,
        })}
        {error && (
          <Text fontSize="0.8em" align="left" color="red" mt="0.25rem">{error}</Text>
        )}
      </Box>
      {rightLabel && (
        <Box ml="0.25em" width={labelWidth}>
          <Text>{rightLabel}</Text>
        </Box>
      )}
    </Flex>
  )
})

Input.propTypes = {
  label: PropTypes.string,
  labelWidth: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onBlur: PropTypes.func,
};

Input.displayName = 'Input';

Input.Field = forwardRef(({ name, type, value, ...props }, ref) => (
  <Field name={name} type={type} value={value}>
    {({ field, meta }) => (
      <Input
        {...field}
        {...props}
        ref={ref}
        type={type}
        error={meta.toucehd && meta.error}
      />
    )}
  </Field>
))

const InputSearch = ({ isFilter, defaultValue, ...props }) => {
  // const [value, setValue] = useState(defaultValue)
  const inputRef = useRef()

  useEffect(() => {
    inputRef.current.value = defaultValue || ''
  }, [defaultValue, isFilter])

  return (
    <Box.Relative zIndex={1}>
      <Input
        inputpadding="0.5em 0.25em 0.5em 1.75em"
        ref={inputRef}
        {...props}
      />
      <Box position="absolute" left="0.5em" top="50%" transform="translateY(-45%)">
        <GrSearch size="1.25em" />
      </Box>
      <Flex
        position="absolute"
        right="0.5rem"
        top="50%"
        transform="translateY(-50%)"
        alignItems="center"
      >
        {Boolean(isFilter) &&
          <Box bg="cyan" borderRadius="1.5em" fontSize="0.375em" px="0.5em" py="0.125em" mr="0.25em" color="white">● ● ●</Box>
        }
      </Flex>
    </Box.Relative>
  )
}

Input.Search = InputSearch

export default Input;
