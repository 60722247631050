import React from 'react'

import Search from '../Search'

import Flex from '../../components/Flex';

import theme, { responsive } from '../../components/ThemeProvider/theme';
import useResponsive from '../../contexts/mediaQuery/useResponsive'
import FilterControl from './FilterControl';

const Filter = ({ rotate, allRotate, ...props }) => {
  const { isMobile } = useResponsive()
  return (
    <Flex
      background="linear-gradient(rgba(255, 255, 255) 15%, rgba(255, 255, 255) 60%, rgba(255, 255, 255, 0) 100%)"
      alignItems="center"
      px={responsive('1em', '0')}
      flexWrap="wrap"
      position="fixed"
      width={1}
      top={theme.headerHeight}
      zIndex={33}
      {...props}
    >
      {isMobile && <Search pt="0.5em" isMobile />}
      <FilterControl rotate={rotate} allRotate={allRotate} />
    </Flex>
  )
}

export default Filter
