import React from 'react'

import Container from '../../components/Container'
import Text from '../../components/Text'
import Box from '../../components/Box'
import Image from '../../components/Image'

import react1 from './react_1.jpg'
import react1webp from './react_1.webp'
import react2 from './react_2.jpg'
import react2webp from './react_2.webp'
import react3 from './react_3.jpg'
import react3webp from './react_3.webp'
import { isObject, map } from 'lodash'

const lostLevel = [
  '隨時隨地都知道自己走在哪，即使走錯路，也能很快發現，並靠自身定位能力回到正路。',
  '發現迷途後，無法自己回到正路，知道原地待援原則。',
  '發現迷途後，無法自己回到正路，不知道原地待援原則，然後開始跟著感覺走（很多案例是跟著溪谷走）。',
  '完全不曉得自己迷途，就一直走，直到很久以後才發現。'
]

const youCanDo = [
  '學會使用指北針與地圖，至少要會使用導航app。',
  '下載離線地圖',
  '下載路線航跡（要有認知別人的航跡不一定準，要比對確認）',
  '確定叉路或模糊點',
  '記住叉路或模糊點',
]

const afterGo = [
  '要知道別想完全按照別人航跡走',
  '至少1小時打開GPS定位1次，看看是否偏離路徑',
  '接近叉路或模糊點時再次確認',
]

const tips = [
  '需要待援時，謹守333原則。3小時可能冷死，3天可能渴死，3週可能餓死。',
  '下溪風險高，除非有必要，否則不要冒然下溪，更不要妄想沿溪走出來。',
  { 即使只有小背包也要帶的裝備: [
    '食：糧食、水',
    '衣：保暖衣、保暖帽、雨衣褲',
    '住：大塑膠袋',
    '行：導航（定位）工具、頭燈',
    '其他：哨子、麥克筆、打火機',
  ]}
]

const Reaction = () => (
  <Container maxWidth="800px">
    <Text.H1 whiteSpace="pre-wrap">【登山迷途基礎應對】</Text.H1>
    <Text.H6>
      撰文 / 葉兆擁，文章來源 / 橘子關懷基金會
    </Text.H6>
    <Text.Article>
      印地圖、上PTT找記錄、讀記錄、估行程、估新舊比、標山頭、畫水線山線路線、路線上標點、地圖防水.....大學登山社時期出隊前的忙碌畫面，依然清晰在腦海。無論是1天的單攻、2-3天的小活動、10多天的長程縱走，除了上述的行前準備以外，都還需要經過一道嚴謹的審核制度——審留守。
    </Text.Article>
    <Text.Article>
      沒審過就不給出發！（噹啷，但爲了社團長期發展著想，審留守是要降低風險，先將一些問題或困難點提出來討論，並非要考倒嚮導）。審留守是不論經驗，每位出隊人員都要參加的，當天每人都會帶著自己嘔心瀝血完成的地圖，再把行程神遊一遍（當然嚮導已經行前過），每支隊伍都會重覆這個流程，對的，每-支-隊！因此大學4年或有意無意5年下來，就會累積很多精心手作的地圖。
    </Text.Article>
    <Box>
      <Image src={[react3webp, react3]} />
    </Box>
    <Box as="ol" pl="1em" listStyleType="decimal" my="2em">
      {lostLevel.map((text, i) => (
        <Box as="li" key={i}>{text}</Box>
      ))}
    </Box>
    <Text.Article>
      以上4階段如果是在前2階段，迷途後風險即降低。而有沒有準備，會關係到在哪個階段發現迷途。
    </Text.Article>
    <Text.Article>
      迷途的原因可能百百種，不知道有叉路、起霧上個廁所、跟著獸徑走、正路在溪溝或稜線轉彎、寬稜分稜時、摸黑走路...... 。從前準備行程時，大約重裝1小時會抓1個休息點。行走時，會經常拿地圖對自己位置，確認每個休息點都有到達，有叉路或有可能走偏的點也會先標在地圖上提醒自己，一直將最後已知點往前推，如果發生迷途，就能將找人的範圍縮小。
    </Text.Article>
    <Box>
      <Image src={[react2webp, react2]} />
    </Box>
    <Text.Article>
      科技日新月異，導航app也如雨後春筍般冒出，下載地圖跟取得航跡都方便多了，登山也能速食化？No～～～行前準備依然重要，沒有準備就甭想不迷途。即使請了嚮導，還是要了解自己有可能脫隊而迷途。
    </Text.Article>
    <Text.Article>迷途後的處理方式若不洽當，是有可能丟掉小命的。關於迷途，除了推廣迷途後的處置以外，個人認爲還能再加上「建立迷途的意識」，預防重於治癒。</Text.Article>
    <Text.Article>
      建立迷途的意識，可以先這樣洗腦自己。<br />
      出發前：<br />
      告訴自己可能迷途，進而去了解路線上的模糊點，然後記住模糊點位置。<br />
      出發後：<br />
      隨時懷疑自己經過模糊點了沒。
    </Text.Article>
    <Text>
      實際的做法可以是<br />
      出發前：
    </Text>
    <Box as="ol" pl="1em" listStyleType="decimal" mt="0" mb="1em">
      {youCanDo.map((text, i) => (
        <Box as="li" key={i}>{text}</Box>
      ))}
    </Box>
    <Text>
      出發後：
    </Text>
    <Box as="ol" pl="1em" listStyleType="decimal" my="0">
      {afterGo.map((text, i) => (
        <Box as="li" key={i}>{text}</Box>
      ))}
    </Box>
    <Box>
      <Image src={[react1webp, react1]} />
    </Box>
    <Text.Article>
      有了迷途的意識（當然爬山風險還有很多面向，這邊先只講迷途），才會開始認真看待自己要爬的山，仔細了解路線上會發生迷途的模糊點，盡可能不要錯過，或者即使走錯，也能在相對短的時間內發現，將迷途階段控制在前2點。
    </Text.Article>
    <Text.Article>
      <b>沒有迷途的意識，不想做準備，即使每棵樹都釘上路牌，每顆石頭都畫上記號，每1公尺就綁上路條，也還是會有人走丟。反之，準備充足，即便沒路，也能自己找出路。入山，要拿出態度！</b>
    </Text.Article>
    <Text.Article>
      拿著地圖，回望稜線，色筆畫的山水線因爲汗水雨水的浸潤而模糊，而記憶是鮮明的，一起苦、一起瘋、一起走過，順利完成的背後是用心準備過的！
    </Text.Article>
    <Text>
      Tips：
    </Text>
    <Box as="ol" pl="1em" listStyleType="decimal" mb="2em" mt="0">
      {tips.map((text, i) => isObject(text) ? map(text, (value, key) => (
        <Box key={i}>
          <Box as="li">{key}</Box>
          <Box>
            {value.map((t, k) => (
              <Text key={k}>{t}</Text>
            ))}
          </Box>
        </Box>
      )) : (
        <Box as="li" key={i}>{text}</Box>
      ))}
    </Box>
    <Text.H6 textAlign="left" mb="2rem">作者簡介：</Text.H6>
    <Text fontWeight="bold">
      葉兆擁 Yeh Chao-Yung<br />
      <br />
      東海大學化學工程學系學士<br />
      米亞桑戶外中心特約登山嚮導<br />
      嘉明湖山屋管理團隊<br />
      2018 完成Conterra Technical Rescue Program LV1＆LV2 訓練課程<br />
      2017 美國WMA核發野外急救員證照
    </Text>
  </Container>
)

export default Reaction
