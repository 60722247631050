import React from 'react'
// import { Link } from 'react-router-dom';

import Box from '../../components/Box';
import Link from '../../components/Link';
import Text from '../../components/Text';
import Image from '../../components/Image';

import part3_1 from './part3_1.jpg'
import part3_2 from './part3_2.jpg'

import part3_1webp from './part3_1.webp'
import part3_2webp from './part3_2.webp'

const lists = [
  '從事戶外活動的天數',
  '最近的天氣情況',
  '預計行走的路線規劃',
  '水源的狀態'
]

const systems = [
  '避難帳：像是露宿袋、輕量木乃伊睡袋、外帳等，都會是一個很好的保護裝備。但若是單獨行動或是想要更輕量化負重，則會建議使用大型塑膠袋，如米亞桑團隊有研發大型的維生塑膠袋，一來可以作為背包打包使用；二來可以在遇到需緊急迫降即在預計行程之外地停留時，就能具備維繫生命且能抵抗室外環境的大型防水防寒迷途裝備。',
  '緊急糧食：所謂的意外，永遠都是超出我們預期的情況。故在原訂攜帶的食物之外，應可準備一包高熱量的食物，如巧克力或是其他耐放的食物。',
  '緊急用水：在台灣的戶外環境中，水源的缺乏仍是有可能發生的。通常發生缺水的情況時容易造成緊張，故在迫降時如何維持維生所需用水是重要的。一般可以透過外帳收集露水，往往能在非常緊急的情況下提供幫助。但若一開始就能備足300-500cc的水或是具備學習找水的能力，也能減少因要滯留山上卻缺乏維生用水的危機。在取水這部分亦特別提醒，切勿為尋求水源下切至溪谷，在地形上相對遮蔽求援不易，且溪谷地形通常難以爬升，若在迫降情況下是相當不建議山友採取這樣的行動。',
  '緊急衣物：若在登山時習慣多帶一件衣物，不如考慮將其換做雨衣類型，亦或是知道行程為長天數活動時，可以考慮多帶一件輕薄的排汗衫。在台灣的天氣若非很冷或是於高海拔環境中，透過更換乾的衣物大多能達到足夠的保暖效果，在加上大型迷途防水袋，往往可以創造保暖且乾爽的環境。透過一天晚上的安全等待，通常能在天亮後再次回到原本的路線或是被成功救援。'
]

const PartIII = () => {
  return (
    <Box>
      <Box my="2em">
        <Image src={[part3_1webp, part3_1]} />
        <Text>實拍圖來源：許書瑋</Text>
      </Box>
      <Text.Article>
        前述兩個篇幅我們談及到了定位系統、照明系統、防曬保護、急救相關、切割工具以及火源系統
        (野外基礎裝備<Link.Underline color="black" to="/equipment/1">PARTI</Link.Underline>、<Link.Underline color="black" to="/equipment/2">PARTII</Link.Underline>)，最後我們會介紹避難帳、緊急糧食、緊急用水、緊急衣物：
      </Text.Article>
      <Box as="ol" listStyleType="lower-alpha" my="2em">
        {systems.map((text, i) => (
          <Box as="li" mt="1em" key={i}>{text}</Box>
        ))}
      </Box>
      <Box my="2em">
        <Image src={[part3_2webp ,part3_2]} />
        <Text>實拍圖來源：許書瑋</Text>
      </Box>
      <Text.Article>
        以上十個項目中，是我們每一次上山都不應該遺漏的裝備器材。而若是剛剛開始從事戶外活動的初心者，可以藉由閱讀上述說明進行準備，在大方向不變的情況下進行微調與變化都是可行的，如現代人多會攜帶行動電源等電子設備，都能協助登山活動的安全。
      </Text.Article>
      <Text.Article>
        以上參考自美國REI官方網站與登山聖經所介紹的相關資訊，並且融合台灣目前常使用的戶外裝備所整理出來的，這10個必備系統，千萬要記得在每一次登山時都要帶上山唷！
      </Text.Article>
    </Box>
  )
}

export default PartIII
