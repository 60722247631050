import React from 'react';
import {
  HashRouter,
  Switch,
  Route,
} from 'react-router-dom';
import { Formik, Form } from 'formik'

import HomePage from './containers/HomePage'
import AboutPage from './containers/AboutPage'
import Layout from './containers/Layout'
import Topics from './containers/Topics'
import LeaveNoTrace from './containers/LeaveNoTrace'
import Workshop from './containers/Workshop'
import Duty from './containers/Duty'
import Shop from './containers/Shop';
import Aid from './containers/Aid';
import Equipment from './containers/Equipment';
import Guide from './containers/Guide';
import Reaction from './containers/Reaction';
import Training from './containers/Training';

function App() {
  return (
    <Formik
      initialValues={{
        location: [],
        journal: [],
        notHunMt: [],
      }}
    >
      <Form>
        <HashRouter>
          <Layout>
            <Switch>
              <Route exact path="/" component={HomePage} />
              <Route path="/about" component={AboutPage} />
              <Route path="/topics/:id" component={Topics} />
              <Route path="/lnt" component={LeaveNoTrace} />
              <Route path="/project" component={Workshop} />
              <Route path="/duty" component={Duty} />
              <Route path="/shop" component={Shop} />
              <Route path="/aid" component={Aid} />
              <Route path="/guide" component={Guide} />
              <Route path="/react" component={Reaction} />
              <Route path="/equipment/:id" component={Equipment} />
              <Route path="/training/:id" component={Training} />
            </Switch>
          </Layout>
        </HashRouter>
      </Form>
    </Formik>
  );
}

export default App;
