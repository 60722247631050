import React from 'react'

import Box from '../../components/Box';
import Flex from '../../components/Flex';
import Text from '../../components/Text';
import Container from '../../components/Container';
import Link from '../../components/Link';
import { responsive } from '../../components/ThemeProvider/theme';
import BackgroundImage from '../../components/BackgroundImage';

import useTsv from './useTsv'
import dataTsv from './data.tsv'
import product1 from './product1.png'
import product1webp from './product1.webp'

const shopPicture = {
  product1: [product1webp, product1],
}

const Shop = () => {
  const { data, loading } = useTsv(dataTsv)
  // console.log(data)
  return (
    <Container as={Flex} flexDirection={responsive('column', 'row')}>
      {data.map(({ name, description, price, sale, image, to }, i) => (
        <Box
          as={(p) => <Link color="black" {...p} />}
          href={to} width={responsive(1, 1 / 3)}
          pt={responsive('3.125em', '6.25em')}
          px={responsive('0.5em', '1.5em')}
          key={i}
        >
          <BackgroundImage src={shopPicture[image]} ratio={1 / 2} />
          <Text mt="1.375rem" fontSize={responsive('1.125em', '1.25em')}>{name}</Text>
          <Text>{description}</Text>
          <Flex justifyContent="flex-end" mt="0.5em">
            <Box
              position="relative"
              mr={sale ? '0.5em' : 0}
              color={sale ? 'gray' : 'black'}
              fontSize={responsive(sale ? '0.875em' : '1em', sale ? '1em' : '1.125em')}
            >
              NT.{price}
              {sale && (
                <Box
                  position="absolute"
                  bg="gray"
                  height="1px"
                  top="50%"
                  left="-0.25em"
                  right="-0.25em"
                  transform="translateY(-50%)"
                />
              )}
            </Box>
            {sale && <Text fontSize={responsive('1em', '1.125em')}>NT.{sale}</Text>}
          </Flex>
        </Box>
      ))}
    </Container>
  )
}

export default Shop
