import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom';
import { MdMenu } from 'react-icons/md';
import { BsChevronDown } from 'react-icons/bs';
import Collapsible from 'react-collapsible';
import OutsideClickHandler from 'react-outside-click-handler';

import Box from '../../components/Box';
import Button from '../../components/Button';
import { responsive } from '../../components/ThemeProvider/theme';
import useResponsive from '../../contexts/mediaQuery/useResponsive';

const menu = [
  {
    label: '關於山模山樣',
    to: '/about',
  },
  {
    label: '2020橘子關懷\n基金會共同合作',
    to: '/project',
  },
  {
    label: '設計模板解密',
    to: '/guide',
  },
  {
    label: '登山主題特輯',
    Comp: Collapsible,
    children: (setOpen) => topics.map(({ topic, to }, i) => (
        <Box key={i}>
          <Button.Transparent
            as={Link}
            to={to}
            onClick={() => {
              setOpen(false)
            }}
            color="gray"
            py="0.25em"
          >{topic}</Button.Transparent>
        </Box>
      )
    )
  },
  {
    label: '訂閱我們',
    to: true,
  },
]

const topics = [
  {
    topic: '登山訓練建議 PART I',
    to: '/training/1'
  },
  {
    topic: '登山訓練建議 PART II',
    to: '/training/2'
  },
  {
    topic: '登山訓練建議 PART III',
    to: '/training/3'
  },
  {
    topic: '野外基礎裝備 PART I',
    to: '/equipment/1'
  },
  {
    topic: '野外基礎裝備 PART II',
    to: '/equipment/2'
  },
  {
    topic: '野外基礎裝備 PART III',
    to: '/equipment/3'
  },
  {
    topic: '登山迷途基礎應對',
    to: '/react'
  },
  {
    topic: '急救知識',
    to: '/aid'
  },
  {
    topic: '無痕山林',
    to: '/lnt'
  },
  {
    topic: '山友責任',
    to: '/duty'
  },
]

const shop = {
  label: '商品',
  to: '/shop',
}

const Menu = ({ openModal, openList }) => {
  const [open, setOpen] = useState()
  const history = useHistory()
  const { isMobile } = useResponsive()
  useEffect(() => {
    if (isMobile && menu.length < 6) {
      menu.splice(3, 0, shop)
    }
    if (!isMobile && menu.length > 5) {
      menu.splice(3, 1)
    }
  }, [isMobile])
  return (
    <>
      <OutsideClickHandler disabled={!Boolean(open)} onOutsideClick={() => setOpen(false)}>
        <Box px={responsive('0.5em', '1em')}>
          <Button.Transparent
            color="lightGray"
            onClick={() => {
              setOpen(!open)
            }}
          >
            <MdMenu size="2.25em" />
          </Button.Transparent>
        </Box>
        <Box
          top="3.5em"
          height="100vh"
          left="0"
          bg="white"
          py="3em"
          px="5em"
          position="absolute"
          transform={open ? 'translateX(0%)' : 'translateX(-100%)'}
          transition="all 0.5s"
          zIndex={99}
        >
          {menu.map(({ label, to, Comp = Button.Transparent, children }, i, { length }) => (
            <Box whiteSpace="pre-wrap" key={i}>
              <Comp
                trigger={
                  <Box position="relative" cursor="pointer" mb="0.875em">
                    {label}
                    <Box position="absolute" top="0" right="105%">
                      <BsChevronDown />
                    </Box>
                  </Box>
                }
                as={Link}
                to={to}
                onClick={() => {
                  (i === length - 1) && openModal()
                  setTimeout(() => {
                    setOpen(!open)
                  }, 500)
                  openList(false)
                }}
                transitionTime={200}
                mb="0.875em"
                py="0"
              >{to ? label : children(setOpen, history)}
              </Comp>
            </Box>
          ))}
        </Box>
      </OutsideClickHandler>
    </>
  )
}

export default Menu
